import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal";
import {
  setImageDetail,
  setPrevModal,
} from "../../../../../redux/slices/userSlice";

const ShowImage = (props) => {
  const dispatch = useDispatch();

  const imageDetail = useSelector((state) => state.user.imageDetail);
  const prevModal = useSelector((state) => state.user.prevModal);

  return (
    <>
      <Modal title={"Image"} name={"showImage"} icon={"fa-solid fa-image"}>
        <div className="row">
          {prevModal === "listUser" ? (
            <>
              <div className="col-12">
                <a
                  href="#"
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setPrevModal(null));
                    dispatch(setImageDetail(null));
                  }}
                >
                  <i
                    style={{ fontSize: "20px" }}
                    className="fa fa-light fa-arrow-left"
                    title="Go To Users List"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target={"#listUser"}
                  ></i>
                </a>
              </div>
            </>
          ) : null}
          <div className="col-12">
            <img
              src={imageDetail}
              alt=""
              style={{
                // width: "95%",
                // height: "95%",
                // maxWidth: "250px",
                maxHeight: "80vh",
                padding: "2px",
                margin: "10px",
                float: "left",
                border: "2px solid grey",
                // backgroundColor: "red",
              }}
              // onClick={() => window.open(imageUrl)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShowImage;
