import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./containers/Login/Login";
import Project from "./containers/Project";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./redux/store";
import SendLink from "./containers/ResetPassword/SendLink";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import Mqtt from "./Mqtt/Mqtt";
import VerifyEmail from "./containers/VerifyEmail/VerifyEmail";
import { setScreenWidth } from "./redux/slices/themeSlice";
import { useEffect } from "react";
import { useState } from "react";
import Privacy from "./containers/Privacy";
import Terms from "./containers/Terms";
import Manual from "./containers/Manual/Manual";

let width = 1600;

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <div
          className="mainClass"
          style={{
            margin: "2px",
            marginBottom: "5px",
            border: "2px solid black",
            padding: "2px",
            height: "99.5vh",
          }}
        >
          <div>
            {/* <Mqtt /> */}
            <Project />
          </div>
        </div>
      </>
    ),
  },
  {
    path: "/privacy",
    element: (
      <div style={{ overflowY: "scroll", height: "99.5vh" }}>
        <Privacy />
      </div>
    ),
  },
  {
    path: "/terms",
    element: (
      <div style={{ overflowY: "scroll", height: "99.5vh" }}>
        <Terms />
      </div>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <>
        <div
          style={{
            overflowY: "scroll",
            margin: "2px",
            marginBottom: "5px",
            border: "2px solid black",
            padding: "2px",
            height: "99.5vh",
          }}
        >
          <div>
            <SendLink />
          </div>
        </div>
      </>
    ),
  },
  {
    path: "/reset-password/:resetLink",
    element: (
      <>
        <div
          style={{
            overflowY: "scroll",
            margin: "2px",
            marginBottom: "5px",
            border: "2px solid black",
            padding: "2px",
            height: "99.5vh",
          }}
        >
          <div>
            <ResetPassword />
          </div>
        </div>
      </>
    ),
  },
  {
    path: "/verify-email/:resetLink",
    element: (
      <>
        <div
          style={{
            overflowY: "scroll",
            margin: "2px",
            marginBottom: "5px",
            border: "2px solid black",
            padding: "2px",
            height: "99.5vh",
          }}
        >
          <div>
            <VerifyEmail />
          </div>
        </div>
      </>
    ),
  },
  {
    path: "/version",
    element: <>Version 2.0.0</>,
  },
  {
    path: "/manual",
    element: (
      <div
        style={{
          overflowY: "scroll",
          margin: "2px",
          marginBottom: "5px",
          padding: "2px",
          height: "99.5vh",
        }}
      >
        <div className="manualBody">
          <Manual />
        </div>
      </div>
    ),
  },
]);

function App() {
  return (
    <>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer />
      </Provider>
    </>
  );
}

export default App;
