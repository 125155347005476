import React from "react";

const Privacy = (props) => {
  return (
    <>
      <div style={{ margin: "60px" }}>
        <p>
          <strong>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              PRIVACY POLICY
            </span>
          </strong>
        </p>
        <p>Introduction</p>
        <p>
          At Unisource, we care about the privacy of your data and are committed
          to protecting it. This Privacy Policy explains what information we
          collect about you and why, what we do with that information, and how
          we handle that information.
        </p>
        <p>
          This notice applies across all websites that we own and operate and
          all services we provide, including our online platform, mobile app,
          and any other services we may offer (for example, events or training).
          For the purpose of this notice, we&rsquo;ll just call them our
          &lsquo;services&rsquo;.
        </p>
        <p>
          When we say &lsquo;personal data&rsquo; we mean identifiable
          information about you, like your name, email, address, telephone
          number, support queries, community comments, location data and so on.
          If you can&rsquo;t be identified (for example, when personal data has
          been aggregated and anonymised) then this notice doesn&rsquo;t apply.
          Check out our Terms &amp; Conditions for more information on how we
          treat your other data.
        </p>
        <p>
          We may need to update this notice from time to time. Where a change is
          significant, we&rsquo;ll make sure we let you know &ndash; usually by
          sending you an email or in app notification.
        </p>
        <p>Last updated: 17 May 2022</p>
        <p>Table of Contents&nbsp;</p>
        <p>&bull; Introduction</p>
        <p>&bull; Who are &lsquo;we&rsquo;?</p>
        <p>&bull; How we collect your data</p>
        <p>&bull; How we use your data</p>
        <p>&bull; How we can share your data</p>
        <p>&bull; International Data Transfers</p>
        <p>&bull; Security</p>
        <p>&bull; Retention</p>
        <p>&bull; Your rights</p>
        <p>&bull; How to contact us</p>
        <p>Who are &lsquo;we&rsquo;?</p>
        <p>
          When we refer to &lsquo;we&rsquo; (or &lsquo;our&rsquo; or
          &lsquo;us&rsquo;), that means Unisource Solutions (Pvt) Limited. Our
          headquarters are in Lahore, Pakistan. Address details are available on
          our Contact us page.
        </p>
        <p>
          We are a provider of application suite for management of emergency
          requests within an industrial and residential complexes. It&rsquo;s a
          powerful end to end solution to connect customers/residents to rescue
          and help services managed by estate administration and emergency
          response teams, using web based Computer Aided Despatch services and
          mobile apps. If you want to find out more about what we do, see the
          About EFIG<sup>&reg;</sup> page.
        </p>
        <p>________________________________________</p>
        <p>How we collect your data</p>
        <p>
          The ways we collect it can be broadly categorised into the following:
        </p>
        <p>
          Information you provide to us directly: When you register Yourself as
          a user of EFIG<sup>&reg;</sup> Customer app we collect personal data
          which is provided during the registration process. We ask you to
          provide or get verified your personal data already given to us by your
          estate administration like full name, phone number, residential
          address, email and coordinates of your registered address.
        </p>
        <p>
          If you don&rsquo;t want to provide us with personal data, you
          don&rsquo;t have to, but it might mean you can&rsquo;t use some parts
          of our services.
        </p>
        <p>
          Information we collect automatically: We collect some information
          about you automatically when you visit our websites or use our
          services, like your IP address and device type. We also collect
          information when you communicate with your security service provider
          using chat provided in EFIG<sup>&reg;</sup> App including text
          messages, voice notes and images with timestamps. This information is
          useful for your security service prover and estate administration to
          fine tune their services and monitor their quality.&nbsp;
        </p>
        <p>
          Information we get from third parties: The majority of information we
          collect, we collect directly from you. Initial information required
          for authorization of registration comes from the Estate Administration
          where your residence is located.&nbsp;
        </p>
        <p>Where we collect personal data, we&rsquo;ll only process it:</p>
        <p>&bull; to perform a contract with you, or</p>
        <p>
          &bull; where we have legitimate interests to process the personal data
          and they&rsquo;re not overridden by your rights, or
        </p>
        <p>&bull; in accordance with a legal obligation, or</p>
        <p>&bull; where we have your consent.</p>
        <p>
          If we don&rsquo;t collect your personal data, we may be unable to
          provide you with all our services, and most functions and features on
          our services may not be available to you.
        </p>
        <p>
          If you&rsquo;re someone who doesn&rsquo;t have a relationship with us,
          but believe that an EFIG<sup>&reg;</sup> user has entered your
          personal data into our system or services, you&rsquo;ll need to
          contact that EFIG<sup>&reg;</sup> subscriber for any questions you
          have about your personal data (including where you want to access,
          correct, amend, or request that the user delete, your personal data).
        </p>
        <p>________________________________________</p>
        <p>How we use your data</p>
        <p>
          First and foremost, we use your personal data to operate our websites
          and provide you with any services you&rsquo;ve requested, and to
          manage our relationship with you. We also use your personal data for
          other purposes, which may include the following:
        </p>
        <p>To communicate with you. This may include:</p>
        <p>
          &bull; providing you with information you&rsquo;ve requested from us
          or information we are required to send to you
        </p>
        <p>
          &bull; operational communications, like chat with your security
          service provider or its representatives, changes to our websites and
          services, security updates, or assistance with using our websites and
          services
        </p>
        <p>
          &bull; marketing communications (about EFIG<sup>&reg;</sup>&nbsp;
          about product announcements, software updates etc.) in accordance with
          your marketing preferences
        </p>
        <p>
          &bull; asking you for feedback or to take part in any research we are
          conducting (which we may engage a third party to assist with).
        </p>
        <p>
          To support you: This may include assisting with the resolution of
          technical support issues or other issues relating to the websites or
          services, whether by email, in-app support or otherwise.
        </p>
        <p>
          To assist you: Unisource has a number of qualified sales &amp; support
          agents to support EFIG<sup>&reg;</sup> users. Either during or after
          your trial of EFIG<sup>&reg;</sup>, Unisource may ask a local agent /
          advisor to make contact with you regarding your trial.
        </p>
        <p>
          The purpose of these advisors is to assist with the scope,
          implementation and ongoing support of your EFIG<sup>&reg;</sup>{" "}
          system. You may ask Unisource to not be contactable by anyone during
          the signup process by contacting at either&nbsp;
          <a href="sales@unisourceinternational.com">
            sales@unisourceinternational.com
          </a>
          &nbsp;or&nbsp;
          <a href="support@unisourceinternational.com">
            support@unisourceinternational.com
          </a>
          .
        </p>
        <p>
          To enhance our websites, mobile apps and services, for example, by
          tracking and monitoring your use of websites and services so we can
          keep improving, or by carrying out technical analysis of our websites
          and services so that we can optimise your user experience and provide
          you with more efficient tools.
        </p>
        <p>
          To protect you: So that we can detect and prevent any fraudulent or
          malicious activity, and make sure that everyone is using our websites
          and services fairly and in accordance with our Terms &amp; Conditions.
        </p>
        <p>
          To market to you: In addition to sending you marketing communications,
          we may also use your personal data to display targeted advertising to
          you online &ndash; through our own websites and services or through
          third party websites and their platforms.
        </p>
        <p>
          To analyse, aggregate and report: We may use the personal data we
          collect about you and other users of our websites and services
          (whether obtained directly or from third parties) to produce
          aggregated and anonymised analytics and reports, which we may share
          publicly or with third parties.
        </p>
        <p>
          By using the EFIG<sup>&reg;</sup> Service, you consent to your
          personal information being collected, held and used in this way and
          for any other use you authorise. Unisource will only use your personal
          information for the purposes described in this Policy or with your
          express permission.
        </p>
        <p>
          It is your responsibility to keep your password to the EFIG
          <sup>&reg;</sup> Service safe, you should notify us as soon as
          possible if you become aware of any misuse of your password, and
          immediately change your password within the Service or via the
          &ldquo;Forgotten Password&rdquo; process.
        </p>
        <p>
          EFIG<sup>&reg;</sup> Software does not store your credit card details
        </p>
        <p>
          If you choose to pay for the Service by credit card, your credit card
          details are not stored by EFIG<sup>&reg;</sup> and cannot be accessed
          by Unisource staff. Your credit card details are encrypted and
          securely stored by a third-party payment gateway to enable Unisource
          to automatically bill your credit card on a recurring basis.
        </p>
        <p>________________________________________</p>
        <p>How we can share your data</p>
        <p>
          There will be times when we need to share your personal data with
          third parties. We will only disclose your personal data to:
        </p>
        <p>
          &bull; third party service providers and partners who assist and
          enable us to use the personal data to, for example, support delivery
          of or provide functionality on the website or
        </p>
        <p>
          &bull; regulators, law enforcement bodies, government agencies, courts
          or other third parties where we think it&rsquo;s necessary to comply
          with applicable laws or regulations, or to exercise, establish or
          defend our legal rights. Where possible and appropriate, we will
          notify you of this type of disclosure
        </p>
        <p>&bull; other people where we have your consent.</p>
        <p>________________________________________</p>
        <p>International Data Transfers</p>
        <p>
          When we share data, it may be transferred to, and processed in,
          countries other than the country you live in, where our data hosting
          provider&rsquo;s servers are located. These countries may have laws
          different to what you&rsquo;re used to. Rest assured, where we
          disclose personal data to a third party in another country, we put
          safeguards in place to ensure your personal data remains protected.
        </p>
        <p>
          For further information, please contact us using the details set out
          in the Contact us section below.
        </p>
        <p>
          If you do not want your personal information to be transferred to a
          server located elsewhere other than Pakistan, you should not provide
          Unisource with your personal information or use the EFIG
          <sup>&reg;</sup> Service.
        </p>
        <p>________________________________________</p>
        <p>Security</p>
        <p>
          Security is a priority for us when it comes to your personal data.
          We&rsquo;re committed to protecting your personal data and have
          appropriate technical and organisational measures in place to make
          sure that happens. Please contact us for further information.
        </p>
        <p>________________________________________</p>
        <p>Retention</p>
        <p>
          The length of time we keep your personal data depends on what it is
          and whether we have an ongoing business need to retain it (for
          example, to provide you with a service).
        </p>
        <p>
          We&rsquo;ll retain your personal data for as long as we have a
          relationship with you and for a period of time afterwards where we
          have an ongoing business need to retain it, in accordance with our
          data retention policies and practices. Following that period,
          we&rsquo;ll make sure it&rsquo;s deleted or anonymised.
        </p>
        <p>
          If your subscription lapses, Within 90 days following lapse, Unisource
          can provide the Account Owner with a full export of the
          Organisation&rsquo;s data in a common file format determined by
          Unisource, for a fee.
        </p>
        <p>&nbsp;</p>
        <p>
          The Organisation&rsquo;s data may be permanently deleted by Unisource
          90 days after the Organisation stops paying to use EFIG
          <sup>&reg;</sup> Services, or at the Account Owner request (a fee may
          be applicable for such requests).
        </p>
        <p>________________________________________</p>
        <p>Your rights</p>
        <p>
          It&rsquo;s your personal data and you have certain rights relating to
          it. When it comes to various communications, you can ask us not to
          send you these at any time &ndash; just unsubscribe the service by
          sending your request to your security services provider or estate
          administration or write directly to us at&nbsp;
          <a href="support@unisourceinternational.com">
            support@unisourceinternational.com
          </a>
          .
        </p>
        <p>You also have rights to:</p>
        <p>
          &bull; know what personal data we hold about you, and to make sure
          it&rsquo;s correct and up to date
        </p>
        <p>
          &bull; request a copy of your personal data, or ask us to restrict
          processing your personal data or delete it
        </p>
        <p>&bull; object to our continued processing of your personal data.</p>
        <p>
          You can exercise these rights at any time by approaching your security
          service provider or estate administration or sending an email to&nbsp;
          <a href="support@unisourceinternational.com">
            support@unisourceinternational.com
          </a>
          .&nbsp;
        </p>
        <p>
          If you&rsquo;re not happy with how we are processing your personal
          data, please let us know by sending an email to&nbsp;
          <a href="support@unisourceinternational.com">
            support@unisourceinternational.com
          </a>
          .&nbsp;We will review and investigate your complaint, and try to get
          back to you within a reasonable time frame. You can also complain to
          your local data protection authority. They will be able to advise you
          how to submit a complaint.
        </p>
        <p>________________________________________</p>
        <p>How to contact us</p>
        <p>
          We&rsquo;re always keen to hear from you. If you&rsquo;re curious
          about what personal data we hold about you or you have a question or
          feedback for us on this notice, our websites or services, please get
          in touch.
        </p>
        <p>
          As a technology company, we prefer to communicate with you by email
          &ndash; this ensures that you&rsquo;re put in contact with the right
          person, in the right location, and in accordance with any regulatory
          time frames.
        </p>
        <p>
          If you wish to complain about how we have handled your personal
          information, please provide our Privacy Officer with full details of
          your complaint and any supporting documentation:
        </p>
        <p>
          &bull; by email at&nbsp;
          <a href="support@unisourceinternational.com">
            support@unisourceinternational.com
          </a>
          , or
        </p>
        <p>
          &bull; by letter addressed to The Privacy Officer, Unisource Solutions
          (Pvt) Ltd, 1.6 KM Off Defence Road, Industrial Area Kahna Flyover,
          Defence Road, LAHORE
        </p>
        <p>Our Privacy Officer will endeavour to:</p>
        <p>
          &bull; provide an initial response to your query or complaint within
          10 business days, and
        </p>
        <p>
          &bull; investigate and attempt to resolve your query or complaint
          within 30 business days or such longer period as is necessary and
          notified to you by our Privacy Officer.
        </p>
      </div>
    </>
  );
};

export default Privacy;
