/* eslint-disable eqeqeq */
import React from "react";
// import { ToastsStore, ToastsContainer } from "react-toasts";
import { useState } from "react";
import { useEffect } from "react";
import mqtt from "mqtt/dist/mqtt";
// import { connect } from "react-redux";

import { errorToast, succesToast, warnToast } from "../helpers/toasts";
import { useDispatch, useSelector } from "react-redux";

import { MQTT_URL, URL } from "../config";
import axios from "axios";
import { setMqttConnected, setUser } from "../redux/slices/userSlice";
import {
  GetActiveComplaints,
  HandleNewComplaint,
  HandleUpdateComplaint,
} from "../actions";
import { CmpSound, CmpSoundStop } from "../Howler/Beeper";
import { GetEnv } from "../helpers/utils";
import { setEfig } from "../redux/slices/efigSlice";

const battRef = 2.99;
const sumResistance = 60;
const loadResitance = 10;
const battThreshold = 10.8;

var mqttClient = null;
var globalActiveComplaints = null;
var globalUser = null;
var globalEfig = null;

function Mqtt(props) {
  const [client, setClient] = useState("");
  const user = useSelector((state) => state.user.user);
  const efig = useSelector((state) => state.efig.efig);
  const activeComplaintList = useSelector(
    (state) => state.complaint.activeComplaintList
  );

  const dispatch = useDispatch();

  const mqttConnect = async () => {
    const options = {
      // path: "/mqtt",
      // host: "s.unisourceinternational.com",
      username: `sarmad`,
      password: "100uos.Uni",
    };
    const c = mqtt.connect(MQTT_URL, options);
    // const c = mqtt.connect(options);
    setClient(c);
  };

  useEffect(() => {
    mqttConnect();
  }, []);

  useEffect(() => {
    globalUser = user;
    globalActiveComplaints = activeComplaintList;
    globalEfig = efig;
  }, [user, activeComplaintList, efig]);

  const subscribeToSelectedEnergizer = (topic, message) => {
    const energizer = {};
    energizer.lastUpdated = new Date().toString();
    let msg = message.toString();
    // console.log("asdasdsadasdsad >>>>>", topic, msg);
    const orginalMsg = message.toString();
    if (
      topic === "esp/initialUpdate" ||
      topic === "esp/lowBatAlarm" ||
      topic === "esp/updateAll" ||
      topic === "esp/location" ||
      topic === "esp/battery"
    ) {
      msg = msg.substring(msg.length - 7, msg.length);
    }

    if (msg.startsWith(":")) {
      msg = msg.substring(msg.length - 6, msg.length);
    }

    const serial = parseInt(msg);

    console.log("sadsadasdasdasd>>>>>>>>>>>>>>>>>>>>>>>>>>", serial);

    if (serial != globalEfig.espSerial) {
      return;
    }

    if (topic == "esp/battery") {
      if (orginalMsg.indexOf("BV:") != -1) {
        const result = orginalMsg.substring(
          orginalMsg.indexOf("BV:") + 3,
          orginalMsg.indexOf("SE::")
        );
        // // console.log("result     ", result);
        let num = parseInt(result);
        num = (num * battRef) / 4096;
        let batteryVolt = null;
        batteryVolt = (num * sumResistance) / loadResitance;

        const volt = batteryVolt.toString();
        // console.log("battery volts", volt);
        const batVolts = volt.substring(0, 5);

        energizer.batteryVolts = batVolts;
      }
    }

    if (topic == "esp/initialUpdate") {
      if (orginalMsg.indexOf("FW:") != -1) {
        const fw = orginalMsg.substring(
          orginalMsg.indexOf("FW:") + 3,
          orginalMsg.indexOf("BV:")
        );

        const firmware = fw.toString();
        energizer.firmware = firmware;
      }
      if (orginalMsg.indexOf("BV:") != -1) {
        const result = orginalMsg.substring(
          orginalMsg.indexOf("BV:") + 3,
          orginalMsg.indexOf("SE::")
        );
        let num = parseInt(result);
        num = (num * battRef) / 4096;
        let batteryVolt = null;
        batteryVolt = (num * sumResistance) / loadResitance;

        const volt = batteryVolt.toString();
        // console.log("battery volts", volt);
        energizer.batteryVolts = volt.substring(0, 5);
      }
      if (orginalMsg.indexOf("FS1") != -1) {
        energizer.energizerState = true;
      }
      if (orginalMsg.indexOf("FS0") != -1) {
        energizer.energizerState = false;
      }
      if (orginalMsg.indexOf("AC1") != -1) {
        energizer.acFail = false;
      }
      if (orginalMsg.indexOf("FA1") != -1) {
        energizer.fenceAlarm = false;
      }
      if (orginalMsg.indexOf("LM1") != -1) {
        energizer.lowPower = false;
      }
      if (orginalMsg.indexOf("TA1") != -1) {
        energizer.tamperAlarm = false;
      }
    }

    if (topic == "esp/fenceAlarm") {
      energizer.fenceAlarm = true;
    }
    if (topic == "esp/tamperAlarm") {
      energizer.tamperAlarm = 1;
    }
    if (topic == "esp/lowPowerAlarm") {
      energizer.lowPower = 1;
    }
    if (topic == "esp/acFail") {
      energizer.acFail = 1;
    }
    if (topic == "esp/lowBatAlarm") {
      const result = orginalMsg.substring(0, orginalMsg.indexOf(":"));
      let num = parseInt(result);
      num = (num * battRef) / 4096;
      let batteryVolt = null;
      batteryVolt = (num * sumResistance) / loadResitance;

      const volt = batteryVolt.toString();
      energizer.batteryVolts = volt.substring(0, 5);
    }
    if (topic == "esp/fenceDisarmed") {
      energizer.energizerState = 0;
    }
    if (topic == "esp/fenceArmed") {
      energizer.energizerState = 1;
    }

    if (topic == "esp/fenceAlarmRes") {
      energizer.fenceAlarm = false;
    }

    if (topic == "esp/location") {
      if (orginalMsg.indexOf("LAT:") != -1) {
        const lat = orginalMsg.substring(
          orginalMsg.indexOf("LAT:") + 4,
          orginalMsg.indexOf("LNG:")
        );
        const lng = orginalMsg.substring(
          orginalMsg.indexOf("LNG:") + 4,
          orginalMsg.indexOf("ACC:")
        );
        const accuracy = orginalMsg.substring(
          orginalMsg.indexOf("ACC:") + 4,
          orginalMsg.indexOf("SE::")
        );

        if (lat.indexOf("0.00") == -1 && lng.indexOf("0.00") == -1) {
          succesToast("Location Updated");
          energizer.lat = lat;
          energizer.lng = lng;
          energizer.accuracy = accuracy;
        }
      }
    }

    if (topic == "esp/acFailRes") {
      energizer.acFail = false;
    }
    if (topic == "esp/tamperAlarmRes") {
      energizer.tamperAlarm = false;
    }
    if (topic == "esp/lowPowerAlarmRes") {
      energizer.lowPower = false;
    }
    if (topic == "esp/lowBatAlarmRes") {
      const result = orginalMsg.substring(0, orginalMsg.indexOf(":"));
      let num = parseInt(result);
      num = (num * battRef) / 4096;
      let batteryVolt = null;
      batteryVolt = (num * sumResistance) / loadResitance;

      const volt = batteryVolt.toString();
      energizer.batteryVolts = volt.substring(0, 5);
    }

    if (topic == "esp/cmdAckLow") {
      if (energizer.energizerState) {
        energizer.energizerState = 0;
      }
    }
    if (topic == "esp/cmdAckHigh") {
      if (!energizer.energizerState) {
        energizer.energizerState = 1;
      }
    }

    dispatch(setEfig({ ...globalEfig, ...energizer }));

    console.log("energizer", { ...globalEfig, ...energizer });
  };

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        console.log("connected to Mqtt........................");

        dispatch(setMqttConnected(true));
        mqttClient = client;
        if (user?.groupId) {
          setClient(client);
          doSubscribe("esp/updateEsp");
          doSubscribe(`esp/forceLogout/${user?.id.toString()}`);
          doSubscribe(`esp/complaint/${user?.groupId.toString()}`);
          doSubscribe(`esp/complaint/${user?.groupId.toString()}/playSound`);

          if (user.roleId === 1) {
            doSubscribe(`esp/complaint/superAdmin`);
            client.subscribe("esp/location");
          }

          client.subscribe("esp/fenceAlarm");
          client.subscribe("esp/acFail");
          client.subscribe("esp/tamperAlarm");
          client.subscribe("esp/lowPowerAlarm");
          client.subscribe("esp/lowBatAlarm");
          // Resolve alarms
          client.subscribe("esp/fenceAlarmRes");
          client.subscribe("esp/acFailRes");
          client.subscribe("esp/tamperAlarmRes");
          client.subscribe("esp/lowPowerAlarmRes");
          client.subscribe("esp/lowBatAlarmRes");
          // Fence States
          client.subscribe("esp/fenceArmed");
          client.subscribe("esp/fenceDisarmed");
          client.subscribe("esp/initialUpdate");
          client.subscribe("esp/battery");

          client.subscribe("esp/updateAll");
          // client.subscribe('esp/complaint/superAdmin')
          client.subscribe("esp/cmdAckHigh");
          client.subscribe("esp/cmdAckLow");
        }
      });
      client.on("error", (err) => {
        dispatch(setMqttConnected(false));
        errorToast(err);
        client.end();
      });
      client.on("reconnect", () => {
        // setConnectStatus('Reconnecting');
      });
      client.on("message", async (topic, message) => {
        // console.log("datatatatatat", topic);
        if (topic === `esp/complaint/${user?.groupId.toString()}`) {
          const data = JSON.parse(message);
          if (data.state == 1 && !data?.isErp) {
            CmpSound();
          }
          if (
            data.state === 8 ||
            data.state === 9 ||
            data.discardBy ||
            data.closedBy ||
            data.deleted
          ) {
            CmpSoundStop();
          }

          await GetActiveComplaints(dispatch);
        } else if (topic === `esp/complaint/superAdmin`) {
          await GetActiveComplaints(dispatch);
        } else if (topic === `efig/forceLogout/${user?.groupId.toString()}`) {
          client.end(true, {}, () => {
            console.log(
              "connection closed ........................................"
            );
          });
          dispatch(setUser(null));
          localStorage.clear();
          window.location.reload();
        } else if (
          topic === `esp/complaint/${user?.groupId.toString()}/playSound`
        ) {
          CmpSound();
        } else {
          if (globalEfig) {
            subscribeToSelectedEnergizer(topic, message);
          }
        }
      });
    }
  }, [client]);

  const doSubscribe = (topic) => {
    client.unsubscribe(topic);
    client.subscribe(topic);
    console.log("subs", topic);
  };

  return <></>;
}

export default Mqtt;

export const disconnectMqtt = (data, user) => {
  if (mqttClient) {
    mqttClient.end(true, {}, () => {
      console.log("connection closed ..............................");
    });
  }
};
