import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setEditDeviceData,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";
import { UpdateFWAll as UpdateFWAllApi } from "../../../../../actions";
import Select from "react-select";

const UpdateFWAll = (props) => {
  const [fw, setFw] = useState("");
  const [hw, setHw] = useState("");
  const [filePath, setFilePath] = useState("");
  const [serials, setSerials] = useState([]);
  const [efigSerialArray, setEfigSerialArray] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const list = useSelector((state) => state.efig.list);

  const dispatch = useDispatch();

  useEffect(() => {
    let tmpArr = [];
    list?.forEach((element) => {
      tmpArr.push({
        value: element.id,
        label: element.espSerial,
      });
    });
    setEfigSerialArray(tmpArr);
    tmpArr = [];
  }, [list]);

  const update = async () => {
    if (IsEmpty(fw)) {
      errorToast("Firmware number is required");
      return;
    }

    const arr = [];
    serials.forEach((data) => {
      arr.push(data.value);
    });
    // console.log("asdasdsadsadasdas", arr);
    // return;
    dispatch(setIsLoading(true));

    await UpdateFWAllApi(dispatch, {
      firmware: fw,
      serials: arr,
      hardware: hw,
      url: filePath,
    })
      .then(async () => {
        dispatch(setIsLoading(false));
        setFw("");
        setHw("");
        setFilePath("");
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <>
      <Modal
        title={"Update Firmware for All Energizers"}
        name={"updateFWAll"}
        icon={"fa-solid fa-arrow-up"}
      >
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              title="Go To List"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#listEfigs"}
            ></i>
          </a>
        </div>
        <form>
          <>
            <div className="row">
              <div className="mb-4 col-lg-2">
                <MyTextInput
                  name={"Firmware #*"}
                  val={fw}
                  placeholder={"XX"}
                  setVal={setFw}
                />
              </div>
              <div className="mb-4 col-lg-2">
                <MyTextInput
                  name={"Hardware #*"}
                  val={hw}
                  placeholder={"X"}
                  setVal={setHw}
                />
              </div>
              <div className="mb-4 col-lg-8">
                <label className="col-form-label">{"File Path"}:</label>
                <div className="input-group">
                  <span className="input-group-text">https://</span>
                  <input
                    type={"text"}
                    className="form-control"
                    placeholder="File Path"
                    value={filePath}
                    onChange={(e) => setFilePath(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-4 col-lg-6">
                <label className="col-form-label">{"Exception Serials"}:</label>
                <Select
                  isMulti
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={serials}
                  isLoading={isLoading}
                  isSearchable={true}
                  name="color"
                  options={efigSerialArray}
                  value={serials}
                  onChange={(e) => setSerials(e || [])}
                />
                <span
                  style={{
                    fontSize: "14px",
                    alignSelf: "flex-end",
                    fontStyle: "italic",
                  }}
                >
                  These EFIG's will not be updated
                </span>
              </div>
            </div>

            <br />
            <div className="row" style={{ marginTop: "60px" }}>
              <div className="col-4"></div>
              <div className="col-4" style={{ textAlign: "center" }}>
                {isLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      update();
                    }}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
            <br />
          </>
        </form>
      </Modal>
    </>
  );
};

export default UpdateFWAll;
