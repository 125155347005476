import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setEditDeviceData,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";
import {
  AddErpComplaintApi,
  GetActiveComplaints,
  GetAllGroups,
  UpdateGroupAPI,
} from "../../../../../actions";
import Select from "react-select";

const AddErpComplaint = (props) => {
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessagae] = useState("");
  const [soc, setSoc] = useState("");
  const [typeCode, setTypeCode] = useState("WIREBREAK");
  const [categoryCode, setCategoryCode] = useState("OTHER");
  const [serverityCode, setSeverityCode] = useState("NORMAL");
  const [project, setProject] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [edit, setEdit] = useState("");
  const [_erpProjectList, set_erpProjectList] = useState([]);

  const isLoading = useSelector((state) => state.user.isLoading);
  const editGroupData = useSelector((state) => state.user.editGroupData);
  const erpUsersList = useSelector((state) => state.erp.erpUsersList);
  const erpTechList = useSelector((state) => state.erp.erpTechList);
  const erpCustomersList = useSelector((state) => state.erp.erpCustomersList);
  const erpProjectList = useSelector((state) => state.erp.erpProjectList);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  const Add = async () => {
    if (IsEmpty(typeCode)) {
      errorToast("Request Type is required");
      return;
    }

    if (IsEmpty(categoryCode)) {
      errorToast("Ticket Categorization is required");
      return;
    }
    // if (IsEmpty(subject)) {
    //   errorToast("Subject is required");
    //   return;
    // }
    if (IsEmpty(message)) {
      errorToast("Message is required");
      return;
    }
    if (IsEmpty(soc)) {
      errorToast("Customer Name is required");
      return;
    }
    if (IsEmpty(project)) {
      errorToast("Project is required");
      return;
    }

    dispatch(setIsLoading(true));

    let _title = null;
    if (typeCode === "WIREBREAK") {
      _title = "Wire Break";
    } else if (typeCode === "FENCEALARM") {
      _title = "Fence Alarm";
    } else if (typeCode === "WIRELOOSE") {
      _title = "Wire Loose";
    } else {
      _title = "Other";
    }

    await AddErpComplaintApi(dispatch, {
      title: _title,
      subject: _title,
      message,
      type_code: typeCode,
      fk_soc: soc.value,
      // erpCustomerId: soc.value,
      category_code: categoryCode,
      severity_code: serverityCode,
      fk_project: project.value,
      // erpProjectId: project.value,
      fk_user_assign: assignedTo.value,
      operatorId: user.id,
      fk_statut: 2,
      state: 2,
    })
      .then(async () => {
        await GetActiveComplaints(dispatch);
        emptyFields();
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        errorToast(e.response?.data?.err || "Error");
        dispatch(setIsLoading(false));
      });
  };

  useEffect(() => {
    if (soc) {
      const fiteredList = erpProjectList.filter(
        (project) => project.socid == soc.value
      );
      set_erpProjectList(fiteredList);
    }
  }, [soc]);

  const emptyFields = (emptyEdit = true) => {
    setCategoryCode("OTHER");
    setSoc("");
    setTitle("");
    setSeverityCode("NORMAL");
    setProject("");
    setSubject("");
    setMessagae("");
    setTypeCode("WIREBREAK");
    setAssignedTo("");
    if (emptyEdit) {
      setEdit(null);
    }
  };

  // const update = async () => {
  //   if (IsEmpty(groupId)) {
  //     errorToast("Group Id is required");
  //     return;
  //   }
  //   if (IsEmpty(groupName)) {
  //     errorToast("Group Name is required");
  //     return;
  //   }

  //   dispatch(setIsLoading(true));

  //   await UpdateGroupAPI(dispatch, {
  //     groupId,
  //     name: groupName,
  //   })
  //     .then(async () => {
  //       setGroupName("");
  //       setGroupId("");
  //       await GetAllGroups(dispatch);
  //       dispatch(setIsLoading(false));
  //     })
  //     .catch((e) => {
  //       dispatch(setIsLoading(false));
  //     });
  // };

  return (
    <>
      <Modal
        title={edit ? "Update Complaint" : "Add Complaint"}
        name={"addComplaint"}
        icon={"fa-solid fa-people-roof"}
      >
        <div style={{ height: "70vh" }}>
          <div className="col-12">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                onClick={() => {
                  setEdit(null);
                  emptyFields();
                }}
                title="Go To List"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#complaintHistory"}
              ></i>
            </a>
          </div>
          <form>
            <>
              <div className="row">
                <div className="col-lg-4">
                  <label className="col-form-label">Request Type:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={typeCode}
                    onChange={(e) => {
                      setTypeCode(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option
                      value="WIREBREAK"
                      val2="Wire Break"
                      data-select2-id="44"
                    >
                      Wire Break
                    </option>
                    <option
                      value="FENCEALARM"
                      val2="Fence Alarm"
                      data-select2-id="2"
                    >
                      Fence Alarming
                    </option>
                    <option
                      value="GSMFAULT"
                      val2="GSM Problem"
                      data-select2-id="45"
                    >
                      GSM Problem
                    </option>
                    <option
                      value="WIRELOOSE"
                      val3="Wire Loose"
                      data-select2-id="43"
                    >
                      Wires Loose
                    </option>
                    <option
                      value="REMOTE"
                      val2="Remote Controller Issue"
                      data-select2-id="46"
                    >
                      Remote Controller Issue
                    </option>
                    <option value="OTHER" val2="Other" data-select2-id="47">
                      Other
                    </option>
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="col-form-label">
                    Ticket Categorization:
                  </label>

                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={categoryCode}
                    onChange={(e) => {
                      setCategoryCode(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    <option value="OTHER" data-select2-id="4">
                      Other
                    </option>
                    <option value="CONTRACT" data-select2-id="49">
                      Under Contract
                    </option>
                    <option value="WARRANTY" data-select2-id="50">
                      Under Warranty
                    </option>
                    <option value="PAID" data-select2-id="51">
                      To be Paid
                    </option>
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="col-form-label">Severity:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={serverityCode}
                    onChange={(e) => setSeverityCode(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="LOW" data-select2-id="55">
                      Low
                    </option>
                    <option value="NORMAL" data-select2-id="6">
                      Normal
                    </option>
                    <option value="HIGH" data-select2-id="56">
                      High
                    </option>
                    <option value="BLOCKING" data-select2-id="57">
                      Critical, Blocking
                    </option>
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                {/* <div className=" col-lg-4">
                  <MyTextInput
                    name={"Subject*"}
                    val={subject}
                    placeholder={"Subject*"}
                    setVal={setSubject}
                  />
                </div> */}
                {/* <div className=" col-lg-4">
                  <MyTextInput
                    name={"Message*"}
                    val={message}
                    placeholder={"Message*"}
                    setVal={setMessagae}
                  />
                </div> */}
                <div className=" col-lg-4">
                  <label className="col-form-label">{"Customer Name"}:</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    value={soc}
                    options={erpCustomersList}
                    onChange={(e) => setSoc(e || null)}
                  />
                </div>
                <div className=" col-lg-4">
                  <label className="col-form-label">{"Project"}:</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    value={project}
                    options={
                      _erpProjectList.length ? _erpProjectList : erpProjectList
                    }
                    onChange={(e) => setProject(e || null)}
                  />
                </div>
                <div className=" col-lg-4">
                  <label className="col-form-label">{"Assigned to"}:</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    value={assignedTo}
                    options={erpTechList}
                    onChange={(e) => setAssignedTo(e || null)}
                  />
                </div>
                <div className="mb-3 mt-2 col-lg-8">
                  <label className="form-label" style={{ fontWeight: "500" }}>
                    Message*:
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    maxLength={1000}
                    value={message}
                    onChange={(e) => setMessagae(e.target.value)}
                  ></textarea>
                  <div className="fst-italic float-end">
                    A Maximum of 255 words.
                    {message ? `(${255 - message.length} remaining)` : ""}
                  </div>
                </div>
                {/* <div className=" col-lg-4">
                  <label className="col-form-label">{"Title"}:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  >
                    <option value={""}></option>
                    <option value="Fence Alarm">Fence Alarm</option>
                    <option value="Wire Break">Wire Break</option>
                    <option value="Wire Loose">Wire Loose</option>
                    <option value="Efig Problem">Efig Problem</option>
                    <option value="Other">Other</option>
                  </select>
                </div> */}
              </div>

              <br />
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4" style={{ textAlign: "center" }}>
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      &nbsp; Loading...
                    </button>
                  ) : edit ? (
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        // update();
                      }}
                    >
                      Update Complaint
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        Add();
                      }}
                    >
                      Add Complaint
                    </button>
                  )}
                </div>
              </div>
              <br />
            </>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AddErpComplaint;
