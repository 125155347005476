/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";
import "./style.css";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal";
import { setEfig } from "../../../../../redux/slices/efigSlice";

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const ViewLocation = (props) => {
  const dispatch = useDispatch();

  const markerLocation = useSelector((state) => state.map.markerLocation);
  const efig = useSelector((state) => state.efig.tempEfig);

  const [data, setData] = useState({
    type: "FeatureCollection",
    features: [],
  });
  const [map, setMap] = useState(false);

  const [viewport, setViewport] = useState({
    latitude: 37.78,
    longitude: -122.41,
    zoom: 11,
  });

  useEffect(() => {
    console.log("markerrrrrrrrrrrrrrrrrrrrrrrrrrrrrr", markerLocation);
  }, [markerLocation]);

  let MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1Ijoic2FybWFkNjE0NjMyNCIsImEiOiJjbG80N2JjNWwyZ3B0MnJxb3c1czFmeDJzIn0.JRqVuJHDS16lMDFPq3amsA";
  let mapStyle = "mapbox://styles/mapbox/streets-v9"; // "mapbox://styles/zaeemtarrar3/ck824k9kf2i9t1iofd77sbalm";

  const getMap = () => {
    if (map) {
      return (
        <Map
          mapStyle={mapStyle}
          style={{ width: "100%", height: "560px" }}
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          initialViewState={{
            longitude: markerLocation?.longitude || 74.3209,
            latitude: markerLocation?.latitude || 31.4676,
            zoom: viewport.zoom,
          }}
          // latitude={markerLocation?.latitude || 31.4676}
          // longitude={markerLocation?.longitude || 74.3209}
          // zoom={viewport.zoom}
          // onViewportChange={setViewport}
          onMove={setViewport}
        >
          {markerLocation ? (
            <Marker
              longitude={markerLocation?.longitude || 74.325816}
              latitude={markerLocation?.latitude || 31.484722}
              // onClick={onMarkerClick}
            >
              <div style={{ zIndex: 1 }}>
                <img
                  src={require("../../../../../assets/mappin.png")}
                  alt=""
                  style={{ width: "34px" }}
                />
              </div>
            </Marker>
          ) : null}
        </Map>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Modal
        title={"Location"}
        name={"viewMarkerOnMap"}
        icon={"fa fa-map-location-dot"}
      >
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              title="Go To List"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#efigDetails"}
              onClick={() => {
                dispatch(setEfig(efig));
              }}
            ></i>
          </a>
        </div>
        <div className="" onMouseEnter={() => setMap(true)}>
          {map ? (
            <>
              {getMap()}
              <br />
            </>
          ) : (
            <>
              <div style={{ textAlign: "center" }}>
                <p> Hover Mouse to see map. </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ViewLocation;
