/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setComplaintDetail,
  setErpComplaintData,
} from "../../../redux/slices/complaintSlice";
import { setUserDetails } from "../../../redux/slices/userSlice";

import {
  setFocusedMarker,
  setFocusedMarkerAlways,
  setMarkerLocation,
} from "../../../redux/slices/mapSlice";
import { GetActiveComplaints, GetCenter } from "../../../actions";
// import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

TimeAgo.addDefaultLocale(en);

const ActiveComplaintList = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  const timeAgo = new TimeAgo("en-US");

  const activeComplaintList = useSelector(
    (state) => state.complaint.activeComplaintList
  );
  const complaintDetail = useSelector(
    (state) => state.complaint.complaintDetail
  );

  useEffect(() => {
    GetActiveComplaints(dispatch);
  }, []);

  const complaitsList = () => {
    let count = 0;
    return activeComplaintList?.map((val, index) => {
      count++;
      return (
        <>
          <tr key={index}>
            <td>
              <a
                href="#"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                // data-bs-toggle="modal"
                // data-bs-target={"#complaintDetails"}
                onClick={() => {
                  dispatch(setComplaintDetail(val));
                  if (complaintDetail?.id !== val.id) {
                    dispatch(setErpComplaintData(null));
                  }
                }}
              >
                {`${val.complaintNumber}`}
              </a>
            </td>
            <td className={`clickable_dev_${val?.id}`}>{`${
              val.user?.customerFirstname || val?.erpCustomer?.fullname || ""
            } ${val.user?.customerLastname || ""}`}</td>
            <td>
              {timeAgo.format(new Date(val.createdAt))}

              {/* {val.createdAt} */}
              {/* {new Date(val.createdAt).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })} */}
            </td>
            <td> {val.title} </td>

            {/* <td>{`${val.tech?.firstname || val?.erpUser?.firstname || ""}`}</td> */}
            <td>
              <i
                style={{
                  fontSize: "14px",
                  color:
                    val.state === 1
                      ? "#DC3545"
                      : val.state === 2 || val.state === 3
                      ? "#3F8755"
                      : val.state === 7
                      ? "orange"
                      : val.state === 6
                      ? "#DC3545"
                      : "grey",
                }}
                title={
                  val?.state == 1
                    ? "In Query"
                    : val?.state == 2
                    ? "Assigned"
                    : val?.state == 3
                    ? "In Process"
                    : val?.state == 7
                    ? "On Hold"
                    : val?.state == 5
                    ? "Waiting for Feedback"
                    : val?.state == 9
                    ? "Cancelled"
                    : val?.state == 8
                    ? "Resolved"
                    : ""
                }
                className={`fa-solid fa-circle ${
                  val.state === 1 ? "fa-beat" : ""
                }`}
              ></i>
            </td>
            {user?.roleId === 1 && user?.groupId === 1 ? (
              val?.energizer?.lat && val?.energizer?.lng ? (
                <td>
                  <a href="#" className="nav-link">
                    <i
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      data-bs-toggle="modal"
                      data-bs-target={"#viewMarkerOnMap"}
                      style={{
                        fontSize: "20px",
                        color: "black",
                        marginLeft: "10px",
                        opacity: user.roleId === 1 ? 1 : 0.6,
                      }}
                      className="fa fa-light fa-map-marker-alt"
                      onClick={() => {
                        dispatch(
                          setMarkerLocation({
                            longitude:
                              parseFloat(val?.energizer?.lng) || 74.3209,
                            latitude:
                              parseFloat(val?.energizer?.lat) || 31.4676,
                            zoom: 12,
                          })
                        );
                      }}
                      title="View Location "
                    ></i>
                  </a>
                </td>
              ) : null
            ) : null}
          </tr>
          <Tooltip anchorSelect={`.clickable_dev_${val?.id}`} place="top">
            <div>
              Contact #: {val.user?.contact || val?.erpCustomer?.contact || ""}
            </div>
            <div>
              Site Address:{" "}
              {val.energizer?.address || val.erpProject?.address || ""}
            </div>
            <div></div>
          </Tooltip>
        </>
      );
    });
  };

  return (
    <>
      <div style={{ height: "70vh", overflow: "auto" }}>
        <div className="row">
          <div className="col-lg-12">
            <div
              style={{
                color: "#438AA2",
                fontWeight: "bold",
                backgroundColor: "#121624",
                height: "30px",
              }}
            >
              Active Complaints{" "}
              {activeComplaintList.length
                ? `(${activeComplaintList.length})`
                : null}
            </div>
          </div>
        </div>

        <div
          style={{ marginTop: "5px", overflowY: "auto", marginBottom: "80px" }}
        >
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane show active">
              <table className="table table-sm small">
                <thead>
                  <tr>
                    <th scope="col">Complaint #</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Created at</th>
                    <th scope="col">Type</th>
                    {/* <th scope="col">Tech</th> */}
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>{complaitsList()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveComplaintList;
