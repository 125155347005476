import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCenter, GetServiceDetails } from "../../actions";
// import SideBarBtn from "../../components/SideBarBtn";
// import MapGl from "./MapWall/MapGl/MapGl";
import MapWall from "./MapWall/MapWall";
import ActiveComplaintList from "./SideBar/ActiveComplaintList";
import MyChart from "./SideBar/MyChart";
import { setScreenWidth } from "../../redux/slices/themeSlice";
import ComplaintDetailsMain from "./MapWall/Modals/Complaints/ComplaintDetailsMain";
// import SideBar from "./SideBar/SideBar";

const Main = (props) => {
  const complaintDetail = useSelector(
    (state) => state.complaint.complaintDetail
  );

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const dispatch = useDispatch();
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
      dispatch(setScreenWidth(getCurrentDimension()));
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return (
    <>
      <div
        className="row"
        style={{
          padding: "0px",
          margin: "0px",
        }}
      >
        <>
          {screenSize.width < 1000 && complaintDetail ? (
            <div style={{ overflowY: "scroll" }}>
              <ComplaintDetailsMain />
            </div>
          ) : (
            <div
              className="col-lg-4"
              style={{
                height: "90vh",
                paddingRight: "2px",
                margin: "0px",
                textAlign: "center",
                padding: "0px",
                borderRight: "1px solid black",
              }}
            >
              {/* <SideBar /> */}
              <div style={{ height: "25vh", backgroundColor: "#F5FBFF" }}>
                <MyChart />
              </div>
              <div style={{ height: "65vh", backgroundColor: "#F5FBFF" }}>
                <ActiveComplaintList />
              </div>
            </div>
          )}

          {/* Map Wall */}

          <div
            style={{
              height: "90vh",
              padding: "0px",
              margin: "0px",
              // width: "95%",
            }}
            className="col-lg-8 "
          >
            <MapWall />
            {/* <h1>Map WALL</h1> */}
          </div>
        </>
      </div>
    </>
  );
};

export default Main;
