import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setEditDeviceData,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { setEfigLoading } from "../../../../../redux/slices/efigSlice";
import {
  AssignEFIG,
  GetEFIGList,
  GetErpProjects,
} from "../../../../../actions";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

// let efigSerialArray = [];
let projectArray = [];
// let customerArray = [];

const AssignEfig = (props) => {
  const [serial, setSerial] = useState("");
  const [efigSerialArray, setEfigSerialArray] = useState("");
  const [customerArray, setCustomerArray] = useState("");
  const [projectArray, setProjectArray] = useState("");
  const [address, setAddress] = useState("");
  const [projectId, setProjectId] = useState("");
  const [userId, setUserId] = useState("");
  const [energizerSerial, setEnergizerSerial] = useState("");
  const [energizerType, setEnergizerType] = useState("");
  const [oldUser, setOldUser] = useState(false);

  const isLoading = useSelector((state) => state.efig.efigLoading);
  const editGroupData = useSelector((state) => state.user.editGroupData);
  const list = useSelector((state) => state.efig.list);
  const customers = useSelector((state) => state.user.customers);
  const assignEfigSerial = useSelector((state) => state.efig.assignEfigSerial);
  const erpProjectList = useSelector((state) => state.erp.erpProjectList);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    getErpProj();
  }, []);

  const getErpProj = async () => {
    await GetErpProjects(dispatch);
  };

  useEffect(() => {
    if (assignEfigSerial) {
      setSerial(assignEfigSerial);
    } else {
      setSerial("");
    }
  }, [assignEfigSerial]);

  const Assign = async () => {
    if (IsEmpty(serial)) {
      errorToast("Serial # is required");
      return;
    }
    if (IsEmpty(energizerSerial)) {
      errorToast("Energizer Serial # is required");
      return;
    }
    if (IsEmpty(energizerType)) {
      errorToast("Energizer Type is required");
      return;
    }
    if (IsEmpty(userId)) {
      errorToast("Customer is required");
      return;
    }

    dispatch(setEfigLoading(true));

    await AssignEFIG(dispatch, {
      espId: serial.value,
      userId: userId.value,
      energizerSerial,
      dolibarrProjectId: projectId?.value,
      type: energizerType,
      address,
      oldUser,
    })
      .then(async () => {
        empty();
        await GetEFIGList(dispatch);
        dispatch(setEfigLoading(false));
      })
      .catch((e) => {
        dispatch(setEfigLoading(false));
      });
  };

  const empty = () => {
    setSerial(null);
    setUserId(null);
    setEnergizerType("");
    setEnergizerSerial("");
    setAddress("");
    setProjectId(null);
    setOldUser(false);
  };

  useEffect(() => {
    let tmpArr = [];
    list?.forEach((element) => {
      if (!element.userId) {
        tmpArr.push({
          value: element.id,
          label: element.espSerial,
        });
      }
    });
    setEfigSerialArray(tmpArr);
    tmpArr = [];
  }, [list]);

  useEffect(() => {
    if (projectId) {
      setAddress(projectId.label);
    }
  }, [projectId]);

  useEffect(() => {
    let tmpArr = [];
    customers?.forEach((element) => {
      tmpArr.push({
        value: element.id,
        label: `${element.customerFirstname || ""} ${
          element.customerLastname || ""
        } ( ${element.address || ""} )`,
        tester: element?.tester,
      });
    });
    setCustomerArray(tmpArr);
    tmpArr = [];
  }, [customers]);

  return (
    <>
      <Modal
        title={"Assign EFIG"}
        name={"assignEfig"}
        icon={"fa-solid fa-link"}
      >
        <div style={{ height: "60vh" }}>
          <div className="col-12">
            <a href="#" className="nav-link" onClick={() => empty()}>
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To List"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#listEfigs"}
              ></i>
            </a>
          </div>
          <form>
            <>
              <div className="row">
                <div className="mb-4 col-lg-3">
                  <label className="col-form-label">{"EFIG Serial"}:</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={userId}
                    isLoading={isLoading}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    value={serial}
                    options={efigSerialArray}
                    onChange={(e) => setSerial(e || null)}
                  />
                </div>
                <div className="mb-4 col-lg-3">
                  <label className="col-form-label">{"Customer"}:</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={userId}
                    isLoading={isLoading}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    value={userId}
                    options={customerArray}
                    onChange={(e) => {
                      console.log("asdsadsadsadas", e);
                      setUserId(e || null);
                    }}
                  />
                </div>
                <div className="mb-4 col-lg-3">
                  <MyTextInput
                    name={"Energizer #"}
                    val={energizerSerial}
                    placeholder={"Energizer #"}
                    setVal={setEnergizerSerial}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="col-form-label">Energizer Type:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={energizerType}
                    onChange={(e) => setEnergizerType(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="Z-11">Energizer Z-11</option>
                    <option value="Z-14">Energizer Z-14</option>
                    <option value="Z-18">Energizer Z-18</option>
                    <option value="Z-28">Energizer Z-28</option>
                  </select>
                </div>

                {user.groupId === 1 ? (
                  <>
                    <div className="mb-4 col-lg-4">
                      <label className="col-form-label">
                        {"ERP Project Ref"}:
                      </label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={projectId}
                        isLoading={isLoading}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        value={projectId}
                        options={erpProjectList}
                        onChange={(e) => setProjectId(e || null)}
                      />
                    </div>
                  </>
                ) : null}

                <div className="mb-4 col-lg-4">
                  <MyTextInput
                    name={"Address"}
                    val={address}
                    placeholder={"Address"}
                    setVal={setAddress}
                  />
                </div>
                {!userId?.tester && (
                  <div
                    className="mb-4 col-lg-4"
                    style={{
                      margin: "auto",
                      justifyItems: "flex-end",
                    }}
                  >
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={oldUser}
                        id="flexCheckDefault2"
                        onChange={(e) => setOldUser(!oldUser)}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault2"
                      >
                        Check if assigning to an old user
                        <span>
                          <i
                            style={{
                              fontSize: "18px",
                              marginLeft: "10px",
                              color: "lightgray",
                            }}
                            className="fas fa-info-circle infoEFIG"
                          ></i>
                        </span>
                      </label>
                      <Tooltip anchorSelect={`.infoEFIG`} place="bottom">
                        <div style={{ width: "200px" }}>
                          I.e. It came for repairing and assigning it to the
                          same user. Will restore secondary users and restricted
                          users save against this EFIG.
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>

              <br />
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4" style={{ textAlign: "center" }}>
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      &nbsp; Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        Assign();
                      }}
                    >
                      Assign EFIG
                    </button>
                  )}
                </div>
              </div>
              <br />
            </>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default AssignEfig;
