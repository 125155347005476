import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setEditDeviceData,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import { GetEFIGList, InterGroupTransferApi } from "../../../../../actions";
import { setEfigLoading } from "../../../../../redux/slices/efigSlice";

const InterGroupTransfer = (props) => {
  const [serials, setSerials] = useState([]);
  const [groupToId, setGroupToId] = useState("");
  const [groupFromId, setGroupFromId] = useState("");
  const [efigSerialArray, setEfigSerialArray] = useState("");

  const isLoading = useSelector((state) => state.efig.efigLoading);
  const editGroupData = useSelector((state) => state.user.editGroupData);
  const groups = useSelector((state) => state.user.groups);
  const list = useSelector((state) => state.efig.list);

  useEffect(() => {
    console.log("serrrr", serials);
  }, [serials]);

  useEffect(() => {
    let tmpArr = [];
    list?.forEach((element) => {
      if (!element.userId) {
        tmpArr.push({
          value: element.id,
          label: element.espSerial,
        });
      }
    });
    setEfigSerialArray(tmpArr);
    tmpArr = [];
  }, [list]);

  const dispatch = useDispatch();

  const transfer = async () => {
    if (IsEmpty(serials)) {
      errorToast("Serials required");
      return;
    }
    if (IsEmpty(groupToId)) {
      errorToast(" To Group is required");
      return;
    }
    if (IsEmpty(groupFromId)) {
      errorToast("From Group is required");
      return;
    }

    dispatch(setEfigLoading(true));

    await InterGroupTransferApi(dispatch, {
      serials,
      groupFromId,
      groupToId,
    })
      .then(async () => {
        await GetEFIGList(dispatch);
        dispatch(setEfigLoading(false));
        empty();
      })
      .catch((e) => {
        dispatch(setEfigLoading(false));
      });
  };

  const empty = () => {
    setSerials("");
    setGroupFromId("");
    setGroupToId("");
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return (
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Inter Group Transfer"}
        name={"interGroupTransfer"}
        icon={"fa-solid fa-exchange-alt"}
      >
        <div className="col-12">
          <a href="#" className="nav-link" onClick={() => empty()}>
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              title="Go To List"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#listEfigs"}
            ></i>
          </a>
        </div>
        <form>
          <>
            <div className="row">
              <div className="mb-4 col-lg-6">
                <label className="col-form-label">{"Serials"}:</label>
                <Select
                  isMulti
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={serials}
                  isLoading={isLoading}
                  isSearchable={true}
                  name="color"
                  options={efigSerialArray}
                  value={serials}
                  onChange={(e) => setSerials(e || [])}
                />
              </div>

              <div className="col-lg-3">
                <label className="col-form-label">Group From*:</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={groupFromId}
                  onChange={(e) => setGroupFromId(e.target.value)}
                >
                  <option value=""></option>
                  {groupList()}
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
              <div className="col-lg-3">
                <label className="col-form-label">Group To*:</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={groupToId}
                  onChange={(e) => setGroupToId(e.target.value)}
                >
                  <option value=""></option>
                  {groupList()}
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>

            <br />
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4" style={{ textAlign: "center" }}>
                {isLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      transfer();
                    }}
                  >
                    Transfer
                  </button>
                )}
              </div>
            </div>
            <br />
          </>
        </form>
      </Modal>
    </>
  );
};

export default InterGroupTransfer;
