import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddComplaintResponder,
  AddContact,
  AddCustomerAPI,
  AddUserAPI,
  AddWhitelistCustomer,
  DeleteUser,
  GenerateAndSendPassword,
  GetErpCustomers,
  GetUsers,
  SearchCustomers,
  UpdateComplaintResponder,
  UpdateCustomer,
  UpdateServiceContact,
  UpdateUsers,
  UpdateWhiteListCustomer,
  UploadFile,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { URL } from "../../../../../config";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setActionVal,
  setConfirmText,
  setEditUserData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import Select from "react-select";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const AddCustomer = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [groupId, setGroupId] = useState("");
  const [city, setCity] = useState("");
  const [edit, setEdit] = useState("");
  const [userId, setUserId] = useState("");
  const [erpCustomerId, setErpCustomerId] = useState("");
  const [network, setNetwork] = useState("");
  const [tester, setTester] = useState(false);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const editUserData = useSelector((state) => state.user.editUserData);
  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);
  const groups = useSelector((state) => state.user.groups);
  const erpCustomersList = useSelector((state) => state.erp.erpCustomersList);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    getErpCus();
  }, []);

  const getErpCus = async () => {
    await GetErpCustomers(dispatch);
  };

  useEffect(() => {
    if (erpCustomerId) {
      setAddress(erpCustomerId.address);
      const [before, after] = splitLastOccurrence(erpCustomerId.fullname, " ");
      setFirstname(before);
      setLastname(after);
    }
  }, [erpCustomerId]);

  function splitLastOccurrence(str, substring) {
    const lastIndex = str.lastIndexOf(substring);

    const before = str.slice(0, lastIndex === -1 ? str.length : lastIndex);
    let after = "";
    if (lastIndex !== -1) {
      after = str.slice(lastIndex + 1);
    }

    return [before, after];
  }

  useEffect(() => {
    if (editUserData) {
      if (editUserData?.erpCustomerId) {
        erpCustomersList.every((user) => {
          if (user.id === editUserData.erpCustomerId) {
            setErpCustomerId(user);
            return false;
          }
          return true;
        });
      }

      setUserId(editUserData?.id);
      setGroupId(editUserData?.groupId);
      setFirstname(editUserData?.customerFirstname);
      setLastname(editUserData?.customerLastname);
      setNetwork(editUserData?.network?.toLowerCase());
      setContact(editUserData?.contact.substr(3, editUserData?.contact.length));
      setEmail(editUserData?.email);
      setCity(editUserData?.city);
      setAddress(editUserData?.address);
      setTester(editUserData?.tester);
      setEdit(editUserData?.roleId?.toString());
      dispatch(setEditUserData(null));
    }
  }, [editUserData]);

  const getErpCustomerId = () => {
    const index = erpCustomersList.findIndex(
      (cus) => cus.value === erpCustomerId.value
    );
    if (index > -1) {
      return erpCustomersList[index]?.id;
    }
    return null;
  };

  const addCustomer = async () => {
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }

    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    dispatch(setIsLoading(true));

    await AddCustomerAPI(dispatch, {
      customerFirstname: firstname,
      customerLastname: lastname,
      address,
      roleId: 3,
      email,
      network,
      contact: "+92" + contact,
      groupId,
      erpCustomerId: erpCustomerId?.value,
      tester,
    })
      .then(async () => {
        // await Get(dispatch);
        emptyFields();
        await SearchCustomers(dispatch, {});
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
    dispatch(setIsLoading(false));
  };

  const updateCustomer = async () => {
    if (IsEmpty(userId)) {
      errorToast("User ID is required");
      return;
    }
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(lastname)) {
      errorToast("Lastname is required");
      return;
    }

    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    dispatch(setIsLoading(true));

    await UpdateCustomer(dispatch, {
      userId,
      customerFirstname: firstname,
      customerLastname: lastname,
      roleId: 3,
      email,
      network,
      address,
      contact: "+92" + contact,
      erpCustomerId: erpCustomerId?.value,
      groupId,
      tester,
    })
      .then(async () => {
        // await GetUsers(dispatch);
        emptyFields(false);
        await SearchCustomers(dispatch, {});
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });

    dispatch(setIsLoading(false));
  };

  const emptyFields = (emptyEdit = true) => {
    setFirstname("");
    setErpCustomerId("");
    setLastname("");
    setContact("");
    setEmail("");
    setCity("");
    setNetwork("");
    setUserId("");
    setAddress("");
    setGroupId("");
    setTester(false);
    if (emptyEdit) {
      setEdit(null);
    }
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return (
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  return (
    <>
      <Modal
        title={edit ? "Update Customer" : "Add Customer"}
        name={"addCustomer"}
        icon={"fa fa-user-plus"}
      >
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              onClick={() => {
                setEdit(null);
                emptyFields();
              }}
              title="Go Back"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#listResidents"}
            ></i>
          </a>
        </div>
        <form>
          <>
            {user.groupId === 1 ? (
              <>
                <div className="row">
                  <div className="mb-4 col-lg-6">
                    <label className="col-form-label">{"Customer"}:</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={userId}
                      isLoading={isLoading}
                      isClearable={true}
                      isSearchable={true}
                      name="color"
                      value={erpCustomerId}
                      options={erpCustomersList}
                      onChange={(e) => setErpCustomerId(e || null)}
                    />
                  </div>
                  <div
                    className="mb-4 col-lg-6"
                    style={{
                      margin: "auto",
                      justifyItems: "flex-end",
                    }}
                  >
                    <div class="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={tester}
                        id="flexCheckDefault"
                        onChange={(e) => setTester(!tester)}
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault"
                      >
                        Add as a tester
                        <span>
                          <i
                            style={{
                              fontSize: "18px",
                              marginLeft: "10px",
                              color: "lightgray",
                            }}
                            className="fas fa-info-circle info"
                          ></i>
                        </span>
                      </label>
                      <Tooltip anchorSelect={`.info`} place="top">
                        <div>Will not receive SMS</div>

                        {/* <div>Site Address: {val.energizer?.address || ""}</div> */}
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <hr />
              </>
            ) : null}

            <div className="row">
              <div className="mb-3 col-lg-3">
                <MyTextInput
                  name={"Firstname *"}
                  val={firstname}
                  placeholder={""}
                  setVal={setFirstname}
                />
              </div>
              <div className="mb-3 col-lg-3">
                <MyTextInput
                  name={"Lastname"}
                  val={lastname}
                  placeholder={""}
                  setVal={setLastname}
                />
              </div>

              <div className="mb-3 col-lg-3">
                <MyTextInput
                  name={"Cell Phone # *"}
                  val={contact}
                  placeholder={"3xxxxxxxxxx"}
                  setVal={setContact}
                  max={10}
                  pre={true}
                />
              </div>
              <div className="col-lg-3">
                <label className="col-form-label">
                  Select Network Provider*:
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={network}
                  onChange={(e) => setNetwork(e.target.value)}
                >
                  <option value=""></option>
                  <option value="zong">Zong</option>
                  <option value="warid">Warid</option>
                  <option value="jazz">Jazz</option>
                  <option value="ufone">Ufone</option>
                  <option value="telenor">Telenor</option>
                  <option value="s-com">S-com</option>
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
              <div className="mb-3 col-lg-6">
                <MyTextInput
                  name={"Address"}
                  val={address}
                  setVal={setAddress}
                />
              </div>
              <div className="mb-3 col-lg-3">
                <MyTextInput name={"Email"} val={email} setVal={setEmail} />
              </div>
              <div className="col-lg-3">
                <label className="col-form-label">Select Group*:</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={groupId}
                  onChange={(e) => setGroupId(e.target.value)}
                >
                  <option value=""></option>
                  {groupList()}
                  {/* <option value="5">Device</option> */}
                </select>
              </div>
            </div>

            <br />
            <div className="row">
              <div className="col-lg-4"></div>
              <div className="col-lg-4" style={{ textAlign: "center" }}>
                {isLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : edit ? (
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      updateCustomer();
                    }}
                  >
                    Update User
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      addCustomer();
                    }}
                  >
                    Add User
                  </button>
                )}
              </div>
            </div>
            <br />
          </>
        </form>
      </Modal>
    </>
  );
};

export default AddCustomer;
