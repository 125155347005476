import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import { URL } from "../../../../../config";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import { api } from "../../../../../actions/apiClient";
import {
  setIsLoading,
  setSelectedUser,
} from "../../../../../redux/slices/userSlice";
import MyCheckbox from "../../../../../components/MyCheckbox";

const UpdateUserAccessControl = (props) => {
  const [addCustomer, setAddCustomer] = useState(false);
  const [listCustomer, setListCustomer] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [listUser, setListUser] = useState(false);
  const [addEfig, setAddEfig] = useState(false);
  const [listEfig, setListEfig] = useState(false);
  const [assignEfig, setAssignEfig] = useState(false);
  const [transferEfig, setTransferEfig] = useState(false);
  const [updateEfig, setUpdateEfig] = useState(false);
  const [addAnn, setAddAnn] = useState(false);
  const [listAnn, setListAnn] = useState(false);
  const [addPayments, setAddPayments] = useState(false);
  const [listPayments, setListPayments] = useState(false);
  const [addComplaint, setAddComplaint] = useState(false);
  const [listComplaint, setlistComplaint] = useState(false);
  const [accessControl, setAccessControl] = useState(false);
  const [closeComplaint, setCloseComplaint] = useState(false);
  const [sync, setSync] = useState(false);

  const selectedUser = useSelector((state) => state.user.selectedUser);
  const isLoading = useSelector((state) => state.user.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedUser?.id) {
      getAccessControl(selectedUser?.id);
    }
  }, [selectedUser]);

  const getAccessControl = async (userId) => {
    const token = localStorage.getItem("token");
    const url = URL + "user/getAccessControl";
    await api
      .post(
        url,
        {
          userId,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        console.log("accessControl", response.data);
        const data = response.data;
        setAddAnn(data.addAnn);
        setListAnn(data.listAnn);
        setAddComplaint(data.addComplaint);
        setlistComplaint(data.listComplaint);
        setAddCustomer(data.addCustomer);
        setListCustomer(data.listCustomer);
        setAddUser(data.addUser);
        setListUser(data.listUser);
        setAddEfig(data.addEfig);
        setListEfig(data.listEfig);
        setAssignEfig(data.assignEfig);
        setTransferEfig(data.transferEfig);
        setUpdateEfig(data.updateEfig);
        setAccessControl(data.accessControl);
        setAddPayments(data.addPayments);
        setListPayments(data.listPayments);
        setCloseComplaint(data.closeComplaint);
        setSync(data.sync);
      })
      .catch((e) => {
        errorToast(e.response?.data?.err || "Something went wrong");
        throw new Error(e);
      });
  };

  const UpdateUserAccessControl = async () => {
    dispatch(setIsLoading(true));

    const token = localStorage.getItem("token");
    const url = URL + "user/updateAccessControl";
    await api
      .post(
        url,
        {
          userId: selectedUser?.id,
          addComplaint,
          addCustomer,
          listCustomer,
          listComplaint,
          listAnn,
          addAnn,
          addPayments,
          listPayments,
          assignEfig,
          addEfig,
          listEfig,
          transferEfig,
          updateEfig,
          addUser,
          listUser,
          accessControl,
          closeComplaint,
          sync,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        emptyFields();
        dispatch(setSelectedUser(null));
        dispatch(setIsLoading(false));
        succesToast("Access Rights updated");
      })
      .catch((e) => {
        errorToast(e.response?.data?.err || "Something went wrong");
        dispatch(setIsLoading(false));
        throw new Error(e);
      });
  };

  const emptyFields = () => {
    setAddAnn(false);
    setListAnn(false);
    setAddComplaint(false);
    setlistComplaint(false);
    setAddCustomer(false);
    setListCustomer(false);
    setAddUser(false);
    setListUser(false);
    setAddEfig(false);
    setTransferEfig(false);
    setUpdateEfig(false);
    setAccessControl(false);
    setAssignEfig(false);
    setAddPayments(false);
    setListPayments(false);
    setListEfig(false);
    setCloseComplaint(false);
    setSync(false);
  };

  return (
    <>
      <Modal
        title={"User Access Control"}
        name={"userAccessControl"}
        icon={"fa fa-solid fa-lock"}
      >
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              onClick={() => {
                dispatch(setSelectedUser(null));
              }}
              title="Go Back"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#listUser"}
            ></i>
          </a>
          <br />
          <div className="row">
            <center>
              {" "}
              <h3>
                Update Access control for {selectedUser?.firstname}{" "}
                {selectedUser?.lastname}{" "}
              </h3>{" "}
            </center>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <MyCheckbox
                val={addComplaint}
                name={"Add Complaint"}
                onChange={setAddComplaint}
              />
              <MyCheckbox
                val={listComplaint}
                name={"List Complaint"}
                onChange={setlistComplaint}
              />
              <MyCheckbox
                val={closeComplaint}
                name={"Close Complaint"}
                onChange={setCloseComplaint}
              />
              <MyCheckbox
                val={addUser}
                name={"Add User"}
                onChange={setAddUser}
              />
              <MyCheckbox
                val={listUser}
                name={"List User"}
                onChange={setListUser}
              />
              <MyCheckbox
                val={addCustomer}
                name={"Add Customer"}
                onChange={setAddCustomer}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <MyCheckbox
                val={listCustomer}
                name={"List Customer"}
                onChange={setListCustomer}
              />
              <MyCheckbox
                val={addEfig}
                name={"Add Efig"}
                onChange={setAddEfig}
              />
              <MyCheckbox
                val={listEfig}
                name={"List Efig"}
                onChange={setListEfig}
              />
              <MyCheckbox
                val={addAnn}
                name={"Add Announcement"}
                onChange={setAddAnn}
              />
              <MyCheckbox
                val={listAnn}
                name={"List Announcement"}
                onChange={setListAnn}
              />
              <MyCheckbox
                val={assignEfig}
                name={"Assign Efig"}
                onChange={setAssignEfig}
              />
            </div>
            <div className="col-md-4 col-sm-12">
              <MyCheckbox
                val={transferEfig}
                name={"Transfer Efig"}
                onChange={setTransferEfig}
              />
              <MyCheckbox
                val={updateEfig}
                name={"Update Efig"}
                onChange={setUpdateEfig}
              />
              <MyCheckbox
                val={addPayments}
                name={"Add Payments"}
                onChange={setAddPayments}
              />
              <MyCheckbox
                val={listPayments}
                name={"List Payments"}
                onChange={setListPayments}
              />
              <MyCheckbox
                val={accessControl}
                name={"Access Control"}
                onChange={setAccessControl}
              />
              <MyCheckbox val={sync} name={"Allow sync"} onChange={setSync} />
            </div>
          </div>
          <br />
          <br />

          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4" style={{ textAlign: "center" }}>
              {isLoading ? (
                <button className="btn btn-primary" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  &nbsp; Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  onClick={(e) => {
                    e.preventDefault();
                    UpdateUserAccessControl();
                  }}
                >
                  Update
                </button>
              )}
            </div>
          </div>
          <br />
        </div>
      </Modal>
    </>
  );
};

export default UpdateUserAccessControl;
