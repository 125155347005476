import { configureStore } from "@reduxjs/toolkit";
import chatSlice from "./slices/chatSlice";
import logsSlice from "./slices/logsSlice";
import mapSlice from "./slices/mapSlice";
import reportsSlice from "./slices/reportsSlice";
import themeSlice from "./slices/themeSlice";
import userSlice from "./slices/userSlice";
import complaintSlice from "./slices/complaintSlice";
import efigSlice from "./slices/efigSlice";
import erpSlice from "./slices/erpSlice";
import paymentSlice from "./slices/paymentSlice";

export const store = configureStore({
  reducer: {
    theme: themeSlice,
    user: userSlice,
    efig: efigSlice,
    map: mapSlice,
    chat: chatSlice,
    logs: logsSlice,
    report: reportsSlice,
    complaint: complaintSlice,
    erp: erpSlice,
    payment: paymentSlice,
  },
});
