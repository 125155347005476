import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setEditDeviceData,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import {
  setEditEfig,
  setEfigLoading,
} from "../../../../../redux/slices/efigSlice";
import {
  GetEFIGList,
  GetErpProjects,
  UpdateEFIGAPI,
} from "../../../../../actions";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

// let efigSerialArray = [];
let projectArray = [];
// let customerArray = [];

const UpdateEfig = (props) => {
  const [address, setAddress] = useState("");
  const [projectId, setProjectId] = useState("");
  const [energizerSerial, setEnergizerSerial] = useState("");
  const [energizerType, setEnergizerType] = useState("");

  const isLoading = useSelector((state) => state.efig.efigLoading);
  const editEfig = useSelector((state) => state.efig.editEfig);
  const erpProjectList = useSelector((state) => state.erp.erpProjectList);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();

  useEffect(() => {
    getErpProj();
  }, []);

  const getErpProj = async () => {
    await GetErpProjects(dispatch);
  };

  useEffect(() => {
    if (editEfig) {
      setAddress(editEfig?.address);
      if (editEfig?.erpProjectId) {
        erpProjectList.every((project) => {
          if (project.id === editEfig.erpProjectId) {
            setProjectId(project);
            return false;
          }
          return true;
        });
      }
      setEnergizerSerial(editEfig?.energizerSerial);
      setEnergizerType(editEfig?.type);
    }
  }, [editEfig]);

  const UpdateEfig = async () => {
    if (IsEmpty(energizerSerial)) {
      errorToast("Energizer Serial # is required");
      return;
    }
    if (IsEmpty(address)) {
      errorToast("Address is required");
      return;
    }
    if (IsEmpty(energizerType)) {
      errorToast("Energizer Type is required");
      return;
    }

    dispatch(setEfigLoading(true));

    await UpdateEFIGAPI(dispatch, {
      energizerSerial,
      projectId: projectId?.value,
      address,
      energizerId: editEfig?.id,
      type: energizerType,
    })
      .then(async () => {
        empty();
        await GetEFIGList(dispatch);
        dispatch(setEfigLoading(false));
      })
      .catch((e) => {
        dispatch(setEfigLoading(false));
      });
  };

  const empty = () => {
    setEnergizerType("");
    setEnergizerSerial("");
    setAddress("");
    setProjectId(null);
  };

  useEffect(() => {
    if (projectId) {
      setAddress(projectId.label);
    }
  }, [projectId]);

  return (
    <>
      <Modal
        title={"Update EFIG Details"}
        name={"UpdateEfig"}
        icon={"fa-solid fa-link"}
      >
        <div style={{ height: "60vh" }}>
          <div className="col-12">
            <a
              href="#"
              className="nav-link"
              onClick={() => {
                empty();
                dispatch(setEditEfig(null));
              }}
            >
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To List"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#listEfigs"}
              ></i>
            </a>
          </div>
          <form>
            <>
              <div className="row">
                <div className="mb-4 col-lg-3">
                  <MyTextInput
                    name={"Energizer #"}
                    val={energizerSerial}
                    placeholder={"Energizer #"}
                    setVal={setEnergizerSerial}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="col-form-label">Energizer Type:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={energizerType}
                    onChange={(e) => setEnergizerType(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="Z-11">Energizer Z-11</option>
                    <option value="Z-14">Energizer Z-14</option>
                    <option value="Z-18">Energizer Z-18</option>
                    <option value="Z-28">Energizer Z-28</option>
                  </select>
                </div>

                {user.groupId === 1 ? (
                  <>
                    <div className="mb-4 col-lg-4">
                      <label className="col-form-label">{"Project Id"}:</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={projectId}
                        isLoading={isLoading}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        value={projectId}
                        options={erpProjectList}
                        onChange={(e) => setProjectId(e || null)}
                      />
                    </div>
                  </>
                ) : null}

                <div className="mb-4 col-lg-4">
                  <MyTextInput
                    name={"Address"}
                    val={address}
                    placeholder={"Address"}
                    setVal={setAddress}
                  />
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col-4"></div>
                <div className="col-4" style={{ textAlign: "center" }}>
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      &nbsp; Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        UpdateEfig();
                      }}
                    >
                      Update EFIG
                    </button>
                  )}
                </div>
              </div>
              <br />
            </>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default UpdateEfig;
