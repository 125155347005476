import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mapStyle: "mapbox://styles/mapbox/streets-v12",
  ubricaMapStyle: "mapbox://styles/mapbox/streets-v12",
  ubricaTheme: "light",
  geofences: [],
  mapZoom: 12,
  latitude: 31,
  longitude: 74,
  geoLng: 74.42696711164534,
  geoLat: 31.521113782075002,
  focusedMarker: null,
  focusedMarkerAlways: null,
  geoIsLoading: null,
  showStaticMarkerLayer: true,
  staticMarker: [],
  markerLocation: null,
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setMapStyle: (state, action) => {
      state.mapStyle = action.payload;
    },
    setUbricaMapStyle: (state, action) => {
      state.ubricaMapStyle = action.payload;
    },
    setUbricaTheme: (state, action) => {
      state.ubricaTheme = action.payload;
    },
    setGeofences: (state, action) => {
      state.geofences = action.payload;
    },
    setZoom: (state, action) => {
      state.mapZoom = action.payload;
    },
    setLatLng: (state, action) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    setGeoLatLng: (state, action) => {
      state.geoLat = action.payload.geoLat;
      state.geoLng = action.payload.geoLng;
    },
    setFocusedMarker: (state, action) => {
      state.focusedMarker = action.payload;
    },
    setFocusedMarkerAlways: (state, action) => {
      state.focusedMarkerAlways = action.payload;
    },
    setGeoIsLoading: (state, action) => {
      state.geoIsLoading = action.payload;
    },
    setStaticMarker: (state, action) => {
      state.staticMarker = action.payload;
    },
    setShowStaticMarkerLayer: (state, action) => {
      state.showStaticMarkerLayer = action.payload;
    },
    setMarkerLocation: (state, action) => {
      state.markerLocation = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setMapStyle,
  setGeofences,
  setZoom,
  setGeoLatLng,
  setLatLng,
  setFocusedMarker,
  setGeoIsLoading,
  setUbricaMapStyle,
  setUbricaTheme,
  setStaticMarker,
  setShowStaticMarkerLayer,
  setMarkerLocation,
  setFocusedMarkerAlways,
} = mapSlice.actions;

export default mapSlice.reducer;
