import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import MyTextInput from "../../../../../components/MyTextInput";
import {
  setActionVal,
  setConfirmText,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { globalDeleteBtn } from "../../../../../components/DeleteConfirm";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { GetAllPayments, SearchPayment } from "../../../../../actions";
import { erpUrl } from "../../../../../config";

const ListPayment = (props) => {
  const dispatch = useDispatch();
  const [paymentNumber, setPaymentNumber] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [serial, setSerial] = useState("");
  const [amount, setAmount] = useState("");
  const [so, setSo] = useState("");
  const [sub, setSub] = useState("");
  const [startDate1, setStartDate1] = useState(null);
  const [endDate1, setEndDate1] = useState(null);

  const [startDate2, setStartDate2] = useState(null);
  const [endDate2, setEndDate2] = useState(null);
  const [status, setStatus] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const paymentList = useSelector((state) => state.payment.list);
  const user = useSelector((state) => state.user.user);

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  useEffect(() => {
    // search();
    getAllPayments();
  }, []);

  const getAllPayments = async () => {
    GetAllPayments(dispatch);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const search = async () => {
    dispatch(setIsLoading(true));
    await SearchPayment(dispatch, {
      customerName: name,
      paymentNumber,
      amount,
      subscription: sub,
      address,
      startDate1,
      startDate2,
      endDate1,
      endDate2,
      espSerial: serial,
      soNumber: so,
    });
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "group") {
      deleteGroupAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const deleteGroup = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Group?",
        type: "group",
        id,
      })
    );
    globalDeleteBtn.click();
  };

  const deleteGroupAction = async (id) => {
    // await DeleteGroupAPI(dispatch, { groupId: id });
    // await GetAllGroups(dispatch);
    // await search();
  };

  const getList = () => {
    let count = 0;
    return paymentList?.map((val, index) => {
      count++;
      return (
        <Fragment key={index}>
          <tr key={index}>
            <th scope="row">{count}</th>
            <td className={`pay_${val?.id}`}>{`${
              val?.paymentNumber || ""
            }`}</td>
            <td>{`${val?.user?.customerFirstname || ""} ${
              val?.user?.customerLastname || ""
            }`}</td>
            <td className={`address_${val?.id}`}>{`${
              val?.energizer?.address || ""
            }`}</td>
            <td>{`${val?.energizer?.espSerial || ""} ${
              val?.energizer?.energizerSerial
                ? "/" + val?.energizer?.energizerSerial
                : ""
            }`}</td>
            <td className={`pay_${val?.id}`}>{`Rs. ${
              new Intl.NumberFormat().format(val?.amount) || "0"
            }/-`}</td>
            <td className={`erp_details_${val?.id}`}>
              <a
                href={`${erpUrl}commande/card.php?id=${val?.saleOrderId}`}
                target="_blank"
              >
                {val?.saleOrderNumber}
              </a>
            </td>
            <td>{`${
              val?.subscription == "1"
                ? "06 Months"
                : val?.subscription == "2"
                ? "01 Year"
                : "Custom"
            }`}</td>

            <td>
              {new Date(val?.startDate).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                // hour: "2-digit",
                // minute: "2-digit",
              })}
            </td>
            <td>
              {new Date(val?.endDate).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                // hour: "2-digit",
                // minute: "2-digit",
              })}
            </td>
            {/* <td>{`${
              new Date() > new Date(val?.endDate) ? "Expired" : "Valid"
            }`}</td> */}
            <td>
              {user.roleId == 1 || user?.accessControl?.addPayment ? (
                <i
                  style={{ fontSize: "24px", cursor: "pointer" }}
                  className="fas fa-plus-circle"
                  title="Add Payment"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target={"#addPayment"}
                ></i>
              ) : null}
            </td>

            {/* <td>
              <a className={`nav-link`} href="#">
                <i
                  style={{
                    fontSize: "20px",
                    color: "blue",
                  }}
                  className="fa fa-light fa-pencil"
                  onClick={() => {
                    dispatch(setEditGroupData(val));
                  }}
                  title="Edit"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target="#addGroup"
                ></i>

                {val.id != 1 ? (
                  <i
                    style={{
                      fontSize: "20px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                    className="fa fa-light fa-trash"
                    onClick={() => deleteGroup(val.id)}
                    title="Delete"
                  ></i>
                ) : null}
              </a>
            </td> */}
          </tr>
          <Tooltip anchorSelect={`.pay_${val?.id}`} place="top">
            <div>Ref: {val?.ref || ""}</div>
            <div>Note: {val?.note || ""}</div>
            <div>
              Payment Date:&nbsp;
              {new Date(val?.paymentDate).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
            {/* <div>Site Address: {val.energizer?.address || ""}</div> */}
          </Tooltip>
          <Tooltip anchorSelect={`.address_${val?.id}`} place="top">
            <div>User Address: {val?.user?.address || ""}</div>
          </Tooltip>
          <Tooltip anchorSelect={`.erp_details_${val?.id}`} place="top">
            <div>
              File Ref: {val?.jsonData?.array_options?.options_fileref || ""}
            </div>
          </Tooltip>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Modal
        title={"List Payments"}
        name={"listPayments"}
        icon={"fa-regular fa-credit-card"}
      >
        <div className="row">
          <div className="col-4">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add Payment"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addPayment"}
              ></i>
            </a>
          </div>
          <div className="col-4">
            <div className="text-center">
              <h6>{`Total Records: ${paymentList?.length}`}</h6>
            </div>
          </div>
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={paymentNumber}
                      placeholder={"Payment #"}
                      onChange={(e) => setPaymentNumber(e.target.value)}
                    />
                    <label className="col-form-label">Payment # </label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={name}
                      placeholder={"Name"}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Customer </label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={address}
                      placeholder={"address"}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="col-form-label">Address</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={serial}
                      placeholder={"Serial"}
                      onChange={(e) => setSerial(e.target.value)}
                    />
                    <label className="col-form-label">Serial #</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={amount}
                      placeholder={"Amount"}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <label className="col-form-label">Amount</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={so}
                      placeholder={"ERP SO #"}
                      onChange={(e) => setSo(e.target.value)}
                    />
                    <label className="col-form-label">ERP SO #</label>
                  </th>
                  <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={sub}
                      onChange={(e) => setSub(e.target.value)}
                    >
                      <option value={""}></option>
                      <option value="1">06 Months</option>
                      <option value="2">01 Year</option>
                    </select>
                    <label className="col-form-label">Subscription</label>
                  </th>
                  <th>
                    {/* <div className="row"> */}
                    <div className="col-12">
                      <DatePicker
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={startDate1}
                        onChange={(date) => setStartDate1(date)}
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        withPortal
                      />
                    </div>
                    <div className="col-12">
                      <DatePicker
                        placeholderText="End Date"
                        className="form-control form-control-sm"
                        selected={startDate2}
                        onChange={(date) => setStartDate2(date)}
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        withPortal
                      />
                    </div>
                    {/* </div> */}
                    <label className="col-form-label">Start Date</label>
                  </th>
                  <th>
                    {/* <div className="row"> */}
                    <div className="col-12">
                      <DatePicker
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={endDate1}
                        onChange={(date) => setEndDate1(date)}
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        withPortal
                      />
                    </div>
                    <div className="col-12">
                      <DatePicker
                        placeholderText="End Date"
                        className="form-control form-control-sm"
                        selected={endDate2}
                        onChange={(date) => setEndDate2(date)}
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        withPortal
                      />
                    </div>
                    {/* </div> */}
                    <label className="col-form-label">End Date</label>
                  </th>
                  {/* <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={""}></option>
                      <option value="1">Valid</option>
                      <option value="2">Expired</option>
                    </select>
                    <label className="col-form-label">Status</label>
                  </th> */}

                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                        style={{
                          width: "80px",
                        }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        style={{
                          width: "80px",
                        }}
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>

              <tbody>{getList()}</tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListPayment;
