import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  complaintList: [],
  activeComplaintList: [],
  searchedActiveComplaintList: [],
  activeElectricComplaints: [],
  activeCivilComplaints: [],
  activeSanitaryComplaints: [],
  activePlumbingComplaints: [],
  activeMiscComplaints: [],
  searchedComplaints: [],
  complaintId: null,
  erpComplaintData: null,
  electricTabBeat: false,
  civilTabBeat: false,
  sanitaryTabBeat: false,
  plumbingTabBeat: false,
  miscTabBeat: false,
  isCloseLoading: false,
  isdDeleteLoading: false,
  isComplaintLoading: false,
  isAssignLoading: false,
  isResolveLoading: false,
  activeComplaintView: false,
  complaintDetail: null,
  complaintDetailEvent: null,
  chartComplaintList: [],
  totalComplaintDataChart: [],
  closedComplaintDataChart: [],
  activeComplaintDataChart: [],
  responderAssignments: [],
  responderAssignmentLoading: false,

  allResponderAssignments: [],
  preferedTime: "",
};

export const complaintSlice = createSlice({
  name: "complaint",
  initialState,
  reducers: {
    setComplaintList: (state, action) => {
      state.complaintList = action.payload;
    },
    setActiveComplaintList: (state, action) => {
      state.activeComplaintList = action.payload;
    },
    setSearchedActiveComplaintList: (state, action) => {
      state.searchedActiveComplaintList = action.payload;
    },
    setActiveElectricComplaints: (state, action) => {
      state.activeElectricComplaints = action.payload;
    },
    setActiveCivilComplaints: (state, action) => {
      state.activeCivilComplaints = action.payload;
    },
    setActiveSanitaryComplaints: (state, action) => {
      state.activeSanitaryComplaints = action.payload;
    },
    setActivePlumbingComplaints: (state, action) => {
      state.activePlumbingComplaints = action.payload;
    },
    setActiveMiscComplaints: (state, action) => {
      state.activeMiscComplaints = action.payload;
    },

    setComplaintId: (state, action) => {
      state.complaintId = action.payload;
    },

    setSearchedComplaints: (state, action) => {
      state.searchedComplaints = action.payload;
    },
    setIsCloseLoading: (state, action) => {
      state.isCloseLoading = action.payload;
    },
    setIsResolveLoading: (state, action) => {
      state.isResolveLoading = action.payload;
    },
    setIsAssignLoading: (state, action) => {
      state.isAssignLoading = action.payload;
    },
    setIsComplaintLoading: (state, action) => {
      state.isComplaintLoading = action.payload;
    },
    setComplaintDetail: (state, action) => {
      state.complaintDetail = action.payload;
    },
    setComplaintDetailEvent: (state, action) => {
      state.complaintDetailEvent = action.payload;
    },
    setChartComplaintList: (state, action) => {
      state.chartComplaintList = action.payload;
    },
    setTotalComplaintDataChart: (state, action) => {
      state.totalComplaintDataChart = action.payload;
    },
    setActiveComplaintDataChart: (state, action) => {
      state.activeComplaintDataChart = action.payload;
    },
    setClosedComplaintDataChart: (state, action) => {
      state.closedComplaintDataChart = action.payload;
    },
    setErpComplaintData: (state, action) => {
      state.erpComplaintData = action.payload;
    },
    setElectricTabBeat: (state, action) => {
      state.electricTabBeat = action.payload;
    },
    setCivilTabBeat: (state, action) => {
      state.civilTabBeat = action.payload;
    },
    setSanitaryTabBeat: (state, action) => {
      state.sanitaryTabBeat = action.payload;
    },
    setPlumbingTabBeat: (state, action) => {
      state.plumbingTabBeat = action.payload;
    },
    setMiscTabBeat: (state, action) => {
      state.miscTabBeat = action.payload;
    },
    setResponderAssignments: (state, action) => {
      state.responderAssignments = action.payload;
    },
    setAllResponderAssignments: (state, action) => {
      state.allResponderAssignments = action.payload;
    },
    setResponderAssignmentLoading: (state, action) => {
      state.responderAssignmentLoading = action.payload;
    },
    setPreferedTime: (state, action) => {
      state.preferedTime = action.payload;
    },
    setIsDeleteLoading: (state, action) => {
      state.isdDeleteLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setComplaintList,
  setActiveComplaintList,
  setComplaintId,
  setIsComplaintLoading,
  setIsCloseLoading,
  setIsResolveLoading,
  setSearchedComplaints,
  setIsAssignLoading,
  setIsRelocateLoading,
  setSearchedCusComplaints,
  setSearchedType2Complaints,
  setActiveComplaintView,
  setComplaintDetail,
  setComplaintDetailEvent,
  setActiveCivilComplaints,
  setActiveElectricComplaints,
  setActiveMiscComplaints,
  setActiveSanitaryComplaints,
  setSearchedActiveComplaintList,
  setActivePlumbingComplaints,
  setChartComplaintList,
  setActiveComplaintDataChart,
  setClosedComplaintDataChart,
  setTotalComplaintDataChart,
  setCivilTabBeat,
  setElectricTabBeat,
  setMiscTabBeat,
  setPlumbingTabBeat,
  setSanitaryTabBeat,
  setResponderAssignments,
  setResponderAssignmentLoading,
  setPreferedTime,
  setErpComplaintData,
  setAllResponderAssignments,
  setIsDeleteLoading,
} = complaintSlice.actions;

export default complaintSlice.reducer;
