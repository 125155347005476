/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";

import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  DeleteComplaintApi,
  DeleteCustomer,
  FetchComplaintChatSingle,
  GetActiveComplaints,
  GetErpComplaintDetails,
  UpdateComplaintDetails,
  UpdateComplaintState,
} from "../../../../../actions";
import { setComplaintChatSingle } from "../../../../../redux/slices/chatSlice";
import {
  setActionVal,
  setConfirmText,
  setImageDetail,
  setUserDetails,
} from "../../../../../redux/slices/userSlice";
import { setMarkerLocation } from "../../../../../redux/slices/mapSlice";
import { URL, erpUrl } from "../../../../../config";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  setComplaintDetail,
  setIsAssignLoading,
  setIsCloseLoading,
  setIsComplaintLoading,
  setIsDeleteLoading,
} from "../../../../../redux/slices/complaintSlice";
import MyTextInput from "../../../../../components/MyTextInput";
import Select from "react-select";
import { globalDeleteBtn } from "../../../../../components/DeleteConfirm";
import { setEfig } from "../../../../../redux/slices/efigSlice";

var arr = [];

const ComplaintDetailsMain = (props) => {
  const dispatch = useDispatch();
  const [alarmStr, setAlarmStr] = useState(false);
  const [note, setNote] = useState("");
  const [editType, setEditType] = useState("");
  const [soc, setSoc] = useState("");
  const [tech, setTech] = useState("");
  const [project, setProject] = useState("");
  const isComplaintLoading = useSelector(
    (state) => state.complaint.isComplaintLoading
  );
  const complaintDetail = useSelector(
    (state) => state.complaint.complaintDetail
  );
  const erpComplaintData = useSelector(
    (state) => state.complaint.erpComplaintData
  );
  const isCloseLoading = useSelector((state) => state.complaint.isCloseLoading);
  const isAssignLoading = useSelector(
    (state) => state.complaint.isAssignLoading
  );
  const isDeleteLoading = useSelector(
    (state) => state.complaint.isDeleteLoading
  );
  const user = useSelector((state) => state.user.user);
  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);
  // const erpUsersList = useSelector((state) => state.erp.erpUsersList);
  const erpCustomersList = useSelector((state) => state.erp.erpCustomersList);
  const erpProjectList = useSelector((state) => state.erp.erpProjectList);
  const erpTechList = useSelector((state) => state.erp.erpTechList);
  const activeComplaintList = useSelector(
    (state) => state.complaint.activeComplaintList
  );
  const updateComplaintState = async (state) => {
    await UpdateComplaintState(dispatch, {
      complaintId: complaintDetail.id,
      state,
    });
    await GetActiveComplaints(dispatch);
  };

  const updateComplaintDetails = async (state) => {
    if (IsEmpty(soc) && IsEmpty(project) && IsEmpty(tech)) {
      errorToast("Field Is Empty");
      return;
    }
    await UpdateComplaintDetails(dispatch, {
      complaintId: complaintDetail.id,
      fk_soc: soc.value,
      soc: soc.value,
      project: project.value,
      fk_project: project.value,
      techId: tech.value,
    });
    setSoc("");
    setTech("");
    setProject("");
    setEditType("");
    await GetActiveComplaints(dispatch);
  };

  const acceptComplaintAction = async () => {
    dispatch(setIsAssignLoading(true));
    await UpdateComplaintState(dispatch, {
      complaintId: complaintDetail.id,
      state: 2,
      operatorId: user.id,
    });
    await GetActiveComplaints(dispatch);
    dispatch(setIsAssignLoading(false));
  };

  const closeComplaintAction = async () => {
    if (IsEmpty(note)) {
      errorToast("Remarks are required for closing");
      return;
    }

    dispatch(setIsCloseLoading(true));

    await UpdateComplaintState(dispatch, {
      complaintId: complaintDetail.id,
      state: 8,
      closedBy: `${user?.firstname || ""} ${user?.lastname || ""}`,
      note,
    });
    await GetActiveComplaints(dispatch);
    dispatch(setComplaintDetail(null));
    dispatch(setIsCloseLoading(false));
  };

  const discardComplaintAction = async () => {
    if (IsEmpty(note)) {
      errorToast("Remarks are required.");
      return;
    }
    dispatch(setIsCloseLoading(true));

    await UpdateComplaintState(dispatch, {
      complaintId: complaintDetail.id,
      state: 9,
      discardBy: `${user?.firstname || ""} ${user?.lastname || ""}`,
      note,
    });
    await GetActiveComplaints(dispatch);
    dispatch(setComplaintDetail(null));
    dispatch(setIsCloseLoading(false));
  };

  const deleteComplaintAction = async () => {
    dispatch(setIsDeleteLoading(true));
    await DeleteComplaintApi(dispatch, {
      complaintId: complaintDetail.id,
      state: 9,
      discardBy: `${user?.firstname || ""} ${user?.lastname || ""}`,
      note,
    });
    await GetActiveComplaints(dispatch);
    dispatch(setComplaintDetail(null));
    dispatch(setIsDeleteLoading(false));
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "accCmp") {
      acceptComplaintAction();
      dispatch(setActionVal(null));
      setNote("");
    } else if (actionVal?.action === "1" && actionVal?.type === "closeCmp") {
      closeComplaintAction();
      setNote("");
      dispatch(setActionVal(null));
    } else if (actionVal?.action === "1" && actionVal?.type === "discardCmp") {
      discardComplaintAction();
      setNote("");
      dispatch(setActionVal(null));
    } else if (actionVal?.action === "1" && actionVal?.type === "delCmp") {
      deleteComplaintAction();
      dispatch(setActionVal(null));
      setNote("");
    }
  }, [actionVal]);

  const delComplaint = async () => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Complaint?",
        type: "delCmp",
      })
    );
    globalDeleteBtn?.click();
  };

  const accComplaint = async () => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to accept this Complaint?",
        type: "accCmp",
      })
    );
    globalDeleteBtn?.click();
  };

  const closeComplaint = async () => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to close this Complaint?",
        type: "closeCmp",
      })
    );
    globalDeleteBtn?.click();
  };

  const discardComplaint = async () => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to abandon this Complaint?",
        type: "discardCmp",
      })
    );
    globalDeleteBtn?.click();
  };

  useEffect(() => {
    activeComplaintList?.forEach((val) => {
      if (complaintDetail?.id === val?.id) {
        dispatch(setComplaintDetail(val));
      }
    });
  }, [activeComplaintList]);

  useEffect(() => {
    if (complaintDetail?.dolibarrTicketId) {
      GetErpComplaintDetails(dispatch, complaintDetail);
    }
  }, [complaintDetail]);

  useEffect(() => {
    let alarm = "";
    if (complaintDetail?.energizer?.fenceAlarm) {
      alarm += "Fence Alarm, ";
    }
    if (complaintDetail?.energizer?.acFail) {
      alarm += "AC Fail, ";
    }
    if (complaintDetail?.energizer?.tamperAlarm) {
      alarm += "Tamper Alarm, ";
    }
    if (complaintDetail?.energizer?.lowPower) {
      alarm += "Low Power Mode";
    }

    setAlarmStr(alarm);
  }, [complaintDetail]);

  return (
    <>
      {complaintDetail ? (
        <>
          <div
            style={{ padding: "10px", overflowY: "auto", height: "90vh" }}
            className=""
          >
            <div className="row">
              <div className="col-lg-12">
                <a
                  href="#"
                  className="nav-link float-end"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setComplaintDetail(null));
                    setSoc("");
                    setTech("");
                    setProject("");
                  }}
                >
                  <i
                    style={{ fontSize: "26px", color: "red" }}
                    className="fa fa-light fa-close"
                  ></i>
                </a>
              </div>
            </div>
            {/* <div className="row">
                <div
                  className="col-lg-6"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  Customer Details:
                </div>
                <div
                  className="col-lg-6"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  Complaint Details:
                </div>
              </div> */}

            <div className="row">
              <div className="col-lg-6">
                <div
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Customer Details{" "}
                </div>
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th scope="row">Name:</th>
                      <td>
                        {editType === "soc" ? (
                          <div className="row">
                            <div className="mb-4 col-lg-8">
                              <Select
                                className="basic-single"
                                placeholder="Select Customer"
                                classNamePrefix="select"
                                // isLoading={isLoading}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                value={soc}
                                options={erpCustomersList}
                                onChange={(e) => setSoc(e || null)}
                              />
                            </div>
                            <div className="col-lg-4">
                              {isComplaintLoading ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>{" "}
                                  &nbsp; Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-primary w-100"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    updateComplaintDetails();
                                  }}
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <>
                            {complaintDetail?.state < 8 ? (
                              <a href="#">
                                <i
                                  style={{
                                    fontSize: "18px",
                                    marginRight: "8px",
                                    color: "lightblue",
                                  }}
                                  className={`fa fa-light fa-pencil`}
                                  onClick={() => {
                                    setEditType("soc");
                                    setSoc("");
                                    setTech("");
                                    setProject("");
                                  }}
                                ></i>
                              </a>
                            ) : null}
                            {complaintDetail?.isErp ? (
                              <>
                                {`${
                                  complaintDetail?.erpCustomer?.fullname || ""
                                }`}
                              </>
                            ) : (
                              <>
                                {`${
                                  complaintDetail?.user?.customerFirstname || ""
                                } ${
                                  complaintDetail?.user?.customerLastname || ""
                                }`}
                              </>
                            )}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Address: </th>
                      <td>
                        {complaintDetail.isErp ? (
                          <>
                            {`${complaintDetail?.erpCustomer?.address || ""}`}
                          </>
                        ) : (
                          <>{`${complaintDetail?.user?.address || ""}`}</>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Contact #: </th>
                      <td>
                        {complaintDetail?.isErp ? (
                          <>
                            {`${complaintDetail?.erpCustomer?.contact || ""}`}
                          </>
                        ) : (
                          <>{`${complaintDetail?.user?.contact || ""}`}</>
                        )}
                      </td>
                    </tr>
                    {!complaintDetail?.isErp ? (
                      <tr>
                        <th scope="row">Device Admin: </th>
                        <td>
                          {`${complaintDetail?.user?.customerFirstname || ""} ${
                            complaintDetail?.user?.customerLastname || ""
                          }`}
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <th scope="row">Site Address:</th>
                      <td>
                        {editType === "project" ? (
                          <>
                            <div className="row">
                              <div className="mb-4 col-lg-8">
                                <Select
                                  className="basic-single"
                                  placeholder="Select Project"
                                  classNamePrefix="select"
                                  // isLoading={isLoading}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  value={project}
                                  options={erpProjectList}
                                  onChange={(e) => setProject(e || null)}
                                />
                              </div>
                              <div className="col-lg-4">
                                {isComplaintLoading ? (
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>{" "}
                                    &nbsp; Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      updateComplaintDetails();
                                    }}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {complaintDetail?.state < 8 ? (
                              <a href="#">
                                <i
                                  style={{
                                    fontSize: "18px",
                                    marginRight: "8px",
                                    color: "lightblue",
                                  }}
                                  className="fa fa-light fa-pencil"
                                  onClick={() => {
                                    setEditType("project");
                                    setSoc("");
                                    setTech("");
                                    setProject("");
                                  }}
                                ></i>
                              </a>
                            ) : null}
                            {`${complaintDetail?.erpProject?.address || ""}`}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {!complaintDetail?.isErp ? (
                  <>
                    <div
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Energizer Status{" "}
                    </div>
                    <table className="table table-hover">
                      <tbody>
                        <tr>
                          <th scope="row">Alarm:</th>
                          <td>{`${alarmStr || "None"}`}</td>
                        </tr>
                        <tr>
                          <th scope="row">Internet: </th>
                          <td>{`${
                            complaintDetail?.energizer?.internet
                              ? "Connected"
                              : "Disconnected"
                          }`}</td>
                        </tr>
                        <tr>
                          <th scope="row">Energizer State: </th>
                          <td>{`${
                            complaintDetail?.energizer?.energizerState
                              ? "Armed"
                              : "Disarmed"
                          }`}</td>
                        </tr>
                        <tr>
                          <th scope="row">Battery Volts:</th>
                          <td>{`${
                            complaintDetail?.energizer?.batteryVolts || ""
                          } V`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : null}
              </div>
              <div className="col-lg-6">
                <div
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Complaint Details{" "}
                </div>
                <table className="table table-hover">
                  <tbody>
                    {!complaintDetail?.isErp ? (
                      <tr>
                        <th scope="row">EFIG/Energizer #:</th>
                        <td className="nav-link">
                          <a
                            href="#"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            data-bs-toggle="modal"
                            data-bs-target={"#efigDetails"}
                            onClick={() => {
                              dispatch(setEfig(complaintDetail?.energizer));
                              dispatch(setUserDetails(complaintDetail?.user));
                            }}
                          >
                            {`${complaintDetail?.energizer?.espSerial || ""}/ ${
                              complaintDetail?.energizer?.energizerSerial || ""
                            }`}
                          </a>
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <th scope="row">Complaint #:</th>
                      <td>{`${complaintDetail?.complaintNumber || ""}`}</td>
                    </tr>
                    <tr>
                      <th scope="row">Ticket Ref:</th>
                      <td>
                        <a
                          href={`${erpUrl}ticket/card.php?id=${complaintDetail?.dolibarrTicketId}`}
                          target="_blank"
                        >
                          {`${erpComplaintData?.ref || ""}`}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Complaint Type: </th>
                      <td>{`${complaintDetail?.title || ""}`}</td>
                    </tr>
                    <tr>
                      <th scope="row">Description:</th>
                      <td>{`${
                        complaintDetail?.des ||
                        complaintDetail.jsonData?.message ||
                        ""
                      }`}</td>
                    </tr>
                    <tr>
                      <th scope="row">Status:</th>
                      <td>
                        {complaintDetail?.state == 1
                          ? "In Query"
                          : complaintDetail?.state == 2
                          ? "Assigned"
                          : complaintDetail?.state == 3
                          ? "In Process"
                          : complaintDetail?.state == 7
                          ? "On Hold"
                          : complaintDetail?.state == 5
                          ? "Waiting for Feedback"
                          : complaintDetail?.state == 9
                          ? "Cancelled"
                          : complaintDetail?.state == 8
                          ? "Resolved"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Created At:</th>
                      <td>
                        {new Date(
                          complaintDetail?.createdAt
                        ).toLocaleDateString("en-us", {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                    </tr>
                    {complaintDetail?.closedBy ? (
                      <tr>
                        <th scope="row">Closed By:</th>
                        <td>{complaintDetail.closedBy}</td>
                      </tr>
                    ) : null}
                    {complaintDetail?.discardBy ? (
                      <tr>
                        <th scope="row">Discard By:</th>
                        <td>{complaintDetail.discardBy}</td>
                      </tr>
                    ) : null}
                    {complaintDetail?.note ? (
                      <tr>
                        <th scope="row">Note:</th>
                        <td>{complaintDetail.note}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <th scope="row">Operator:</th>
                      <td>{`${complaintDetail?.operator?.firstname || ""} ${
                        complaintDetail?.operator?.lastname || ""
                      }`}</td>
                    </tr>
                    <tr>
                      <th scope="row">Technician:</th>
                      <td>
                        <span>
                          {editType === "tech" ? (
                            <div className="row">
                              <div className="mb-4 col-lg-8">
                                <Select
                                  className="basic-single"
                                  placeholder="Select User"
                                  classNamePrefix="select"
                                  // isLoading={isLoading}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  value={tech}
                                  options={erpTechList}
                                  onChange={(e) => setTech(e || null)}
                                />
                              </div>
                              <div className="col-lg-4">
                                {isComplaintLoading ? (
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>{" "}
                                    &nbsp; Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      updateComplaintDetails();
                                    }}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : (
                            <>
                              {complaintDetail?.state < 8 ? (
                                <a href="#">
                                  <i
                                    style={{
                                      fontSize: "18px",
                                      marginRight: "8px",
                                      color: "lightblue",
                                      cursor: "pointer",
                                    }}
                                    className="fa fa-light fa-pencil"
                                    onClick={() => {
                                      setEditType("tech");
                                      setSoc("");
                                      setTech("");
                                      setProject("");
                                    }}
                                  ></i>
                                </a>
                              ) : null}
                              {`${
                                complaintDetail.tech?.firstname ||
                                complaintDetail?.erpUser?.firstname ||
                                ""
                              } ${
                                complaintDetail.tech?.lastname ||
                                complaintDetail.erpUser?.lastname ||
                                ""
                              }`}
                            </>
                          )}
                        </span>
                      </td>
                    </tr>
                    {complaintDetail?.storagefiles?.length ? (
                      <tr>
                        <th scope="row">Attachments:</th>
                        <td>
                          {/* {complaintDetail?.storagefiles?.length > 0 ? ( */}
                          <>
                            {complaintDetail?.storagefiles[0] ? (
                              <a
                                href="#"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                data-bs-toggle="modal"
                                data-bs-target={"#showImage"}
                                title="Image ..."
                                onClick={() => {
                                  dispatch(
                                    setImageDetail(
                                      `${URL}upload/${complaintDetail?.storagefiles[0]?.id}`
                                    )
                                  );
                                }}
                              >
                                Attachment 01
                              </a>
                            ) : null}
                            {complaintDetail?.storagefiles[1] ? (
                              <a
                                href="#"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                data-bs-toggle="modal"
                                data-bs-target={"#showImage"}
                                title="Image ..."
                                onClick={() => {
                                  dispatch(
                                    setImageDetail(
                                      `${URL}upload/${complaintDetail?.storagefiles[1]?.id}`
                                    )
                                  );
                                }}
                              >
                                , Attachment 02
                              </a>
                            ) : null}
                          </>

                          {/* ) : null} */}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              {complaintDetail.state > 1 && complaintDetail.state < 8 ? (
                user?.roleId === 1 || user?.accessControl?.closeComplaint ? (
                  <>
                    <div className="col-lg-4">
                      <MyTextInput
                        // name={"Note*"}
                        val={note}
                        placeholder={"Note /Remarks *"}
                        setVal={setNote}
                      />
                    </div>
                  </>
                ) : null
              ) : null}
              <div className="col-lg-8">
                {complaintDetail.state < 8 ? (
                  <>
                    <div className="d-grid gap-2 d-md-flex">
                      {complaintDetail.state > 1 &&
                      (user?.roleId === 1 ||
                        user?.accessControl?.closeComplaint) ? (
                        <>
                          {isCloseLoading ? (
                            <button
                              className="btn btn-warning"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              &nbsp; Loading...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-warning "
                              onClick={(e) => {
                                e.preventDefault();
                                closeComplaint();
                              }}
                            >
                              Close
                            </button>
                          )}

                          {isCloseLoading ? (
                            <button
                              className="btn btn-danger"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>{" "}
                              &nbsp; Loading...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-danger "
                              onClick={(e) => {
                                e.preventDefault();
                                discardComplaint();
                              }}
                            >
                              Abandon
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {!complaintDetail?.deleted &&
                          complaintDetail?.state === 1 ? (
                            <>
                              {isAssignLoading ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>{" "}
                                  &nbsp; Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-success "
                                  onClick={(e) => {
                                    e.preventDefault();
                                    accComplaint();
                                  }}
                                >
                                  Accept
                                </button>
                              )}
                              {isCloseLoading ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>{" "}
                                  &nbsp; Loading...
                                </button>
                              ) : (
                                <>
                                  {user?.roleId === 1 ||
                                  user?.accessControl?.closeComplaint ? (
                                    <button
                                      type="submit"
                                      className="btn btn-danger "
                                      onClick={(e) => {
                                        e.preventDefault();
                                        closeComplaint();
                                      }}
                                    >
                                      Close
                                    </button>
                                  ) : null}
                                </>
                              )}
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <br />
            {!complaintDetail?.deleted ? (
              <div className="row">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  {complaintDetail?.state < 8 ? (
                    <>
                      <button
                        className="btn btn-outline-secondary me-md-2"
                        type="button"
                        onClick={() => {
                          updateComplaintState(2);
                        }}
                      >
                        Assigned
                      </button>
                      <button
                        className="btn btn-outline-secondary me-md-2"
                        type="button"
                        onClick={() => {
                          updateComplaintState(3);
                        }}
                      >
                        In Process
                      </button>
                      <button
                        className="btn btn-outline-secondary me-md-2"
                        type="button"
                        onClick={() => {
                          updateComplaintState(7);
                        }}
                      >
                        On Hold
                      </button>
                      <button
                        className="btn btn-outline-secondary me-md-2"
                        type="button"
                        onClick={() => {
                          updateComplaintState(5);
                        }}
                      >
                        Waiting for feedback
                      </button>
                    </>
                  ) : null}
                  {complaintDetail.isErp ? (
                    <>
                      <button
                        className="btn btn-outline-danger me-md-2"
                        type="button"
                        onClick={() => {
                          delComplaint();
                        }}
                      >
                        Delete
                      </button>
                    </>
                  ) : null}
                  {/* 
                <button className="btn btn-outline-secondary" type="button">
                  Button
                </button> */}
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </>
  );
};

export default ComplaintDetailsMain;
