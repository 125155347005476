import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  complaintChatMsgs: [],
  complaintChatSingle: [],
  chatType: "complaint",
  complaintChatBadges: [],
  openedChatbox: { id: null, name: null },
  openedChatboxType: null,
  chatbox: null,
  newBadges: [],
  chatBoxVisible: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setComplaintChatMsgs: (state, action) => {
      state.complaintChatMsgs = action.payload;
    },

    setComplaintChatBadges: (state, action) => {
      state.complaintChatBadges = action.payload;
    },
    setChatType: (state, action) => {
      state.chatType = action.payload;
    },
    setOpenedChatbox: (state, action) => {
      state.openedChatbox = action.payload;
    },
    setOpenedChatboxType: (state, action) => {
      state.openedChatboxType = action.payload;
    },
    setChatbox: (state, action) => {
      state.chatbox = action.payload;
    },
    setNewBadges: (state, action) => {
      state.newBadges = action.payload;
    },
    setChatboxVisible: (state, action) => {
      state.chatBoxVisible = action.payload;
    },
    setComplaintChatSingle: (state, action) => {
      state.complaintChatSingle = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setComplaintChatBadges,
  setComplaintChatMsgs,
  setChatType,
  setChatbox,
  setNewBadges,
  setOpenedChatbox,
  setOpenedChatboxType,
  setChatboxVisible,
  setComplaintChatSingle,
} = chatSlice.actions;

export default chatSlice.reducer;
