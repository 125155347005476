/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";
import {
  setImageDetail,
  setUserDetails,
} from "../../../../../redux/slices/userSlice";
import { URL } from "../../../../../config";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

var arr = [];

const UserDetails = (props) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.user.userDetails);

  return (
    <>
      <Modal title={"User Details"} name={"userDetails"} icon={"fa fa-person"}>
        <div className="">
          <div className="row">
            <div className="col-4">
              <a
                href="#"
                className="nav-link"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setUserDetails(null));
                }}
              >
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-light fa-arrow-left"
                  title="Go To Users List"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target={"#listUser"}
                ></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div
              className="col-12"
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              User Details:
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <ul className="list-group">
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>FullName: </div>
                  <div>
                    {" "}
                    {`${userDetails?.firstname} ${userDetails?.lastname}`}{" "}
                  </div>
                </li>
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Cell Phone #: </div>
                  <div> {userDetails?.contact}</div>
                </li>
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>CNIC: </div>
                  <div> {userDetails?.cnic}</div>
                </li>
                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Email: </div>
                  <div> {userDetails?.email}</div>
                </li>

                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Type: </div>
                  <div>
                    {" "}
                    {userDetails?.resType == 1
                      ? "Electrician"
                      : userDetails?.resType == 2
                      ? "Civil"
                      : userDetails?.resType == 3
                      ? "Sanitary"
                      : userDetails?.resType == 4
                      ? "Plumber"
                      : userDetails?.resType == 5
                      ? "Misc"
                      : "Not Defined"}
                  </div>
                </li>

                <li
                  style={{ height: "40px" }}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div style={{ fontWeight: "bold" }}>Address: </div>
                  <div>
                    {" "}
                    {`${userDetails?.house ?? ""} ${
                      userDetails?.street ?? ""
                    } ${userDetails?.block ?? ""} ${
                      userDetails?.sector ?? ""
                    } ${userDetails?.phase ?? ""} ${userDetails?.town ?? ""} ${
                      userDetails?.accessRoad ?? ""
                    } ${userDetails?.city ?? ""}`}{" "}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserDetails;
