import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  staticList: [],
  update: false,
  clean: false,
  efig: null,
  tempEfig: null,
  assignEfigSerial: null,
  editEfig: null,
  efigLoading: false,
  alarmHistory: [],
  logHistory: [],
  paymentList: [],
  energizerListForPayment: [],
};

export const efigSlice = createSlice({
  name: "efig",
  initialState,
  reducers: {
    setEfigList: (state, action) => {
      state.list = action.payload;
    },
    setEditEfig: (state, action) => {
      state.editEfig = action.payload;
    },
    setStaticList: (state, action) => {
      state.staticList = action.payload;
    },
    setUpdate: (state, action) => {
      state.update = action.payload;
    },
    setClean: (state, action) => {
      state.clean = action.payload;
    },
    setEfig: (state, action) => {
      state.efig = action.payload;
    },
    setTempEfig: (state, action) => {
      state.tempEfig = action.payload;
    },
    setAssignEfigSerial: (state, action) => {
      state.assignEfigSerial = action.payload;
    },
    setEfigLoading: (state, action) => {
      state.efigLoading = action.payload;
    },
    setAlarmHistory: (state, action) => {
      state.alarmHistory = action.payload;
    },
    setLogHistory: (state, action) => {
      state.logHistory = action.payload;
    },
    setPaymentList: (state, action) => {
      state.paymentList = action.payload;
    },
    setEnergizerListForPayment: (state, action) => {
      state.energizerListForPayment = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setEfigList,
  setEfig,
  setTempEfig,
  setEfigLoading,
  setAlarmHistory,
  setLogHistory,
  setAssignEfigSerial,
  setPaymentList,
  setEnergizerListForPayment,
  setUpdate,
  setClean,
  setStaticList,
  setEditEfig,
} = efigSlice.actions;

export default efigSlice.reducer;
