import React from "react";

const Terms = (props) => {
  return (
    <div>
      <title>TERMS &amp; CONDITIONS</title>
      {/* Fonts */}
      <div style={{ margin: "60px" }}>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "21px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              TERMS &amp; CONDITIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            THESE TERMS &amp; CONDITIONS (“Agreement” or “Terms”) GOVERN YOUR
            ACQUISITION AND USE OF EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;SERVICES. IF YOU REGISTER FOR A FREE TRIAL FOR EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;SERVICES, THIS AGREEMENT WILL ALSO GOVERN THAT FREE TRIAL. BY
            ACCEPTING THIS AGREEMENT, EITHER BY CHECKING A BOX INDICATING YOUR
            ACCEPTANCE OR BY EXECUTING AN ORDER FORM THAT REFERENCES THIS
            AGREEMENT, YOU AGREE TO THE TERMS OF THIS AGREEMENT AND THESE TERMS
            WILL THEN APPLY TO YOU FROM THE TIME THAT YOU FIRST ACCESS THE
            SERVICES (INCLUDING WHERE YOU HAVE SUBSCRIBED VIA AN EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;PARTNER). IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF
            A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE
            AUTHORITY TO BIND SUCH AN ENTITY AND ITS AFFILIATES TO THESE TERMS,
            IN WHICH CASE THE TERMS “YOU” OR “YOUR” SHALL REFER TO SUCH ENTITY
            AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO
            NOT AGREE WITH THESE TERMS, YOU MUST NOT ACCEPT THIS AGREEMENT AND
            MAY NOT USE THE SERVICES.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            We at Unisource Solutions (Pvt) Ltd (hereinafter referred as
            Unisource) reserves the right to amend these Terms at any time,
            effective upon the posting of such modified Terms on the Website.
            Unisource Solutions (Pvt) Ltd will make every effort to communicate
            these changes to You via the Website and also via in-app
            notification. It is likely that the Terms will change over time. It
            is Your obligation to ensure that You have read and understood the
            most recent Terms available on the Website. These Terms were last
            updated in&nbsp;
          </span>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            February 2022
          </span>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            . They replace any prior agreement(s) between You and Unisource with
            regards to EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            . When we change these Terms, the “last updated” date above will be
            updated to reflect the date of the most recent version.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              1. DEFINITIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Agreement” or “Terms”
            <br />
            &nbsp;means these EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;Terms and Conditions and includes any notices, policies,
            guidelines or conditions sent to You by Unisource through EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            App or posted on the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Access Fee”
            <br />
            &nbsp;means the monthly or annual fee (excluding any taxes and
            duties) and any applicable one-time-service fees payable by You in
            accordance with the fee schedule set out on the Website and as
            agreed to in Your Order Form for the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Affiliates”
            <br />
            &nbsp;means any entity that directly or indirectly controls, is
            controlled by, or is under common control with, You (for example,
            one of Your subsidiaries).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Billing Contact”
            <br />
            &nbsp;means Your nominated contact entity and address for billing
            purposes.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Confidential Information”
            <br />
            &nbsp;means any information which the disclosing party identifies as
            confidential or which ought reasonably to be considered confidential
            because of its nature and the manner of its disclosure, including
            Subscriber Data and information about the disclosing party’s
            business plans, technical data, and the terms of Your Order but
            excluding information which is, or becomes, publicly available or
            that is already known by, or rightfully received by, the other party
            other than as a result of a breach of an obligation of
            confidentiality.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Data”
            <br />
            &nbsp;means any photos, images, videos, graphics, written content,
            audio files, location information, code, information or data
            inputted or uploaded by You (including by an Invited User and, where
            You have subscribed to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services via a Unisource Partner, by that Unisource Partner on Your
            behalf) into the Website processed or made available to You or
            others as a direct result of Your use of the Services and the
            Website (e.g., Subscriber specific reports).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Intellectual Property Right”
            <br />
            &nbsp;means any patent, trade mark, service mark, copyright, moral
            right, right in a design, know-how and any other intellectual or
            industrial property rights, anywhere in the world whether or not
            registered.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Invited User”
            <br />
            &nbsp;means a unique individual authorised by You to use the
            Services for Your benefit in accordance with this Agreement,
            including Your employees, representatives, contractors and agents
            and the employees, representatives, contractors and agents of Your
            Affiliates (if any).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Loss”
            <br />
            &nbsp;means all liabilities, expenses, losses, damages and costs
            (including legal costs) and expenses, however arising. “Maximum
            Total Invited Users” means the maximum number of Invited Users as
            specified on Your Order Form or as subsequently varied from time to
            time by agreement with the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;support team.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Order Form”
            <br />
            &nbsp;means the subscription check out page where the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;Services, subscription plan, Maximum Total Invited Users,
            Renewal Period and other Usage Limitations and service features are
            selected (as subsequently varied from time to time by agreement
            between You and Unisource) and by which You agree or, where You have
            subscribed to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;Services via an Unisource Partner, the Unisource Partner
            agrees on Your behalf, to subscribe to the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Renewal Date”
            <br />
            &nbsp;means the date (monthly or annual) on which Your subscription
            renews.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Renewal Period”
            <br /> means the period for which You agree to subscribe to the
            Services and (subject to
            <span style={{ background: "yellow" }}>&nbsp;</span>clause 3.1(ii))
            for which You agree to prepay the Access Fees (either monthly or
            annually) as specified in Your Order Form (and which may differ for
            each EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;Services).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Services”
            <br />
            &nbsp;means the online inventory management and other EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;platforms/products that You have subscribed to by an Order
            Form or, where You have subscribed to the Services via an Unisource
            Partner, the Unisource Partner has subscribed to on Your behalf, or
            that Unisource otherwise makes available to You via the Website (as
            may be varied from time to time by Unisource in accordance with
            clause 2.2).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Subscriber”, “You” and “Your”
            <br />
            &nbsp;means the person or the company or other legal entity who is
            identified in the Order Form as the Subscriber. This can also be
            known as the “Account Owner”.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Subscription Term”
            <br />
            &nbsp;means the initial period (monthly or annual) for which you
            agree to subscribe to the Services and for which you agree to prepay
            the Access Fees as specified in Your Order Form, together with each
            subsequent Renewal Period (unless terminated earlier in accordance
            with the terms of this Agreement).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Unisource”
            <br />
            &nbsp;means the applicable Unisource entity specified in clause 10.5
            based on the billing address specified in Your Order Form and, in
            each case, their legal successors.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Unisource Partner”
            <br />
            &nbsp;means any authorised third party channel partner through whom
            you obtain a subscription to the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Usage Limitations”
            <br />
            &nbsp;means those limitations as specified on Your Order Form or as
            subsequently notified in accordance with clause 3.7, and includes
            any limitations that Unisource imposes in relation to future EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services modules or features.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Website”
            <br />
            &nbsp;means the Internet site at the domain&nbsp;
          </span>
          <a href="https://efig.unisourceinternational.com">
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              https://efig.unisourceinternational.com
            </span>
          </a>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            , and mobile apps operated by EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            “Your Organisations”
            <br />
            &nbsp;means, an organisation that You have added as a subscriber to
            the Services or that has been added with Your authority or as a
            result of Your use of the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Any reference to “includes” or “including” is on a without
            limitation basis.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              2. END USER LICENSE AGREEMENT (USE OF SOFTWARE)
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            2.1 Rights to access and use the Services:
            <br />
            &nbsp;Unisource grants You the right to access and use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services (including to authorise Invited Users to access and use the
            EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services) via the Website in accordance with Your subscription type,
            as specified in Your Order Form (subject to any Usage Limitations).
            This right is non-exclusive and limited by, and subject to, the
            terms of this Agreement. You acknowledge and agree that, subject to
            any applicable laws:
            <br />
            &nbsp;a. the Subscriber determines who is an Invited User and what
            level of access each Invited User has to the Services, Website and
            Data (up to the applicable Maximum Total Invited Users);
            <br />
            &nbsp;b. the Subscriber is responsible for all Invited Users’ use of
            the Services and the Website (whether authorised by the Subscriber
            or not);
            <br />
            &nbsp;c. the Subscriber controls each Invited User’s level of access
            to the Services, Website and Data at all times and can revoke or
            change an Invited User’s access, or level of access, at any time and
            for any reason, in which case that person or entity will cease to be
            an Invited User or shall have that different level of access, as the
            case may be;
            <br />
            &nbsp;d. Invited Users’ user names and passwords may not be shared
            or used by more than one individual and the Subscriber may only
            re-assign access rights to a new individual user where an existing
            Invited User no longer requires the rights to access and use the
            Services and Website; and
            <br />
            &nbsp;e. if there is any dispute between a Subscriber and an Invited
            User regarding access to any Services, Website or Data, the
            Subscriber shall decide what access or level of access to the
            relevant Services, Website or Data that Invited User shall have, if
            any.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            2.2 Our right to make changes to the Services:
            <br />
            &nbsp;Unisource &nbsp;may modify the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services from time to time, including by adding or deleting features
            and functions, as we continually improve our Services and Website
            and the experience we offer You. However Unisource will not make
            changes to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services that materially reduce the functionality provided to You
            during the prepaid period of Your subscription. However, You
            acknowledge that Unisource does not control changes made to their
            service terms by third party service provider (e.g., emergency
            response, backup &amp; rescue services) to their customers and that
            these types of changes may impact the functionality of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              3. YOUR OBLIGATIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.1. Payment obligations:
            <br />
            &nbsp;a. An invoice for the Access Fee will be issued:
            <br />
            &nbsp;i. Six monthly or annually in advance (depending on Your
            subscription type) at the beginning of Your Subscription Term and
            subsequently on each Renewal Date; or
            <br />
            &nbsp;ii. where we offer, and You select, an annual subscription
            with monthly billing, monthly in advance during Your Subscription
            Term.
            <br />
            &nbsp;b. All invoices will include the Access Fee for the
            corresponding period of use (including any one-time-service fees
            that shall be payable in lump sums). Unisource will continue
            invoicing You monthly or annually in advance (as the case may be)
            until this Agreement is terminated in accordance with clause 8.
            <br />
            &nbsp;c. All Unisource invoices for EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;Services will be sent to You, or to a Billing Contact whose
            details are provided by You, by email. You must pay or arrange
            payment of all amounts specified in any invoice within five days of
            the invoice date. You are responsible for payment of all applicable
            taxes and duties in addition to the Access Fees. If You are required
            to deduct or withhold any tax, You must pay the amount deducted or
            withheld as required by law and pay us an additional amount so that
            we receive payment in full as if there were no deduction or
            withholding.
            <br />
            &nbsp;d. If any invoiced amount remains unpaid after the payment due
            date then Unisource may charge You late payment interest calculated
            at a daily rate of 1%.
            <br />
            &nbsp;e. If Unisource has to spend money collecting overdue amounts
            from You then You will reimburse Unisource for those costs.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.2 Variations to Access Fees:
            <br />
            &nbsp;Subject to clause 3.7, the Access Fee will remain fixed during
            the initial period of Your Subscription Term or the relevant Renewal
            Period (as applicable). Unisource reserves the right to increase the
            Access Fees for the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services on 30 days’ written notice to You with any such increase to
            then take effect on the first Renewal Date following the end date of
            such notice period.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.3 Subscribers ordering via an Unisource Partner:
            <br />
            &nbsp;If You have subscribed to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services via an Unisource Partner, Your Unisource Partner will issue
            the invoices for the Access Fees to You, and You must pay the
            invoiced amounts to the Partner in accordance with clause 3.1.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.4. Preferential pricing or discounts:
            <br />
            &nbsp;You may from time to time be offered preferential pricing or
            discounts for the Access Fees as a result of the number of Your
            customers. Eligibility for such preferential pricing or discounts is
            conditional upon Your acceptance of responsibility for payment of
            Access Fees in relation to all of Your customers. Without prejudice
            to any other rights that Unisource may have under these Terms or at
            law, Unisource reserves the right to render invoices for the full
            (non-discounted) Access Fees due or suspend or terminate Your use of
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services in respect of any or all of Your customers in the event
            that any invoices for those Access Fees are not paid in full by You
            to Unisorce by the due date for payment.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.5. General obligations:
            <br />
            &nbsp;You are solely responsible for your use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and Website and for Your Data. You must only use the
            Services and Website for Your own lawful internal business purposes,
            in accordance with these Terms and any notices, policies, guidelines
            or conditions sent to You by Unisource or posted on the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.6. Access conditions:
            <br />
            &nbsp;a. You must ensure that all usernames and passwords (including
            any passwords allocated to Invited Users) required to access the
            Services and Website are kept secure and confidential. You must
            immediately notify Unisource of any unauthorised use of Your
            passwords or any other breach of security and Unisource will reset
            Your password(s) and You must take all other actions that Unisource
            reasonably deems necessary to maintain or enhance the security of
            EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            ’s services and networks (including the Website) and Your access to
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services.
            <br />
            &nbsp;b. When accessing and using the Services You must not:
            <br />
            &nbsp;i. attempt to undermine the security or integrity of EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            ’s computing systems or networks (including the Website) or, where
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services are hosted by a third party, that third party’s computing
            systems and networks;
            <br />
            &nbsp;ii. use, or misuse, the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;Services in any way which may impair the functionality of the
            Services, Website or other systems used to deliver the Services or
            impair the ability of any other user to use the Services or Website,
            including by misusing the Services in a manner that materially
            exceeds reasonable usage or use patterns over any month or by using
            the Services in a malicious, fraudulent or unlawful manner;
            <br />
            &nbsp;iii. attempt to gain unauthorised access to any materials
            other than those to which You have been given express permission to
            access or to the computer system on which the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services are hosted;
            <br />
            &nbsp;iv. transmit, input or upload to the Website, any files that
            may damage any other person’s computing devices or software, content
            that may be offensive, or material or Data in violation of any law
            (including Data or other material protected by copyright or trade
            secrets which You do not have the right to use); or
            <br />
            &nbsp;v. attempt to modify, copy, adapt, reproduce, disassemble,
            decompile or reverse engineer any computer programs used to deliver
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services or to operate the Website except as is strictly necessary
            to use either of them for normal operation.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.7. Usage Limitations:
            <br />
            &nbsp;a. In addition to the Maximum Total Named Users, Your use of
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services may be subject to Usage Limitations, including monthly
            transactions, You are permitted to make against EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            ’s application programming interface (“API”). Any Usage Limitations
            will remain in effect for the duration of the initial period of Your
            Subscription Term or the relevant Renewal Period (as applicable).
            Unisource reserves the right to vary the Usage Limitations on 30
            days’ written notice to You with any such variation to then take
            effect on the first Renewal Date following the end date of such
            notice period (unless Unisource chooses to relax certain Usage
            Limitations, in which case such variation will take effect
            immediately).
            <br />
            &nbsp;b. You may not use or access the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services in a manner that exceeds these Usage Limitations. If You
            exceed Your Usage Limitations then then You must either disable or
            correct such use, or
            <br />
            &nbsp;Your Subscription will be upgraded to the subscription tier
            which corresponds with Your actual use.
            <br />
            &nbsp;c. Where Your Subscription is upgraded in accordance with
            clause 3.7(b), &nbsp;Unisource will invoice You for the Access Fee
            that corresponds to Your new Subscription tier and you agree that
            the new Access Fee will apply from the date of such upgrade.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.8 Usage reviews:
            <br />
            &nbsp;You agree that Unisource may review Your use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services at any time during the Subscription Term, and You will
            provide any reasonable assistance to verify Your compliance with
            this Agreement as we may request. Unisource may suspend Your rights
            to access and use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services (including Your rights to access any of Your Data)
            immediately upon written notice if we determine that Your use of the
            Services (including any of Your Data) breaches this Agreement
            (without limiting any other rights or remedies Unisource may have).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            3.9. Communication Conditions:
            <br />
            &nbsp;a. As a condition of these Terms, if You use any communication
            tools available through the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services (such as any text chat, exchanging pictures and audio
            files), You agree only to use such communication tools for lawful
            and legitimate purposes. You must not use any such communication
            tool for posting or disseminating any material unrelated to the use
            of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services, including: unsolicited commercial e-mail, files that may
            damage any other person’s computing devices or software, content
            that may be offensive to any other users of the Services or the
            Website, or material in violation of any law (including material
            that is protected by copyright or trade secrets which You do not
            have the right to use).
            <br />
            &nbsp;b. When You generate any communication through EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            , You represent that You are permitted to generate such
            communication. Unisource is under no obligation to ensure that the
            communications on the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services are legitimate or that they are related only to the use of
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services. As with any other web-based forum, You must exercise
            caution when using the communication tools available on the Website.
            However, Unisource does reserve the right to remove any
            communication at any time in its sole discretion.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              4. CONFIDENTIALITY AND PRIVACY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            4.1. Confidentiality:
            <br />
            &nbsp;Unless the relevant party has the prior written consent of the
            other or unless required to do so by law:
            <br />
            &nbsp;a. Each party will preserve the confidentiality of all
            Confidential Information of the other obtained in connection with
            these Terms. Neither party will, without the prior written consent
            of the other, disclose or make any Confidential Information
            available to any person, or use the same for its own benefit, other
            than as expressly contemplated by these Terms or, where Unisource is
            the recipient, in order to provide the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services to You.
            <br />
            &nbsp;b. Each party’s obligations under this clause will survive
            termination of these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            4.2. Privacy:
            <br />
            &nbsp;a. Unisource maintains a privacy policy that sets out what
            information Unisource collects about individuals and why, what
            Unisource does with that information, and how Unisource
            &nbsp;handles that information. You should read that policy at&nbsp;
          </span>
          <a href="https://ears.pk/privacy-policy/">
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "white",
                border: "none windowtext 1.0pt",
                padding: "0in",
                textDecoration: "none",
              }}
            >
              https://EFIG.pk/privacy-policy/
            </span>
          </a>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;(“Privacy Policy”). In the event of any conflict between these
            Terms and the terms of the Unisource Privacy Policy, the terms of
            our Privacy Policy will take precedence.
            <br />
            &nbsp;b. If You are subject to the territorial scope of the
            Regulation (EU) 2016/679 (GDPR), You agree that You are a “data
            controller” and that Unisource is a “data processor” (as those terms
            are defined in the GDPR) and that the terms of the Data Processor
            Agreement form part of this Agreement.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              5. INTELLECTUAL PROPERTY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            5.1. General:
            <br />
            &nbsp;Title to, and all Intellectual Property Rights in the
            Services, the Website and any documentation relating to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services remain the property of Unisource (or its licensors).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            5.2. Ownership of Data:
            <br />
            &nbsp;Title to, and all Intellectual Property Rights in, the Data
            remain Your property. However, Your access to the Data is contingent
            on full payment of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services Access Fees when due and any re-establishment fee due and
            payable under&nbsp;clause 5.6.&nbsp;You grant Unisource a licence to
            use, copy, transmit, store, and back-up Your Data for the purposes
            of enabling You to access and use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and for any other purpose related to provision of EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services to You. For the avoidance of doubt, this licence is without
            limitation to Unisource’s right to create anonymised data
            compilations or similar works based on Your Data.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            5.3. Backup of Data:
            <br />
            &nbsp;You must maintain copies of all Data inputted or uploaded into
            the Website. Unisource adheres to its best practice policies and
            procedures to prevent data loss, including a daily system data
            back-up regime, but does not make any guarantees that there will be
            no loss of Data. Unisource expressly excludes liability for any loss
            of Data (including Data archived pursuant to clause 5.6) no matter
            how caused.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            5.4. Third-party apps and Your Data:
            <br />
            &nbsp;If You enable third-party apps for use in conjunction with the
            Services, You acknowledge that Unisource &nbsp;may allow the
            providers of those third-party apps to access Your Data as required
            for the interoperation of such third-party apps with the Services.
            Unisource shall not be responsible for any disclosure, modification
            or deletion of Your Data resulting from any such access by
            third-party application providers. You also acknowledge that those
            third party app providers are independent of EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and those apps will be subject to terms and conditions and
            privacy notices set by their providers.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            5.5. Accuracy of Data:
            <br />
            &nbsp;You agree and acknowledge that You are responsible for
            ensuring the accuracy of Data inputted into the mobile app and
            Website by You or by any person on Your behalf (including an
            Unisource Partner). Unisource is under no obligation to ensure that
            Your Data in the Website is an accurate representation of Your
            actual business data.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            5.6 Deletion and recovery of Data:
            <br />
            &nbsp;a. Where:
            <br />
            &nbsp;i. You choose to discontinue Your Services in accordance with
            clause 8.1;
            <br />
            &nbsp;ii. either party terminates this Agreement in accordance with
            clause 8.3;
            <br />
            &nbsp;iii. Unisource suspends or terminates this Agreement in
            accordance with clause 8.5; or
            <br />
            &nbsp;iv. Unisource otherwise suspends and/or terminates Your right
            to access to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and/or the Website in accordance with this Agreement,
            <br />
            &nbsp;it is Your responsibility to extract Your Data from the
            Website prior to the effective date of such suspension or
            termination. Following the effective date of suspension or
            termination, Unisource reserves the right, in Unisource’s sole
            discretion, to either (i) delete Your Data or (ii) archive Your Data
            for a period of time of up to six months (upon the expiry of which
            time Unisource may delete Your Data).
            <br />
            &nbsp;b. If Unisource has chosen to archive Your Data and if Your
            Services are subsequently re-established within the archive period
            then Unisource will restore Your access to the archived Data
            provided that You pay Unisource in advance:
            <br />
            &nbsp;i. all arrears of Your unpaid Access Fee over the period that
            the Data was archived; and
            <br />
            &nbsp;ii. a re-establishment fee of PKR150,000 or such other amount
            as is determined by Unisource in Unisource’s sole discretion and
            notified to You).
            <br />
            &nbsp;c. Where your right to access and use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and Website has been terminated and Unisource has chosen to
            archive Your Data, if You omitted to extract Your Data prior to the
            effective date of such termination and You subsequently notify us
            that You require access to Your Data then Unisource will temporarily
            restore Your access to the archived Data provided that You
            re-subscribe to the Services for a month and that You pay Unisource
            the associated Access Fee together with a one-off fee of such amount
            as is determined by Unisource in Unisource’s sole discretion and
            notified to You.
            <br />
            &nbsp;
            <br />
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              6. WARRANTIES AND ACKNOWLEDGEMENTS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            6.1. Data:
            <br />
            &nbsp;a. You represent and warrant that:
            <br />
            &nbsp;i. You have obtained all necessary consents and are otherwise
            authorised to input or upload the Data that You input or upload to
            the Website, including any Data inputted or uploaded to the Website
            by any Invited User or by a Unisource Partner on Your behalf; and
            <br />
            &nbsp;ii. You are similarly authorised to access the processed Data
            that is made available to You through Your use of the Website and
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services, in each case, whether the inputted or uploaded Data is
            Your own or that of anyone else.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            6.2 Rights of third parties / access to Data:
            <br />
            &nbsp;a. You acknowledge and agree that Unisource has no
            responsibility to any person other than You and nothing in this
            Agreement confers, or purports to confer, a benefit on any person
            that is enforceable against us by any person (including Your
            Affiliates) other than You.
            <br />
            &nbsp;b. If You use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services or access the Website on behalf of or for the benefit of
            anyone other than Yourself (whether a body corporate or otherwise)
            You agree that:
            <br />
            &nbsp;i. You are responsible for ensuring that You have the right to
            do so;
            <br />
            &nbsp;ii. In the case of a third person requiring access to Your
            Data (i.e., other than an Invited User), You are responsible for
            authorising any such person to access Your Data, and You agree that
            Unisource has no obligation to provide any such person with access
            to Your Data without Your prior written authorisation (and may refer
            any third party access requests for information to You to address);
            and
            <br />
            &nbsp;iii. You will indemnify Unisource against any Loss arising out
            of or in connection with EFIG’s refusal to provide such third
            persons with access to Your Data and/or Unisource enabling access by
            such third persons to Your Data where this has been done with Your
            written authorisation.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            6.3 Acknowledgements and disclaimers:
            <br />
            &nbsp;You acknowledge and agree that Unisource gives no warranty
            about the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and Website and, without limiting the foregoing:
            <br />
            &nbsp;a. The provision of, access to, and use of, the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and the Website is on an “as is” basis and at Your own
            risk.
            <br />
            &nbsp;b. Unisource does not warrant that the use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services or the Website will be uninterrupted or error free. Among
            other things, the operation and availability of the systems used for
            accessing the Service and the Website, including public telephone
            services, computer networks and the Internet, can be unpredictable
            and may from time to time interfere with or prevent access to the
            EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and the Website. Unisource is not in any way responsible
            for any such interference or prevention of Your access or use of the
            EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services.
            <br />
            &nbsp;c. Unisource does not warrant that the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services or the Website will meet Your requirements or that the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services or the Website will be suitable for any particular purpose.
            It is Your sole responsibility to determine that the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and the Website meet the needs of Your business and are
            suitable for the purposes for which they are used.
            <br />
            &nbsp;d. You remain solely responsible for complying with all
            applicable accounting, tax and other laws. It is Your responsibility
            to check that storage of and access to Your Data via the Website
            will comply with laws applicable to You (including any laws
            requiring You to retain records).
            <br />
            &nbsp;e. You are solely responsible for assessing the correct taxes
            you should charge Your customers.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            6.4. No implied warranties:
            <br />
            &nbsp;To avoid doubt, all implied conditions or warranties are
            excluded in so far as is permitted by law, including warranties of
            merchantability and fitness for purpose, title and non-infringement.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            6.5. Consumer protection laws:
            <br />
            &nbsp;You warrant and represent that You are acquiring the right to
            access and use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and Website for the purposes of a business and that, to the
            maximum extent permitted by law, any statutory consumer guarantees
            or legislation intended to protect non-business consumers in any
            jurisdiction does not apply to the supply of the Services, the
            Website or these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              7. INDEMNITY AND LIMITATION OF LIABILITY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            7.1. Indemnity:
            <br />
            &nbsp;You indemnify Unisource against all Losses that EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services incurs arising out of or in connection with:
            <br />
            &nbsp;a. Your breach of any of these Terms;
            <br />
            &nbsp;b. any claims of tax authorities of any country, state,
            province or other jurisdiction in relation to the taxes levied on
            sales to Your customers; and/or
            <br />
            &nbsp;c. any third party claims against Unisource relating to Your
            Data or otherwise to Your use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services or Website or any third party app (or other third party
            product).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            7.2 Unisource is not responsible:
            <br />
            &nbsp;To the maximum extent permitted by law, Unisource will not be
            liable to You (or any other person) in contract, tort (including
            negligence), or otherwise, for any Losses (whether direct or
            indirect) arising out of, or in connection with, Your use of, or
            reliance on, the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services or Website or otherwise arising out of or in connection
            with these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            7.3. If, notwithstanding&nbsp;clause 7.2, Unisource is determined to
            have any liability to You (or any third party) arising out of or in
            connection with these Terms, Unisource’s liability in respect of any
            one incident, or series of connected incidents, will be limited to
            an amount equal to the Access Fees paid by You in the one month
            preceding the first such incident.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              8. TERMINATION AND SUSPENSION
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            8.1. Trial policy:
            <br />
            &nbsp;When You first sign up for access to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services You can evaluate the Services for a period of 30 days or as
            agreed by You and Unisource, with no obligation to continue to use
            the Services. You may elect to, on or before the fourteenth day
            after the initial submission of Your Order Form, discontinue the
            Services and receive a full refund of any Access Fees paid minus any
            one-time-service fees accrued up to the date of discontinuation. If
            You do not elect to discontinue using the Services within this 30
            day timeframe, You will be billed from the day You first added Your
            billing details into Your Order Form. If You choose to discontinue
            using the Services, You must apply in writing to&nbsp;
          </span>
          <u>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "blue",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              support@unisourceinternational.com
            </span>
          </u>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;to be removed from the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            8.2. Modifications by You to Your subscription:
            <br />
            &nbsp;You may only reduce Your subscribed Services to a lower level
            subscription lesser Service by providing Unisource with written
            notice via&nbsp;
          </span>
          <u>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "blue",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              support@unisourceinternational.com
            </span>
          </u>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;at least ten days prior to the next Renewal Date and where You
            have not used the Service or Website features that are not available
            in the lower level subscription. The change to Your subscription
            will then take effect on that Renewal Date. You may change Your
            subscribed EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services by adding additional Services or Invited Users or otherwise
            increasing Your subscription levels at any time.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            8.3. No-fault termination:
            <br />
            &nbsp;The Subscription Term will renew automatically at the end of
            the initial period of Your Subscription Term and then subsequently
            at the end of each Renewal Period and the Access Fee for that month
            or year (as applicable) will continue to be due and payable in
            advance (in accordance with clause 3.1), unless either party
            terminates these Terms by giving notice to the other party at least
            30 days before the end of the initial subscription period or the
            relevant Renewal Period (as applicable). If insufficient notice is
            given then:
            <br />
            &nbsp;a. if Your subscription renews on a monthly basis, Your
            subscription will not be cancelled until the next Renewal Date;
            <br />
            &nbsp;b. if Your subscription renews on an annual basis then,
            provided Your notice is received by Unisource no later than seven
            days following the Renewal Date, Unisource will accept Your notice
            of cancellation and will refund the Access Fees already paid by You
            in relation to the new Renewal Period. If Your notice is received by
            Unisource more than seven days after the Renewal Date then Unisource
            will accept Your notice of cancellation and may then choose (in its
            sole discretion) to refund some or all of the Access Fees already
            paid by You that relate to the unused portion of the new Renewal
            Period. Where we offer and You have selected an annual subscription
            with monthly billing then if your notice is received by Unisource
            during the initial period of Your EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Subscription Term or more than seven days after the Renewal Date
            then Unisource may invoice You for some or all of the Access Fees
            that relate to the unused portion of the initial subscription period
            or the new Renewal Period (as applicable).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            8.4 Overdue:
            <br />
            &nbsp;a. Access Fees are paid in advance, however in the event that
            Your account becomes overdue this must be settled within five days
            otherwise Unisource reserves the right to suspend Your subscription
            and Your rights to access the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services, Website and the Data and to charge a reactivation fee to
            reinstate Your account (equal to the cost of a one month
            subscription). The Billing Contact will be notified once an account
            becomes overdue.
            <br />
            &nbsp;b. If You have subscribed to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services via a Unisource Partner then that Unisource Partner may
            also request that Your account be suspended due to non-payment. In
            the event such a request is made Unisource will endeavour to contact
            You to confirm this via email, if provided, else it will be the sole
            responsibility of the Partner to send a message to You and all
            Invited Users before Your account is suspended.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            8.5. Breach:
            <br />
            &nbsp;If You:
            <br />
            &nbsp;a. breach any of these Terms (including by non-payment of any
            Access Fees) and do not remedy the breach within thirty days after
            receiving notice of the breach if the breach is capable of being
            remedied;
            <br />
            &nbsp;b. breach any of these Terms and the breach is not capable of
            being remedied; or
            <br />
            &nbsp;c. You are declared bankrupt or You become bankrupt,
            insolvent, or You go into liquidation or a receiver or manager is
            appointed over any of Your assets, or You make any arrangement with
            Your creditors, or become subject to any similar insolvency event in
            any jurisdiction, then (without limiting Unisource’s suspension
            rights at clause 8.4 above) EFIG® &nbsp;may take any or all of the
            following actions, at its sole discretion:
            <br />
            &nbsp;i. terminate this Agreement and Your right to access and use
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and the Website;
            <br />
            &nbsp;ii. suspend, for any definite or indefinite period of time,
            Your right to access and use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and the Website;
            <br />
            &nbsp;iii. suspend or terminate Your access to any or all EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Data through the Website; or
            <br />
            &nbsp;iv. delete Your EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Data in accordance with&nbsp;clause 5.6.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            8.6. Accrued Rights:
            <br />
            &nbsp;Termination of these Terms is without prejudice to any rights
            and obligations of the parties accrued up to and including the date
            of termination. On termination of this Agreement You will:
            <br />
            &nbsp;a. remain liable for any accrued charges and amounts which
            become due for payment before or after termination; and
            <br />
            &nbsp;b. immediately cease to use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and the Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            8.7. Expiry or termination:
            <br />
            &nbsp;Those clauses which by their nature survive expiry or
            termination, including clauses 3.1, 4, 5, 6, 7, 8 and 10, will
            survive the expiry or termination of these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            8.8. Refund Policy:
            <br />
            &nbsp;Subject to clause 8.1 Unisource does not provide refunds if
            You decide to stop using the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services and accessing the Website at any time during Your
            Subscription Term.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              9. HELP DESK
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            9.1. Technical Problems:
            <br />
            &nbsp;You have a right to technical support, in accordance
            with&nbsp;
          </span>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            the&nbsp;Customer Success&nbsp;Plan&nbsp;on Your subscription, or in
            the case where&nbsp;You have none, the Essential Customer Success
            Plan
          </span>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            9.2. Service availability:
            <br />
            &nbsp;Whilst Unisource intends that the Services and the Website
            should be available 24 hours a day, seven days a week, it is
            possible that on occasions the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services or Website may be unavailable to permit maintenance or
            other development activity to take place. If for any reason
            Unisource has to interrupt the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "12px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            Services for any period, Unisource will use reasonable endeavours to
            publish in advance details of such activity.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              10. GENERAL
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            10.1. Entire agreement:
            <br />
            &nbsp;These Terms, together with the Unisource Privacy Policy and
            the Data Processing Agreement (where applicable) and the terms of
            any other notices or instructions given to You under these Terms,
            supersede and extinguish all prior agreements, representations
            (whether oral or written), and understandings and constitute the
            entire agreement between You and Unisource relating to the Services
            and Your access and use of the Website and the other matters dealt
            with in these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            10.2. Waiver:
            <br />
            &nbsp;A waiver of any breach of any provision in these Terms shall
            not be effective unless that waiver is in writing and is signed by
            the party against whom that waiver is claimed. If either party
            waives any breach of these Terms, this will not constitute a waiver
            of any other breach. No waiver will be effective unless made in
            writing.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            10.3. Delays:
            <br />
            &nbsp;Neither party will be liable for any delay or failure in
            performance of its obligations under these Terms if the delay or
            failure is due to any cause outside its reasonable control. This
            clause does not apply to any obligation to pay money.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            10.4. No Assignment:
            <br />
            &nbsp;You may not assign or transfer this Agreement or any of Your
            rights under this Agreement to another person without our prior
            written consent. The transfer of the billing ownership may be
            requested by contacting&nbsp;
          </span>
          <u>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "blue",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              support@unisourceinternational.com
            </span>
          </u>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;It is at the discretion of Unisource to action this change
            over.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            10.5. Unisource entity, governing law and jurisdiction:
            <br />
            &nbsp;
            <br />
            &nbsp;
          </span>
        </p>
        <table
          style={{
            width: "449.25pt",
            background: "white",
            border: "solid windowtext 1.0pt",
          }}
        >
          <thead>
            <tr>
              <td
                style={{
                  width: "51.75pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: '"Arial",sans-serif',
                        color: "black",
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Billing Address
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "89.55pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: '"Arial",sans-serif',
                        color: "black",
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      EFIG® &nbsp;Entity
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "104.7pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: '"Arial",sans-serif',
                        color: "black",
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Address for notices
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "79.5pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: '"Arial",sans-serif',
                        color: "black",
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Registration number
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "57pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: '"Arial",sans-serif',
                        color: "black",
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Law
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "56.25pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center",
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: '"Arial",sans-serif',
                        color: "black",
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Venue
                    </span>
                  </strong>
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  width: "51.75pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    verticalAlign: "baseline",
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "16px",
                        fontFamily: '"Arial",sans-serif',
                        color: "black",
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Pakistan
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "89.55pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    verticalAlign: "baseline",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: '"Arial",sans-serif',
                      color: "black",
                      border: "none windowtext 1.0pt",
                      padding: "0in",
                    }}
                  >
                    Unisource Solutions (Pvt) Limited
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "104.7pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    verticalAlign: "baseline",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: '"Arial",sans-serif',
                      color: "black",
                      border: "none windowtext 1.0pt",
                      padding: "0in",
                    }}
                  >
                    1.6 KM Off Defence Road, Industrial Area Kahna Flyover,
                    Defence Road, LAHORE
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "79.5pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center",
                    verticalAlign: "baseline",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: '"Arial",sans-serif',
                      color: "black",
                      border: "none windowtext 1.0pt",
                      padding: "0in",
                    }}
                  >
                    0079421
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "57pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center",
                    verticalAlign: "baseline",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: '"Arial",sans-serif',
                      color: "black",
                      border: "none windowtext 1.0pt",
                      padding: "0in",
                    }}
                  >
                    Pakistan
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "56.25pt",
                  border: "1pt solid windowtext",
                  padding: "0.75pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                    textAlign: "center",
                    verticalAlign: "baseline",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontFamily: '"Arial",sans-serif',
                      color: "black",
                      border: "none windowtext 1.0pt",
                      padding: "0in",
                    }}
                  >
                    Pakistan
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            10.6. Severability:
            <br />
            &nbsp;If any part or provision of these Terms is invalid,
            unenforceable or in conflict with the law, that part or provision is
            replaced with a provision which, as far as possible, accomplishes
            the original purpose of that part or provision. The remainder of
            this Agreement will be binding on the parties.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            10.7. Notices:
            <br />
            &nbsp;Any notice given under these Terms by either party to the
            other must be in writing by email and will be deemed to have been
            given on transmission unless an electronic error message is received
            by the sender. Notices to Unisource must be sent to&nbsp;
          </span>
          <u>
            <span
              style={{
                fontSize: "16px",
                fontFamily: '"Arial",sans-serif',
                color: "blue",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              info@unisourceinternational.com
            </span>
          </u>
          <span
            style={{
              fontSize: "16px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            . Notices to You will be sent to the email address(es) on record in
            our account information for You. You must keep all Your account
            information current.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
              border: "none windowtext 1.0pt",
              padding: "0in",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            THESE TERMS &amp; CONDITIONS (“Agreement” or “Terms”) GOVERN YOUR
            ACQUISITION AND USE OF EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;SERVICES. IF YOU REGISTER FOR A FREE TRIAL FOR EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;SERVICES, THIS AGREEMENT WILL ALSO GOVERN THAT FREE TRIAL. BY
            ACCEPTING THIS AGREEMENT, EITHER BY CHECKING A BOX INDICATING YOUR
            ACCEPTANCE OR BY EXECUTING AN ORDER FORM THAT REFERENCES THIS
            AGREEMENT, YOU AGREE TO THE TERMS OF THIS AGREEMENT AND THESE TERMS
            WILL THEN APPLY TO YOU FROM THE TIME THAT YOU FIRST ACCESS THE
            SERVICES (INCLUDING WHERE YOU HAVE SUBSCRIBED VIA AN EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;PARTNER). IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF
            A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE
            AUTHORITY TO BIND SUCH AN ENTITY AND ITS AFFILIATES TO THESE TERMS,
            IN WHICH CASE THE TERMS “YOU” OR “YOUR” SHALL REFER TO SUCH ENTITY
            AND ITS AFFILIATES. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO
            NOT AGREE WITH THESE TERMS, YOU MUST NOT ACCEPT THIS AGREEMENT AND
            MAY NOT USE THE SERVICES.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            We at Unisource Solutions (Pvt) Ltd (hereinafter referred as
            Unisource) reserves the right to amend these Terms at any time,
            effective upon the posting of such modified Terms on the Website.
            Unisource Solutions (Pvt) Ltd will make every effort to communicate
            these changes to You via the Website and also via in-app
            notification. It is likely that the Terms will change over time. It
            is Your obligation to ensure that You have read and understood the
            most recent Terms available on the Website. These Terms were last
            updated in February 2022. They replace any prior agreement(s)
            between You and Unisource with regards to EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            . When we change these Terms, the “last updated” date above will be
            updated to reflect the date of the most recent version.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              1. DEFINITIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Agreement” or “Terms”
            <br />
            &nbsp;means these EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;Terms and Conditions and includes any notices, policies,
            guidelines or conditions sent to You by Unisource through EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Appor posted on the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Access Fee”
            <br />
            &nbsp;means the monthly or annual fee (excluding any taxes and
            duties) and any applicable one-time-service fees payable by You in
            accordance with the fee schedule set out on the Website and as
            agreed to in Your Order Form for the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Affiliates”
            <br />
            &nbsp;means any entity that directly or indirectly controls, is
            controlled by, or is under common control with, You (for example,
            one of Your subsidiaries).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Billing Contact”
            <br />
            &nbsp;means Your nominated contact entity and address for billing
            purposes.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Confidential Information”
            <br />
            &nbsp;means any information which the disclosing party identifies as
            confidential or which ought reasonably to be considered confidential
            because of its nature and the manner of its disclosure, including
            Subscriber Data and information about the disclosing party’s
            business plans, technical data, and the terms of Your Order but
            excluding information which is, or becomes, publicly available or
            that is already known by, or rightfully received by, the other party
            other than as a result of a breach of an obligation of
            confidentiality.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Data”
            <br />
            &nbsp;means any photos, images, videos, graphics, written content,
            audio files, location information, code, information or data
            inputted or uploaded by You (including by an Invited User and, where
            You have subscribed to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services via a Unisource Partner, by that Unisource Partner on Your
            behalf) into the Website processed or made available to You or
            others as a direct result of Your use of the Services and the
            Website (e.g., Subscriber specific reports).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Intellectual Property Right”
            <br />
            &nbsp;means any patent, trade mark, service mark, copyright, moral
            right, right in a design, know-how and any other intellectual or
            industrial property rights, anywhere in the world whether or not
            registered.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Invited User”
            <br />
            &nbsp;means a unique individual authorised by You to use the
            Services for Your benefit in accordance with this Agreement,
            including Your employees, representatives, contractors and agents
            and the employees, representatives, contractors and agents of Your
            Affiliates (if any).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Loss”
            <br />
            &nbsp;means all liabilities, expenses, losses, damages and costs
            (including legal costs) and expenses, however arising. “Maximum
            Total Invited Users” means the maximum number of Invited Users as
            specified on Your Order Form or as subsequently varied from time to
            time by agreement with the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;support team.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Order Form”
            <br />
            &nbsp;means the subscription check out page where the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;Services, subscription plan, Maximum Total Invited Users,
            Renewal Period and other Usage Limitations and service features are
            selected (as subsequently varied from time to time by agreement
            between You and Unisource) and by which You agree or, where You have
            subscribed to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;Services via an Unisource Partner, the Unisource Partner
            agrees on Your behalf, to subscribe to the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Renewal Date”
            <br />
            &nbsp;means the date (monthly or annual) on which Your subscription
            renews.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Renewal Period”
            <br />
            &nbsp;means the period for which You agree to subscribe to the
            Services and (subject to clause 3.1(ii)) for which You agree to
            prepay the Access Fees (either monthly or annually) as specified in
            Your Order Form (and which may differ for each EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;Services).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Services”
            <br />
            &nbsp;means the online inventory management and other EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;platforms/products that You have subscribed to by an Order
            Form or, where You have subscribed to the Services via an Unisource
            Partner, the Unisource Partner has subscribed to on Your behalf, or
            that Unisource otherwise makes available to You via the Website (as
            may be varied from time to time by Unisource in accordance with
            clause 2.2).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Subscriber”, “You” and “Your”
            <br />
            &nbsp;means the person or the company or other legal entity who is
            identified in the Order Form as the Subscriber. This can also be
            known as the “Account Owner”.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Subscription Term”
            <br />
            &nbsp;means the initial period (monthly or annual) for which you
            agree to subscribe to the Services and for which you agree to prepay
            the Access Fees as specified in Your Order Form, together with each
            subsequent Renewal Period (unless terminated earlier in accordance
            with the terms of this Agreement).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Unisource”
            <br />
            &nbsp;means the applicable Unisource entity specified in clause 10.5
            based on the billing address specified in Your Order Form and, in
            each case, their legal successors.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Unisource Partner”
            <br />
            &nbsp;means any authorised third party channel partner through whom
            you obtain a subscription to the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Usage Limitations”
            <br />
            &nbsp;means those limitations as specified on Your Order Form or as
            subsequently notified in accordance with clause 3.7, and includes
            any limitations that Unisource imposes in relation to future EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services modules or features.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Website”
            <br />
            &nbsp;means the Internet site at the domain&nbsp;
          </span>
          <a href="https://efig.unisourceinternational.com">
            <span
              style={{ fontSize: "15px", fontFamily: '"Arial",sans-serif' }}
            >
              https://efig.unisourceinternational.com
            </span>
          </a>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            , and mobile apps operated by EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            .
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            “Your Organisations”
            <br />
            &nbsp;means, an organisation that You have added as a subscriber to
            the Services or that has been added with Your authority or as a
            result of Your use of the Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Any reference to “includes” or “including” is on a without
            limitation basis.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              2. END USER LICENSE AGREEMENT (USE OF SOFTWARE)
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            2.1 Rights to access and use the Services:
            <br />
            &nbsp;Unisource grants You the right to access and use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services (including to authorise Invited Users to access and use the
            EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services) via the Website in accordance with Your subscription type,
            as specified in Your Order Form (subject to any Usage Limitations).
            This right is non-exclusive and limited by, and subject to, the
            terms of this Agreement. You acknowledge and agree that, subject to
            any applicable laws:
            <br />
            &nbsp;a. the Subscriber determines who is an Invited User and what
            level of access each Invited User has to the Services, Website and
            Data (up to the applicable Maximum Total Invited Users);
            <br />
            &nbsp;b. the Subscriber is responsible for all Invited Users’ use of
            the Services and the Website (whether authorised by the Subscriber
            or not);
            <br />
            &nbsp;c. the Subscriber controls each Invited User’s level of access
            to the Services, Website and Data at all times and can revoke or
            change an Invited User’s access, or level of access, at any time and
            for any reason, in which case that person or entity will cease to be
            an Invited User or shall have that different level of access, as the
            case may be;
            <br />
            &nbsp;d. Invited Users’ user names and passwords may not be shared
            or used by more than one individual and the Subscriber may only
            re-assign access rights to a new individual user where an existing
            Invited User no longer requires the rights to access and use the
            Services and Website; and
            <br />
            &nbsp;e. if there is any dispute between a Subscriber and an Invited
            User regarding access to any Services, Website or Data, the
            Subscriber shall decide what access or level of access to the
            relevant Services, Website or Data that Invited User shall have, if
            any.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            2.2 Our right to make changes to the Services:
            <br />
            &nbsp;Unisource &nbsp;may modify the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services from time to time, including by adding or deleting features
            and functions, as we continually improve our Services and Website
            and the experience we offer You. However Unisource will not make
            changes to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services that materially reduce the functionality provided to You
            during the prepaid period of Your subscription. However, You
            acknowledge that Unisource does not control changes made to their
            service terms by third party service provider (e.g., emergency
            response, backup &amp; rescue services) to their customers and that
            these types of changes may impact the functionality of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              3. YOUR OBLIGATIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.1. Payment obligations:
            <br />
            &nbsp;a. An invoice for the Access Fee will be issued:
            <br />
            &nbsp;i. monthly or annually in advance (depending on Your
            subscription type) at the beginning of Your Subscription Term and
            subsequently on each Renewal Date; or
            <br />
            &nbsp;ii. where we offer, and You select, an annual subscription
            with monthly billing, monthly in advance during Your Subscription
            Term.
            <br />
            &nbsp;b. All invoices will include the Access Fee for the
            corresponding period of use (including any one-time-service fees
            that shall be payable in lump sums). Unisource will continue
            invoicing You monthly or annually in advance (as the case may be)
            until this Agreement is terminated in accordance with clause 8.
            <br />
            &nbsp;c. All Unisource invoices for EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;Services will be sent to You, or to a Billing Contact whose
            details are provided by You, by email. You must pay or arrange
            payment of all amounts specified in any invoice within five days of
            the invoice date. You are responsible for payment of all applicable
            taxes and duties in addition to the Access Fees. If You are required
            to deduct or withhold any tax, You must pay the amount deducted or
            withheld as required by law and pay us an additional amount so that
            we receive payment in full as if there were no deduction or
            withholding.
            <br />
            &nbsp;d. If any invoiced amount remains unpaid after the payment due
            date then Unisource may charge You late payment interest calculated
            at a daily rate of 1%.
            <br />
            &nbsp;e. If Unisource has to spend money collecting overdue amounts
            from You then You will reimburse Unisource for those costs.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.2 Variations to Access Fees:
            <br />
            &nbsp;Subject to clause 3.7, the Access Fee will remain fixed during
            the initial period of Your Subscription Term or the relevant Renewal
            Period (as applicable). Unisource reserves the right to increase the
            Access Fees for the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services on 30 days’ written notice to You with any such increase to
            then take effect on the first Renewal Date following the end date of
            such notice period.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.3 Subscribers ordering via an Unisource Partner:
            <br />
            &nbsp;If You have subscribed to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services via an Unisource Partner, Your Unisource Partner will issue
            the invoices for the Access Fees to You, and You must pay the
            invoiced amounts to the Partner in accordance with clause 3.1.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.4. Preferential pricing or discounts:
            <br />
            &nbsp;You may from time to time be offered preferential pricing or
            discounts for the Access Fees as a result of the number of Your
            customers. Eligibility for such preferential pricing or discounts is
            conditional upon Your acceptance of responsibility for payment of
            Access Fees in relation to all of Your customers. Without prejudice
            to any other rights that Unisource may have under these Terms or at
            law, Unisource reserves the right to render invoices for the full
            (non-discounted) Access Fees due or suspend or terminate Your use of
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services in respect of any or all of Your customers in the event
            that any invoices for those Access Fees are not paid in full by You
            to Unisorce by the due date for payment.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.5. General obligations:
            <br />
            &nbsp;You are solely responsible for your use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and Website and for Your Data. You must only use the
            Services and Website for Your own lawful internal business purposes,
            in accordance with these Terms and any notices, policies, guidelines
            or conditions sent to You by Unisource or posted on the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.6. Access conditions:
            <br />
            &nbsp;a. You must ensure that all usernames and passwords (including
            any passwords allocated to Invited Users) required to access the
            Services and Website are kept secure and confidential. You must
            immediately notify Unisource of any unauthorised use of Your
            passwords or any other breach of security and Unisource will reset
            Your password(s) and You must take all other actions that Unisource
            reasonably deems necessary to maintain or enhance the security of
            EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            ’s services and networks (including the Website) and Your access to
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services.
            <br />
            &nbsp;b. When accessing and using the Services You must not:
            <br />
            &nbsp;i. attempt to undermine the security or integrity of EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            ’s computing systems or networks (including the Website) or, where
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services are hosted by a third party, that third party’s computing
            systems and networks;
            <br />
            &nbsp;ii. use, or misuse, the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;Services in any way which may impair the functionality of the
            Services, Website or other systems used to deliver the Services or
            impair the ability of any other user to use the Services or Website,
            including by misusing the Services in a manner that materially
            exceeds reasonable usage or use patterns over any month or by using
            the Services in a malicious, fraudulent or unlawful manner;
            <br />
            &nbsp;iii. attempt to gain unauthorised access to any materials
            other than those to which You have been given express permission to
            access or to the computer system on which the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services are hosted;
            <br />
            &nbsp;iv. transmit, input or upload to the Website, any files that
            may damage any other person’s computing devices or software, content
            that may be offensive, or material or Data in violation of any law
            (including Data or other material protected by copyright or trade
            secrets which You do not have the right to use); or
            <br />
            &nbsp;v. attempt to modify, copy, adapt, reproduce, disassemble,
            decompile or reverse engineer any computer programs used to deliver
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services or to operate the Website except as is strictly necessary
            to use either of them for normal operation.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.7. Usage Limitations:
            <br />
            &nbsp;a. In addition to the Maximum Total Named Users, Your use of
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services may be subject to Usage Limitations, including monthly
            transactions, You are permitted to make against EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            ’s application programming interface (“API”). Any Usage Limitations
            will remain in effect for the duration of the initial period of Your
            Subscription Term or the relevant Renewal Period (as applicable).
            Unisource reserves the right to vary the Usage Limitations on 30
            days’ written notice to You with any such variation to then take
            effect on the first Renewal Date following the end date of such
            notice period (unless Unisource chooses to relax certain Usage
            Limitations, in which case such variation will take effect
            immediately).
            <br />
            &nbsp;b. You may not use or access the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services in a manner that exceeds these Usage Limitations. If You
            exceed Your Usage Limitations then then You must either disable or
            correct such use, or
            <br />
            &nbsp;Your Subscription will be upgraded to the subscription tier
            which corresponds with Your actual use.
            <br />
            &nbsp;c. Where Your Subscription is upgraded in accordance with
            clause 3.7(b), &nbsp;Unisource will invoice You for the Access Fee
            that corresponds to Your new Subscription tier and you agree that
            the new Access Fee will apply from the date of such upgrade.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.8 Usage reviews:
            <br />
            &nbsp;You agree that Unisource may review Your use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services at any time during the Subscription Term, and You will
            provide any reasonable assistance to verify Your compliance with
            this Agreement as we may request. Unisource may suspend Your rights
            to access and use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services (including Your rights to access any of Your Data)
            immediately upon written notice if we determine that Your use of the
            Services (including any of Your Data) breaches this Agreement
            (without limiting any other rights or remedies Unisource may have).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            3.9. Communication Conditions:
            <br />
            &nbsp;a. As a condition of these Terms, if You use any communication
            tools available through the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services(such as any text chat, exchanging pictures and audio
            files), You agree only to use such communication tools for lawful
            and legitimate purposes. You must not use any such communication
            tool for posting or disseminating any material unrelated to the use
            of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services, including: unsolicited commercial e-mail, files that may
            damage any other person’s computing devices or software, content
            that may be offensive to any other users of the Services or the
            Website, or material in violation of any law (including material
            that is protected by copyright or trade secrets which You do not
            have the right to use).
            <br />
            &nbsp;b. When You generate any communication through EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            , You represent that You are permitted to generate such
            communication. Unisource is under no obligation to ensure that the
            communications on the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services are legitimate or that they are related only to the use of
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services. As with any other web-based forum, You must exercise
            caution when using the communication tools available on the Website.
            However, Unisource does reserve the right to remove any
            communication at any time in its sole discretion.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              4. CONFIDENTIALITY AND PRIVACY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            4.1. Confidentiality:
            <br />
            &nbsp;Unless the relevant party has the prior written consent of the
            other or unless required to do so by law:
            <br />
            &nbsp;a. Each party will preserve the confidentiality of all
            Confidential Information of the other obtained in connection with
            these Terms. Neither party will, without the prior written consent
            of the other, disclose or make any Confidential Information
            available to any person, or use the same for its own benefit, other
            than as expressly contemplated by these Terms or, where Unisource is
            the recipient, in order to provide the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services to You.
            <br />
            &nbsp;b. Each party’s obligations under this clause will survive
            termination of these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            4.2. Privacy:
            <br />
            &nbsp;a. Unisource maintains a privacy policy that sets out what
            information Unisource collects about individuals and why, what
            Unisource does with that information, and how Unisource
            &nbsp;handles that information. You should read that policy at&nbsp;
          </span>
          <a href="https://ears.pk/privacy-policy/">
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "white",
                border: "none windowtext 1.0pt",
                padding: "0in",
                textDecoration: "none",
              }}
            >
              https://EFIG.pk/privacy-policy/
            </span>
          </a>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            &nbsp;(“Privacy Policy”). In the event of any conflict between these
            Terms and the terms of the Unisource Privacy Policy, the terms of
            our Privacy Policy will take precedence.
            <br />
            &nbsp;b. If You are subject to the territorial scope of the
            Regulation (EU) 2016/679 (GDPR), You agree that You are a “data
            controller” and that Unisource is a “data processor” (as those terms
            are defined in the GDPR) and that the terms of the Data Processor
            Agreement form part of this Agreement.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              5. INTELLECTUAL PROPERTY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.1. General:
            <br />
            &nbsp;Title to, and all Intellectual Property Rights in the
            Services, the Website and any documentation relating to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services remain the property of Unisource (or its licensors).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.2. Ownership of Data:
            <br />
            &nbsp;Title to, and all Intellectual Property Rights in, the Data
            remain Your property. However, Your access to the Data is contingent
            on full payment of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services Access Fees when due and any re-establishment fee due and
            payable under clause 5.6. You grant Unisource a licence to use,
            copy, transmit, store, and back-up Your Data for the purposes of
            enabling You to access and use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and for any other purpose related to provision of EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services to You. For the avoidance of doubt, this licence is without
            limitation to Unisource’s right to create anonymised data
            compilations or similar works based on Your Data.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.3. Backup of Data:
            <br />
            &nbsp;You must maintain copies of all Data inputted or uploaded into
            the Website. Unisource adheres to its best practice policies and
            procedures to prevent data loss, including a daily system data
            back-up regime, but does not make any guarantees that there will be
            no loss of Data. Unisource expressly excludes liability for any loss
            of Data (including Data archived pursuant to clause 5.6) no matter
            how caused.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.4. Third-party apps and Your Data:
            <br />
            &nbsp;If You enable third-party apps for use in conjunction with the
            Services, You acknowledge that Unisource &nbsp;may allow the
            providers of those third-party apps to access Your Data as required
            for the interoperation of such third-party apps with the Services.
            Unisource shall not be responsible for any disclosure, modification
            or deletion of Your Data resulting from any such access by
            third-party application providers. You also acknowledge that those
            third party app providers are independent of EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and those apps will be subject to terms and conditions and
            privacy notices set by their providers.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.5. Accuracy of Data:
            <br />
            &nbsp;You agree and acknowledge that You are responsible for
            ensuring the accuracy of Data inputted into the mobile app and
            Website by You or by any person on Your behalf (including an
            Unisource Partner). Unisource is under no obligation to ensure that
            Your Data in the Website is an accurate representation of Your
            actual business data.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            5.6 Deletion and recovery of Data:
            <br />
            &nbsp;a. Where:
            <br />
            &nbsp;i. You choose to discontinue Your Services in accordance with
            clause 8.1;
            <br />
            &nbsp;ii. either party terminates this Agreement in accordance with
            clause 8.3;
            <br />
            &nbsp;iii. Unisource suspends or terminates this Agreement in
            accordance with clause 8.5; or
            <br />
            &nbsp;iv. Unisource otherwise suspends and/or terminates Your right
            to access to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and/or the Website in accordance with this Agreement,
            <br />
            &nbsp;it is Your responsibility to extract Your Data from the
            Website prior to the effective date of such suspension or
            termination. Following the effective date of suspension or
            termination, Unisource reserves the right, in Unisource’s sole
            discretion, to either (i) delete Your Data or (ii) archive Your Data
            for a period of time of up to six months (upon the expiry of which
            time Unisource may delete Your Data).
            <br />
            &nbsp;b. If Unisource has chosen to archive Your Data and if Your
            Services are subsequently re-established within the archive period
            then Unisource will restore Your access to the archived Data
            provided that You pay Unisource in advance:
            <br />
            &nbsp;i. all arrEFIG of Your unpaid Access Fee over the period that
            the Data was archived; and
            <br />
            &nbsp;ii. a re-establishment fee of PKR150,000 or such other amount
            as is determined by Unisource in Unisource’s sole discretion and
            notified to You).
            <br />
            &nbsp;c. Where your right to access and use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and Website has been terminated and Unisource has chosen to
            archive Your Data, if You omitted to extract Your Data prior to the
            effective date of such termination and You subsequently notify us
            that You require access to Your Data then Unisource will temporarily
            restore Your access to the archived Data provided that You
            re-subscribe to the Services for a month and that You pay Unisource
            the associated Access Fee together with a one-off fee of such amount
            as is determined by Unisource in Unisource’s sole discretion and
            notified to You.
            <br />
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              6. WARRANTIES AND ACKNOWLEDGEMENTS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.1. Data:
            <br />
            &nbsp;a. You represent and warrant that:
            <br />
            &nbsp;i. You have obtained all necessary consents and are otherwise
            authorised to input or upload the Data that You input or upload to
            the Website, including any Data inputted or uploaded to the Website
            by any Invited User or by a Unisource Partner on Your behalf; and
            <br />
            &nbsp;ii. You are similarly authorised to access the processed Data
            that is made available to You through Your use of the Website and
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services, in each case, whether the inputted or uploaded Data is
            Your own or that of anyone else.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.2 Rights of third parties / access to Data:
            <br />
            &nbsp;a. You acknowledge and agree that Unisource has no
            responsibility to any person other than You and nothing in this
            Agreement confers, or purports to confer, a benefit on any person
            that is enforceable against us by any person (including Your
            Affiliates) other than You.
            <br />
            &nbsp;b. If You use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services or access the Website on behalf of or for the benefit of
            anyone other than Yourself (whether a body corporate or otherwise)
            You agree that:
            <br />
            &nbsp;i. You are responsible for ensuring that You have the right to
            do so;
            <br />
            &nbsp;ii. In the case of a third person requiring access to Your
            Data (i.e., other than an Invited User), You are responsible for
            authorising any such person to access Your Data, and You agree that
            Unisource has no obligation to provide any such person with access
            to Your Data without Your prior written authorisation (and may refer
            any third party access requests for information to You to address);
            and
            <br />
            &nbsp;iii. You will indemnify Unisource against any Loss arising out
            of or in connection with EFIG’s refusal to provide such third
            persons with access to Your Data and/or Unisource enabling access by
            such third persons to Your Data where this has been done with Your
            written authorisation.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.3 Acknowledgements and disclaimers:
            <br />
            &nbsp;You acknowledge and agree that Unisource gives no warranty
            about the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and Website and, without limiting the foregoing:
            <br />
            &nbsp;a. The provision of, access to, and use of, the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and the Website is on an “as is” basis and at Your own
            risk.
            <br />
            &nbsp;b. Unisource does not warrant that the use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services or the Website will be uninterrupted or error free. Among
            other things, the operation and availability of the systems used for
            accessing the Service and the Website, including public telephone
            services, computer networks and the Internet, can be unpredictable
            and may from time to time interfere with or prevent access to the
            EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and the Website. Unisource is not in any way responsible
            for any such interference or prevention of Your access or use of the
            EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services.
            <br />
            &nbsp;c. Unisource does not warrant that the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services or the Website will meet Your requirements or that the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services or the Website will be suitable for any particular purpose.
            It is Your sole responsibility to determine that the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and the Website meet the needs of Your business and are
            suitable for the purposes for which they are used.
            <br />
            &nbsp;d. You remain solely responsible for complying with all
            applicable accounting, tax and other laws. It is Your responsibility
            to check that storage of and access to Your Data via the Website
            will comply with laws applicable to You (including any laws
            requiring You to retain records).
            <br />
            &nbsp;e. You are solely responsible for assessing the correct taxes
            you should charge Your customers.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.4. No implied warranties:
            <br />
            &nbsp;To avoid doubt, all implied conditions or warranties are
            excluded in so far as is permitted by law, including warranties of
            merchantability and fitness for purpose, title and non-infringement.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            6.5. Consumer protection laws:
            <br />
            &nbsp;You warrant and represent that You are acquiring the right to
            access and use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and Website for the purposes of a business and that, to the
            maximum extent permitted by law, any statutory consumer guarantees
            or legislation intended to protect non-business consumers in any
            jurisdiction does not apply to the supply of the Services, the
            Website or these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              7. INDEMNITY AND LIMITATION OF LIABILITY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            7.1. Indemnity:
            <br />
            &nbsp;You indemnify Unisource against all Losses that EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Servicesincurs arising out of or in connection with:
            <br />
            &nbsp;a. Your breach of any of these Terms;
            <br />
            &nbsp;b. any claims of tax authorities of any country, state,
            province or other jurisdiction in relation to the taxes levied on
            sales to Your customers; and/or
            <br />
            &nbsp;c. any third party claims against Unisource relating to Your
            Data or otherwise to Your use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services or Website or any third party app (or other third party
            product).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            7.2 Unisource is not responsible:
            <br />
            &nbsp;To the maximum extent permitted by law, Unisource will not be
            liable to You (or any other person) in contract, tort (including
            negligence), or otherwise, for any Losses (whether direct or
            indirect) arising out of, or in connection with, Your use of, or
            reliance on, the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services or Website or otherwise arising out of or in connection
            with these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            7.3. If, notwithstanding clause 7.2, Unisource is determined to have
            any liability to You (or any third party) arising out of or in
            connection with these Terms, Unisource’s liability in respect of any
            one incident, or series of connected incidents, will be limited to
            an amount equal to the Access Fees paid by You in the one month
            preceding the first such incident.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              8. TERMINATION AND SUSPENSION
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.1. Trial policy:
            <br />
            &nbsp;When You first sign up for access to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services You can evaluate the Services for a period of 30 days or as
            agreed by You and Unisource, with no obligation to continue to use
            the Services. You may elect to, on or before the fourteenth day
            after the initial submission of Your Order Form, discontinue the
            Services and receive a full refund of any Access Fees paid minus any
            one-time-service fees accrued up to the date of discontinuation. If
            You do not elect to discontinue using the Services within this 30
            day timeframe, You will be billed from the day You first added Your
            billing details into Your Order Form. If You choose to discontinue
            using the Services, You must apply in writing
            to&nbsp;support@EFIG.pk&nbsp;to be removed from the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.2. Modifications by You to Your subscription:
            <br />
            &nbsp;You may only reduce Your subscribed Services to a lower level
            subscription lesser Service by providing Unisource with written
            notice via support@EFIG.pk at least ten days prior to the next
            Renewal Date and where You have not used the Service or Website
            features that are not available in the lower level subscription. The
            change to Your subscription will then take effect on that Renewal
            Date. You may change Your subscribed EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services by adding additional Services or Invited Users or otherwise
            increasing Your subscription levels at any time.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.3. No-fault termination:
            <br />
            &nbsp;The Subscription Term will renew automatically at the end of
            the initial period of Your Subscription Term and then subsequently
            at the end of each Renewal Period and the Access Fee for that month
            or year (as applicable) will continue to be due and payable in
            advance (in accordance with clause 3.1), unless either party
            terminates these Terms by giving notice to the other party at least
            30 days before the end of the initial subscription period or the
            relevant Renewal Period (as applicable). If insufficient notice is
            given then:
            <br />
            &nbsp;a. if Your subscription renews on a monthly basis, Your
            subscription will not be cancelled until the next Renewal Date;
            <br />
            &nbsp;b. if Your subscription renews on an annual basis then,
            provided Your notice is received by Unisource no later than seven
            days following the Renewal Date, Unisource will accept Your notice
            of cancellation and will refund the Access Fees already paid by You
            in relation to the new Renewal Period. If Your notice is received by
            Unisource more than seven days after the Renewal Date then Unisource
            will accept Your notice of cancellation and may then choose (in its
            sole discretion) to refund some or all of the Access Fees already
            paid by You that relate to the unused portion of the new Renewal
            Period. Where we offer and You have selected an annual subscription
            with monthly billing then if your notice is received by Unisource
            during the initial period of Your EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Subscription Term or more than seven days after the Renewal Date
            then Unisource may invoice You for some or all of the Access Fees
            that relate to the unused portion of the initial subscription period
            or the new Renewal Period (as applicable).
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.4 Overdue:
            <br />
            &nbsp;a. Access Fees are paid in advance, however in the event that
            Your account becomes overdue this must be settled within five days
            otherwise Unisource reserves the right to suspend Your subscription
            and Your rights to access the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services, Website and the Data and to charge a reactivation fee to
            reinstate Your account (equal to the cost of a one month
            subscription). The Billing Contact will be notified once an account
            becomes overdue.
            <br />
            &nbsp;b. If You have subscribed to the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services via a Unisource Partner then that Unisource Partner may
            also request that Your account be suspended due to non-payment. In
            the event such a request is made Unisource will endeavour to contact
            You to confirm this via email, if provided, else it will be the sole
            responsibility of the Partner to send a message to You and all
            Invited Users before Your account is suspended.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.5. Breach:
            <br />
            &nbsp;If You:
            <br />
            &nbsp;a. breach any of these Terms (including by non-payment of any
            Access Fees) and do not remedy the breach within thirty days after
            receiving notice of the breach if the breach is capable of being
            remedied;
            <br />
            &nbsp;b. breach any of these Terms and the breach is not capable of
            being remedied; or
            <br />
            &nbsp;c. You are declared bankrupt or You become bankrupt,
            insolvent, or You go into liquidation or a receiver or manager is
            appointed over any of Your assets, or You make any arrangement with
            Your creditors, or become subject to any similar insolvency event in
            any jurisdiction, then (without limiting Unisource’s suspension
            rights at clause 8.4 above) EFIG® &nbsp;may take any or all of the
            following actions, at its sole discretion:
            <br />
            &nbsp;i. terminate this Agreement and Your right to access and use
            the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and the Website;
            <br />
            &nbsp;ii. suspend, for any definite or indefinite period of time,
            Your right to access and use of the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and the Website;
            <br />
            &nbsp;iii. suspend or terminate Your access to any or all EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Data through the Website; or
            <br />
            &nbsp;iv. delete Your EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Data in accordance with clause 5.6.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.6. Accrued Rights:
            <br />
            &nbsp;Termination of these Terms is without prejudice to any rights
            and obligations of the parties accrued up to and including the date
            of termination. On termination of this Agreement You will:
            <br />
            &nbsp;a. remain liable for any accrued charges and amounts which
            become due for payment before or after termination; and
            <br />
            &nbsp;b. immediately cease to use the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and the Website.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.7. Expiry or termination:
            <br />
            &nbsp;Those clauses which by their nature survive expiry or
            termination, including clauses 3.1, 4, 5, 6, 7, 8 and 10, will
            survive the expiry or termination of these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            8.8. Refund Policy:
            <br />
            &nbsp;Subject to clause 8.1 Unisource does not provide refunds if
            You decide to stop using the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services and accessing the Website at any time during Your
            Subscription Term.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              9. HELP DESK
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.1. Technical Problems:
            <br />
            &nbsp;You have a right to technical support, in accordance with the
            Customer Success Plan on Your subscription, or in the case where You
            have none, the Essential Customer Success Plan.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            9.2. Service availability:
            <br />
            &nbsp;Whilst Unisource intends that the Services and the Website
            should be available 24 hours a day, seven days a week, it is
            possible that on occasions the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services or Website may be unavailable to permit maintenance or
            other development activity to take place. If for any reason
            Unisource has to interrupt the EFIG
          </span>
          <sup>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              ®&nbsp;
            </span>
          </sup>
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            Services for any period, Unisource will use reasonable endeavours to
            publish in advance details of such activity.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "15px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
                border: "none windowtext 1.0pt",
                padding: "0in",
              }}
            >
              10. GENERAL
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            10.1. Entire agreement:
            <br />
            &nbsp;These Terms, together with the Unisource Privacy Policy and
            the Data Processing Agreement (where applicable) and the terms of
            any other notices or instructions given to You under these Terms,
            supersede and extinguish all prior agreements, representations
            (whether oral or written), and understandings and constitute the
            entire agreement between You and Unisource relating to the Services
            and Your access and use of the Website and the other matters dealt
            with in these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            10.2. Waiver:
            <br />
            &nbsp;A waiver of any breach of any provision in these Terms shall
            not be effective unless that waiver is in writing and is signed by
            the party against whom that waiver is claimed. If either party
            waives any breach of these Terms, this will not constitute a waiver
            of any other breach. No waiver will be effective unless made in
            writing.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            10.3. Delays:
            <br />
            &nbsp;Neither party will be liable for any delay or failure in
            performance of its obligations under these Terms if the delay or
            failure is due to any cause outside its reasonable control. This
            clause does not apply to any obligation to pay money.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            10.4. No Assignment:
            <br />
            &nbsp;You may not assign or transfer this Agreement or any of Your
            rights under this Agreement to another person without our prior
            written consent. The transfer of the billing ownership may be
            requested by contacting support@EFIG.pk. It is at the discretion of
            Unisource to action this change over.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            10.5. Unisource entity, governing law and jurisdiction:
            <br />
            &nbsp;
          </span>
        </p>
        <table
          style={{
            width: "470.25pt",
            marginLeft: "-.15pt",
            border: "solid windowtext 1.0pt",
          }}
        >
          <thead>
            <tr>
              <td
                style={{
                  width: "60.15pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: '"Times New Roman",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Billing Address
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "115.8pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: '"Times New Roman",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      EFIG® &nbsp;Entity
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "144.1pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: '"Times New Roman",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Address for notices
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "73.7pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: '"Times New Roman",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Registration number
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "14pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: '"Times New Roman",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Law
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: '"Times New Roman",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Venue
                    </span>
                  </strong>
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  width: "60.15pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <strong>
                    <span
                      style={{
                        fontSize: "15px",
                        fontFamily: '"Times New Roman",serif',
                        border: "none windowtext 1.0pt",
                        padding: "0in",
                      }}
                    >
                      Pakistan
                    </span>
                  </strong>
                </p>
              </td>
              <td
                style={{
                  width: "115.8pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    Unisource Solutions (Pvt) Limited
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "144.1pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    1.6 KM Off Defence Road, Industrial Area Kahna Flyover,
                    Defence Road, LAHORE
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "73.7pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    0079421
                  </span>
                </p>
              </td>
              <td
                style={{
                  width: "14pt",
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    Pakistan
                  </span>
                </p>
              </td>
              <td
                style={{
                  border: "1pt solid windowtext",
                  padding: "6pt",
                  verticalAlign: "bottom",
                }}
              >
                <p
                  style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "normal",
                    fontSize: "15px",
                    fontFamily: '"Calibri",sans-serif',
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontFamily: '"Times New Roman",serif',
                    }}
                  >
                    Pakistan
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            10.6. Severability:
            <br />
            &nbsp;If any part or provision of these Terms is invalid,
            unenforceable or in conflict with the law, that part or provision is
            replaced with a provision which, as far as possible, accomplishes
            the original purpose of that part or provision. The remainder of
            this Agreement will be binding on the parties.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: ".0001pt",
            marginLeft: "0in",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            background: "white",
            verticalAlign: "baseline",
          }}
        >
          <span
            style={{
              fontSize: "15px",
              fontFamily: '"Arial",sans-serif',
              color: "black",
            }}
          >
            10.7. Notices:
            <br />
            &nbsp;Any notice given under these Terms by either party to the
            other must be in writing by email and will be deemed to have been
            given on transmission unless an electronic error message is received
            by the sender. Notices to Unisource must be sent to info@EFIG.pk.
            Notices to You will be sent to the email address(es) on record in
            our account information for You. You must keep all Your account
            information current.
          </span>
        </p>
        <p
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "8.0pt",
            marginLeft: "0in",
            lineHeight: "107%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
      </div>
    </div>
  );
};

export default Terms;
