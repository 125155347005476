import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "light",
  screenWidth: { width: 1600, height: 720 },
  previousModal: null,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setScreenWidth: (state, action) => {
      state.screenWidth = action.payload;
    },
    setPreviousModal: (state, action) => {
      state.previousModal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTheme, setScreenWidth, setPreviousModal } =
  themeSlice.actions;

export default themeSlice.reducer;
