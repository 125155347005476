/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { useDispatch, useSelector } from "react-redux";
import { IsEmpty } from "../../../../../helpers/utils";
import { BroadCast, GetAnnouncements } from "../../../../../actions";
import axios from "axios";
import { URL } from "../../../../../config";
import { setIsAnnLoading } from "../../../../../redux/slices/userSlice";
import Select from "react-select";

const CreateAnn = (props) => {
  const dispatch = useDispatch();
  const isAnnLoading = useSelector((state) => state.user.isAnnLoading);
  const groups = useSelector((state) => state.user.groups);
  const user = useSelector((state) => state.user.user);
  const customers = useSelector((state) => state.user.customers);
  const isLoading = useSelector((state) => state.user.isLoading);

  const [_customers, set_customers] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [webLink, setWebLink] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [dataa, setDataa] = useState("");
  const [groupId, setGroupId] = useState("");
  const [userOpt, setUserOpt] = useState("");
  const [cus, setCus] = useState("");

  useEffect(() => {
    const arr = [];
    let list = customers;
    if (_customers) {
      list = _customers;
    }

    list?.forEach((val) => {
      arr.push({
        value: val,
        label: `${val?.customerFirstname} ${val?.customerLastname} ( ${val?.address} )`,
      });
    });
    setUserOpt(arr);
  }, [customers, _customers]);

  useEffect(() => {
    if (groupId) {
      let list = customers.filter((val) => val.groupId == groupId);
      console.log(list);
      set_customers(list);
    }
  }, [groupId]);

  const groupList = () => {
    return groups.map((val, index) => {
      return (
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      );
    });
  };
  // const duration = async () => {
  //   let num = 1;
  //   let interval = setInterval(() => {
  //     setdTime(convertHMS(num));
  //     num++;
  //   }, 1000);
  //   setInter(interval);
  // };

  // function convertHMS(value) {
  //   const sec = parseInt(value, 10); // convert value to number if it's string
  //   let hours = Math.floor(sec / 3600); // get hours
  //   let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  //   let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  //   // add 0 if value < 10; Example: 2 => 02
  //   if (hours < 10) {
  //     hours = "0" + hours;
  //   }
  //   if (minutes < 10) {
  //     minutes = "0" + minutes;
  //   }
  //   if (seconds < 10) {
  //     seconds = "0" + seconds;
  //   }
  //   return minutes + ":" + seconds; // Return is HH : MM : SS
  // }

  // const onStop = (audioData) => {
  //   // console.error("audioData >>> ", audioData);
  //   if (inter) {
  //     clearInterval(inter);
  //   }
  //   // console.log("ashgdhjgsa", audioData);

  //   setDataa(audioData);
  //   // if (doUpload) {
  //   setAudioFile(audioData);
  //   // }
  // };

  const creatAnn = async () => {
    if (IsEmpty(title)) {
      errorToast("Title id required");
      return;
    }
    if (IsEmpty(body)) {
      errorToast("Announcement Body required");
      return;
    }
    if (body.length > 1000) {
      errorToast("Body length exceded.");
      return;
    }

    // alert(JSON.stringify(cus.value?.id));
    // return;

    await BroadCast({
      title,
      body,
      videoLink,
      videoTitle,
      webLink,
      webTitle,
      groupId: user.roleId === 1 ? groupId : user.groupId,
      userId: cus?.value?.id || null,
    })
      .then(async (res) => {
        await GetAnnouncements(dispatch);
        emptyFields();
        succesToast("Announcement Added");
      })
      .catch((e) => {
        dispatch(setIsAnnLoading(false));

        errorToast(e.response?.data?.err);
      });
  };

  const emptyFields = () => {
    setTitle("");
    setBody("");
    setVideoLink("");
    setVideoTitle("");
    setWebLink("");
    setWebTitle("");
    setCus("");
    setGroupId("");
  };

  return (
    <>
      <Modal
        title={"Customer Announcement"}
        name={"createAnnouncements"}
        icon={"fa fa-bullhorn"}
      >
        <form>
          <>
            <div className="row">
              <div className="col-12">
                <a href="#" className="nav-link">
                  <i
                    style={{ fontSize: "20px" }}
                    className="fa fa-light fa-arrow-left"
                    title="Go To List"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target={"#listAnnouncements"}
                    onClick={() => emptyFields()}
                  ></i>
                </a>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="mb-3 col-lg-6">
                    <MyTextInput
                      name={"Title*"}
                      val={title}
                      placeholder={""}
                      setVal={setTitle}
                    />
                  </div>
                  <div className="mb-3 col-lg-6">
                    <label className="form-label" style={{ fontWeight: "500" }}>
                      Body*:
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      maxLength={1000}
                      value={body}
                      onChange={(e) => setBody(e.target.value)}
                    ></textarea>
                    <div className="fst-italic float-end">
                      A Maximum of 1000 words.
                      {body ? `(${1000 - body.length} remaining)` : ""}
                    </div>
                  </div>
                </div>
              </div>
              {user.roleId === 1 ? (
                <>
                  <div className="col-lg-4">
                    <label className="col-form-label">Select Group*:</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      value={groupId}
                      onChange={(e) => setGroupId(e.target.value)}
                    >
                      <option value=""></option>
                      {groupList()}
                      {/* <option value="5">Device</option> */}
                    </select>
                  </div>
                </>
              ) : null}
              <div className="col-lg-8">
                <label className="col-form-label">{"EFIG User"}:</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={cus}
                  isLoading={isLoading}
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  value={cus}
                  options={userOpt}
                  onChange={(e) => setCus(e || null)}
                />
                <div className="fst-italic float-end">
                  Select for specific user
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="mb-3 col-lg-3">
                <MyTextInput
                  name={"Web Title"}
                  val={webTitle}
                  placeholder={""}
                  setVal={setWebTitle}
                />
              </div>
              <div className="mb-3 col-lg-3">
                <MyTextInput
                  name={"Web Link"}
                  val={webLink}
                  placeholder={""}
                  setVal={setWebLink}
                />
              </div>
              <div className="mb-3 col-lg-3">
                <MyTextInput
                  name={"Video Title"}
                  val={videoTitle}
                  placeholder={""}
                  setVal={setVideoTitle}
                />
              </div>
              <div className="mb-3 col-lg-3">
                <MyTextInput
                  name={"Video Link"}
                  val={videoLink}
                  placeholder={""}
                  setVal={setVideoLink}
                />
              </div>
            </div>

            <br />
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4" style={{ textAlign: "center" }}>
                {isAnnLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      creatAnn();
                    }}
                  >
                    Broadcast
                  </button>
                )}
              </div>
            </div>
            <br />
          </>
        </form>
      </Modal>
    </>
  );
};

export default CreateAnn;
