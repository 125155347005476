import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddComplaintResponder,
  AddContact,
  AddUserAPI,
  AddWhitelistCustomer,
  DeleteUser,
  GenerateAndSendPassword,
  GetErpTechs,
  GetErpUsers,
  GetUsers,
  SearchCustomers,
  UpdateComplaintResponder,
  UpdateServiceContact,
  UpdateUsers,
  UpdateWhiteListCustomer,
  UploadFile,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { URL } from "../../../../../config";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setActionVal,
  setConfirmText,
  setEditUserData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import Select from "react-select";
import { globalDeleteBtn } from "../../../../../components/DeleteConfirm";

const AddUser = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [userType, setUserType] = useState("");
  const [erpUserId, setErpUserId] = useState("");
  const [contact, setContact] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [groupId, setGroupId] = useState("");
  const [house, setHouse] = useState("");
  const [block, setBlock] = useState("");
  const [street, setStreet] = useState("");
  const [phase, setPhase] = useState("");
  const [town, setTown] = useState("");
  const [city, setCity] = useState("");
  const [cnic, setCnic] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [des, setDes] = useState("");
  const [edit, setEdit] = useState("");
  const [userId, setUserId] = useState("");
  const [uniqueId, setUniqueId] = useState("");
  const [password, setPassword] = useState("");
  const [network, setNetwork] = useState("");
  const [resType, setResType] = useState("");

  const [file, setFile] = useState("");

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const editUserData = useSelector((state) => state.user.editUserData);
  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);
  const groups = useSelector((state) => state.user.groups);
  const erpUsersList = useSelector((state) => state.erp.erpUsersList);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "resetPassword") {
      generateAndSend(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  useEffect(() => {
    if (erpUserId) {
      setFirstname(erpUserId?.firstname);
      setLastname(erpUserId?.lastname);
      setEmail(erpUserId?.email);
    }
  }, [erpUserId]);

  useEffect(() => {
    getErpUser();
  }, []);

  const getErpUser = async () => {
    await GetErpUsers(dispatch);
    await GetErpTechs(dispatch);
  };

  const confirmation = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to reset password for this user?",
        type: "resetPassword",
        id,
      })
    );
    globalDeleteBtn.click();
  };

  useEffect(() => {
    if (editUserData) {
      if (editUserData?.erpUserId) {
        erpUsersList?.every((user) => {
          if (user.id === editUserData.erpUserId) {
            setErpUserId(user);
            return false;
          }
          return true;
        });
      }
      setUserId(editUserData?.id);
      setGroupId(editUserData?.groupId);
      setFirstname(editUserData?.firstname);
      setLastname(editUserData?.lastname);
      setNetwork(editUserData?.network);
      setUsername(editUserData?.username || "");
      setContact(editUserData?.contact.substr(3, editUserData?.contact.length));
      setEmail(editUserData?.email);
      setPassword(editUserData?.password);
      setUserType(editUserData?.roleId?.toString());
      setHouse(editUserData?.house);
      setTown(editUserData?.town);
      setCity(editUserData?.city);
      setPhase(editUserData?.phase);
      setStreet(editUserData?.street);
      setBlock(editUserData?.block);
      setResType(editUserData?.resType);
      setCnic(editUserData?.cnic);
      setEdit(editUserData?.roleId?.toString());
      dispatch(setEditUserData(null));
    }
  }, [editUserData]);

  const addUser = async () => {
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(lastname)) {
      errorToast("Lastname is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(password)) {
      errorToast("Password is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    dispatch(setIsLoading(true));

    await AddUserAPI(dispatch, {
      firstname,
      lastname,
      roleId: userType,
      password,
      email,
      network,
      contact: "+92" + contact,
      groupId,
      erpUserId: erpUserId?.value,
    })
      .then(async () => {
        await GetUsers(dispatch);
        emptyFields();
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
    dispatch(setIsLoading(false));
  };

  const updateUser = async () => {
    if (IsEmpty(userId)) {
      errorToast("User ID is required");
      return;
    }
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(lastname)) {
      errorToast("Lastname is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }
    if (IsEmpty(network)) {
      errorToast("Network is required");
      return;
    }
    if (IsEmpty(groupId)) {
      errorToast("Group is required");
      return;
    }
    // if (IsEmpty(password)) {
    //   errorToast("Password is required");
    //   return;
    // }
    dispatch(setIsLoading(true));

    await UpdateUsers(dispatch, {
      userId,
      firstname,
      lastname,
      roleId: userType,
      email,
      network,
      contact: "+92" + contact,
      groupId,
      erpUserId: erpUserId?.value,
    })
      .then(async () => {
        await GetUsers(dispatch);
        emptyFields(false);
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });

    dispatch(setIsLoading(false));
  };

  const generateAndSend = async (id) => {
    dispatch(setIsLoading(true));
    if (IsEmpty(id)) {
      errorToast("User Id is required");
      return;
    }
    await GenerateAndSendPassword({ userId: id })
      .then(() => {
        dispatch(setIsLoading(false));
        succesToast("Password sent");
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
        errorToast(e.response?.data?.err);
      });
  };

  const emptyFields = (emptyEdit = true) => {
    setFirstname("");
    setUserId("");
    setErpUserId("");
    setLastname("");
    setUsername("");
    setContact("");
    setEmail("");
    setPassword("");
    setUserType("");
    setHouse("");
    setTown("");
    setCity("");
    setResType("");
    setCnic("");
    setPhase("");
    setStreet("");
    setNetwork("");
    setBlock("");
    setUserId("");
    setGroupId("");
    if (emptyEdit) {
      setEdit(null);
    }
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return (
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  return (
    <>
      <Modal
        title={edit ? "Update System User" : "Add System User"}
        name={"addUser"}
        icon={"fa fa-user-plus"}
      >
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              onClick={() => {
                setEdit(null);
                emptyFields();
              }}
              title="Go Back"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={edit === "11" ? "#listResidents" : "#listUser"}
            ></i>
          </a>
        </div>
        <form>
          <div className="row">
            <div className="col-lg-4">
              <label className="col-form-label">Select User Type:</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              >
                <option value={undefined}></option>

                {/* <option value="13">Security Manager</option> */}
                {user.roleId === 1 ? <option value="2">Admin</option> : null}
                <option value="6">Supervisor</option>
                <option value="7">Operator</option>
                <option value="8">Technician</option>
              </select>
            </div>
          </div>
          {userType ? (
            <>
              {user.groupId === 1 ? (
                <>
                  <hr />
                  <div className="row">
                    <div className="mb-4 col-lg-4">
                      <label className="col-form-label">{"ERP User"}:</label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={userId}
                        isLoading={isLoading}
                        isClearable={true}
                        isSearchable={true}
                        name="color"
                        value={erpUserId}
                        options={erpUsersList}
                        onChange={(e) => setErpUserId(e || null)}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              <div className="row">
                <div className="mb-3 col-lg-3">
                  <MyTextInput
                    name={"Firstname *"}
                    val={firstname}
                    placeholder={""}
                    setVal={setFirstname}
                  />
                </div>
                <div className="mb-3 col-lg-3">
                  <MyTextInput
                    name={"Lastname"}
                    val={lastname}
                    placeholder={""}
                    setVal={setLastname}
                  />
                </div>

                <div className="mb-3 col-lg-3">
                  <MyTextInput
                    name={"Cell Phone # *"}
                    val={contact}
                    placeholder={"3xxxxxxxxxx"}
                    setVal={setContact}
                    max={10}
                    pre={true}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="col-form-label">
                    Select Network Provider*:
                  </label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={network}
                    onChange={(e) => setNetwork(e.target.value)}
                  >
                    <option value=""></option>
                    <option value="zong">Zong</option>
                    <option value="warid">Warid</option>
                    <option value="jazz">Jazz</option>
                    <option value="ufone">Ufone</option>
                    <option value="telenor">Telenor</option>
                    <option value="s-com">S-com</option>
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>

                <div className="mb-3 col-lg-4">
                  <MyTextInput name={"Email"} val={email} setVal={setEmail} />
                </div>
                <div className="col-lg-3">
                  <label className="col-form-label">Select Group*:</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={groupId}
                    onChange={(e) => setGroupId(e.target.value)}
                  >
                    <option value=""></option>
                    {groupList()}
                    {/* <option value="5">Device</option> */}
                  </select>
                </div>
                {edit ? (
                  <div className="mb-3 col-lg-3">
                    <label className="col-form-label">Password:</label>
                    <div className="d-grid">
                      {isLoading ? (
                        <button
                          className="btn btn-primary flex"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          &nbsp; Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            confirmation(userId);
                            // addUser();
                          }}
                        >
                          Generate and send Password
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mb-3 col-lg-4">
                    <MyPasswordInput
                      name={"Password *"}
                      val={password}
                      placeholder={""}
                      setVal={setPassword}
                    />
                  </div>
                )}
              </div>

              <br />
              <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-4" style={{ textAlign: "center" }}>
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      &nbsp; Loading...
                    </button>
                  ) : edit ? (
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        updateUser();
                      }}
                    >
                      Update User
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        addUser();
                      }}
                    >
                      Add User
                    </button>
                  )}
                </div>
              </div>
              <br />
            </>
          ) : (
            <>
              <br />
              <br />
            </>
          )}
        </form>
      </Modal>
    </>
  );
};

export default AddUser;
