import React from "react";
import { useSelector } from "react-redux";
import Mqtt from "../Mqtt/Mqtt";
import FirstLogin from "./FirstLogin/FirstLogin";
import Login from "./Login/Login";
import Main from "./Main/Main";
import TopBar from "./TopBar/TopBar";
import { BASE_URL } from "../config";
import ResetPassword from "./ResetPassword/ResetPassword";
import VerifyEmail from "./VerifyEmail/VerifyEmail";

const Project = (props) => {
  const user = useSelector((state) => state.user.user);

  const changeUI = () => {
    if (user?.isFirstLogin) {
      return (
        <>
          <FirstLogin />
        </>
      );
    } else if (user) {
      return (
        <>
          <Mqtt />  
          <TopBar />
          <Main />
        </>
      );
    } else {
      return <Login />;
    }
  };

  return <>{changeUI()}</>;
};

export default Project;
