import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  erpCustomersList: [],
  erpProjectList: [],
  erpUsersList: [],
  erpTechList: [],
};

export const erpSlice = createSlice({
  name: "erp",
  initialState,
  reducers: {
    setErpCustomersList: (state, action) => {
      state.erpCustomersList = action.payload;
    },
    setErpProjectList: (state, action) => {
      state.erpProjectList = action.payload;
    },
    setErpUsersList: (state, action) => {
      state.erpUsersList = action.payload;
    },
    setErpTechList: (state, action) => {
      state.erpTechList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setErpCustomersList,
  setErpProjectList,
  setErpUsersList,
  setErpTechList,
} = erpSlice.actions;

export default erpSlice.reducer;
