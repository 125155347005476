import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserProfile } from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import { setIsLoading, setUser } from "../../../../../redux/slices/userSlice";

const UpdateProfile = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [roleId, setRoleId] = useState("");
  const [userId, setUserId] = useState("");
  const [contact, setContact] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (user) {
      setFirstname(user.firstname);
      setLastname(user.lastname);
      setContact(user.contact.substr(3, user?.contact?.length));
      setUsername(user.username);
      setEmail(user.email);
      setRoleId(user.roleId);
      setUserId(user.id);
    }
  }, [user]);

  const dispatch = useDispatch();

  const goto = async () => {
    if (IsEmpty(firstname)) {
      errorToast("Firstname is required");
      return;
    }
    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      return;
    }

    dispatch(setIsLoading(true));
    await UpdateUserProfile(dispatch, {
      userId,
      roleId,
      firstname,
      lastname,
      contact: "+92" + contact,
      email,
    })
      .then((res) => {
        const updatedUser = {
          ...user,
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          email: res.data.email,
          username: res.data.username,
          contact: res.data.contact.substr(3, res.data.contact.length),
        };
        dispatch(setUser(updatedUser));
        dispatch(setIsLoading(false));
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  const Empty = () => {
    setContact("");
    setEmail("");
    setFirstname("");
    setLastname("");
    setUsername("");
    setUserId("");
    setRoleId("");
  };

  return (
    <>
      <Modal
        title={"Update Profile"}
        name={"updateProfile"}
        icon={"fa fa-pen-to-square"}
      >
        <div className="row">
          <div className="col-12">
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  <div className="input-form">
                    <label className="col-form-label"> Firstname </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Firstname"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          goto();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-form">
                    <label className="col-form-label"> Lastname </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Lastname"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          goto();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <div className="input-form">
                    <MyTextInput
                      name={"Cell Phone #"}
                      val={contact}
                      placeholder={"3xxxxxxxxxx"}
                      setVal={setContact}
                      max={10}
                      pre={true}
                    />
                  </div>
                </div>
                {/* <div className="col-4">
                  <div className="input-form">
                    <label className="col-form-label"> Username </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          goto();
                        }
                      }}
                    />
                  </div>
                </div> */}
                <div className="col-4">
                  <div className="input-form">
                    <label className="col-form-label"> Email </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          goto();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <br />
                <br />
                <div className="text-center">
                  {isLoading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      &nbsp; Loading...
                    </button>
                  ) : (
                    <button
                      style={{ flex: 1, width: "140px" }}
                      title="Login"
                      className="btn btn-primary"
                      onClick={() => {
                        // setIsLoading(true);
                        goto();
                      }}
                    >
                      {"Update"}
                    </button>
                  )}
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* <pre>{JSON.stringify(props.user, null, 2)}</pre> */}
    </>
  );
};

export default UpdateProfile;
