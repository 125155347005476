import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import MyPasswordInput from "../../../../../components/MyPasswordInput";
import MyTextInput from "../../../../../components/MyTextInput";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  setEditDeviceData,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { v4 as uuidv4 } from "uuid";
import { AddEFIG, GetEFIGList } from "../../../../../actions";

const AddEfig = (props) => {
  const [serial, setSerial] = useState("");

  const isLoading = useSelector((state) => state.user.isLoading);
  const editGroupData = useSelector((state) => state.user.editGroupData);

  const dispatch = useDispatch();

  const Add = async () => {
    if (IsEmpty(serial)) {
      errorToast("EFIG Serial is required");
      return;
    }

    dispatch(setIsLoading(true));

    await AddEFIG(dispatch, {
      espSerial: serial,
    })
      .then(async () => {
        await GetEFIGList(dispatch);
        dispatch(setIsLoading(false));
        setSerial("");
      })
      .catch((e) => {
        dispatch(setIsLoading(false));
      });
  };

  return (
    <>
      <Modal title={"Add EFIG"} name={"addEfig"} icon={"fa-solid fa-plus"}>
        <div className="col-12">
          <a href="#" className="nav-link">
            <i
              style={{ fontSize: "20px" }}
              className="fa fa-light fa-arrow-left"
              title="Go To List"
              data-bs-dismiss="modal"
              aria-label="Close"
              data-bs-toggle="modal"
              data-bs-target={"#listEfigs"}
            ></i>
          </a>
        </div>
        <form>
          <>
            <div className="row">
              <div className="mb-4 col-lg-4">
                <MyTextInput
                  name={"Serial #"}
                  val={serial}
                  placeholder={"XXXXXX"}
                  setVal={setSerial}
                />
              </div>
            </div>

            <br />
            <div className="row">
              <div className="col-4"></div>
              <div className="col-4" style={{ textAlign: "center" }}>
                {isLoading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    &nbsp; Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      Add();
                    }}
                  >
                    Add EFIG
                  </button>
                )}
              </div>
            </div>
            <br />
          </>
        </form>
      </Modal>
    </>
  );
};

export default AddEfig;
