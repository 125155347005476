/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch, useSelector } from "react-redux";

import Modal from "../../../../../components/Modal";
import { URL } from "../../../../../config";
import {
  DeleteComplaintApi,
  GetActiveComplaints,
  SearchComplaint,
} from "../../../../../actions";
import {
  setComplaintDetail,
  setErpComplaintData,
  setIsComplaintLoading,
} from "../../../../../redux/slices/complaintSlice";
import {
  setActionVal,
  setConfirmText,
  setPrevModal,
  setUserDetails,
} from "../../../../../redux/slices/userSlice";
import { globalDeleteBtn } from "../../../../../components/DeleteConfirm";

const ComplaintHistory = (props) => {
  const [name, setName] = useState("");
  const [complaintNumber, setComplaintNumber] = useState("");
  const [complaintType, setComplaintType] = useState("");
  const [address, setAddress] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [tech, setTech] = useState("");
  const [groupId, setGroupId] = useState("");
  const [techId, setTechId] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [deleted, setDeleted] = useState(true);

  const technicians = useSelector((state) => state.user.technicians);
  const operators = useSelector((state) => state.user.operators);
  const supervisors = useSelector((state) => state.user.supervisors);
  const groups = useSelector((state) => state.user.groups);
  const complaintDetail = useSelector(
    (state) => state.complaint.complaintDetail
  );
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);

  const user = useSelector((state) => state.user.user);

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  const complaintList = useSelector((state) => state.complaint.complaintList);

  const search = async (del = false) => {
    dispatch(setIsComplaintLoading(true));
    await SearchComplaint(dispatch, {
      customerName: name,
      complaintNumber,
      complaintType,
      address,
      techId,
      operatorId,
      groupId,
      startTime,
      endTime,
      deleted: !deleted,
    });
    dispatch(setIsComplaintLoading(false));
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return (
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  const techListRow = () => {
    return technicians.map((val, index) => {
      return (
        <option key={index} value={val.id}>{`${val?.firstname || ""} ${
          val?.lastname || ""
        }`}</option>
      );
    });
  };
  const operatorListRow = () => {
    const data = [...operators, ...supervisors];
    return data.map((val, index) => {
      return (
        <option key={index} value={val.id}>{`${val?.firstname || ""} ${
          val?.lastname || ""
        }`}</option>
      );
    });
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "searchCmpDel") {
      deleteComplaintAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const deleteComplaint = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Complaint?",
        type: "searchCmpDel",
        id,
      })
    );
    globalDeleteBtn.click();
  };

  const deleteComplaintAction = async (id) => {
    await DeleteComplaintApi(dispatch, { complaintId: id });
    await search();
    await GetActiveComplaints(dispatch);
    // await search();
  };

  const getList = () => {
    let count = 0;
    return complaintList?.map((val, index) => {
      count++;
      return (
        <Fragment key={index}>
          <tr
            style={{
              textDecorationLine: val.deleted ? "line-through" : "none",
            }}
            title={`${val.deleted ? "deleted" : ""}`}
          >
            <th scope="row">{count}</th>

            <td>
              <a
                href="#"
                data-bs-dismiss="modal"
                aria-label="Close"
                // data-bs-toggle="modal"
                // data-bs-target={"#complaintDetails"}
                onClick={() => {
                  dispatch(setComplaintDetail(val));
                  // if (complaintDetail?.id !== val.id) {
                  dispatch(setErpComplaintData(null));
                  // }
                }}
              >
                {`${val.complaintNumber}`}
              </a>
            </td>
            <td>{`${val.title}`}</td>
            <td>
              {val.isErp ? (
                <>{val.erpCustomer?.fullname || ""}</>
              ) : (
                <>
                  <a
                    href="#"
                    className="nav-link"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target={"#customerDetails"}
                    style={{ color: "blue" }}
                    onClick={() => {
                      dispatch(setUserDetails(val.user));
                      dispatch(setPrevModal("complaintHistory"));
                    }}
                  >
                    {`${val.user?.customerFirstname || ""} ${
                      val.user?.customerLastname || ""
                    }`}
                  </a>
                </>
              )}
            </td>
            <td>
              {val.isErp ? (
                <>{val?.erpProject?.address}</>
              ) : (
                <>{`${val.energizer?.address || ""}`}</>
              )}
            </td>

            <td>
              {new Date(val.createdAt).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </td>
            <td>{`${val.operator?.firstname || ""} ${
              val.operator?.lastname || ""
            }`}</td>
            <td>{`${val.tech?.firstname || ""} ${
              val.tech?.lastname || ""
            }`}</td>
            <td>{val?.efigGroup?.name || ""}</td>
            <td>
              {val.isErp ? (
                <>
                  <img
                    src={require("../../../../../assets/d.png")}
                    width="32"
                    height="32"
                    alt=""
                    title={"ERP Complaint"}
                  />
                  <span>
                    <i
                      style={{
                        fontSize: "24px",
                        color: "red",
                        marginLeft: "10px",
                        cursor:
                          user.roleId === 1 || user?.accessControl?.addComplaint
                            ? "pointer"
                            : "",
                        opacity:
                          user.roleId === 1 || user?.accessControl?.addComplaint
                            ? 1
                            : 0.6,
                      }}
                      className="fa fa-light fa-trash"
                      onClick={() => {
                        if (
                          user.roleId === 1 ||
                          user?.accessControl?.addComplaint
                        ) {
                          deleteComplaint(val.id);
                        }
                      }}
                      title="Delete"
                    ></i>
                  </span>
                </>
              ) : (
                <img
                  src={require("../../../../../assets/e.png")}
                  width="32"
                  height="32"
                  alt=""
                  title={"EFIG Complaint"}
                />
              )}
            </td>
          </tr>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Search Complaints"}
        name={"complaintHistory"}
        icon={"fa fa-solid fa-file"}
      >
        <div className="row">
          <div className="col-4">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add Device"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addComplaint"}
              ></i>
            </a>
          </div>
          <div className="col-4">
            <div className="text-center">
              <h6>{`Total Records: ${complaintList?.length}`}</h6>
            </div>
          </div>
          <div className="col-4">
            <div className="form-check float-end">
              <input
                onKeyDown={handleKeyDown}
                className="form-check-input"
                type="checkbox"
                value={deleted}
                id="flexCheckChecked"
                onChange={(e) => {
                  setDeleted(!deleted);
                  // search(deleted);
                }}
              />
              <label className="form-check-label" for="flexCheckChecked">
                Deleted
              </label>
            </div>
          </div>

          {/* <div className="col-9">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add User"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addUser"}
              ></i>
            </a>
          </div>
          <div className="col-1">
            <div className="float-end">
              <div
                className=""
                style={{
                  width: "80px",
                  backgroundColor: "#d1e7dd",
                  padding: "2px 13px",
                  borderRadius: "5px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Online
              </div>
            </div>
          </div>
          <div className="col-1">
            <div className="float-end">
              <div
                style={{
                  width: "80px",
                  backgroundColor: "#F7D7DA",
                  padding: "2px 13px",
                  borderRadius: "5px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Offline
              </div>
            </div>
          </div>
          <div className="col-1">
            <div className="float-end">
              <div
                style={{
                  width: "80px",
                  backgroundColor: "#FCF2CD",
                  padding: "2px 13px",
                  borderRadius: "5px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Busy
              </div>
            </div>
          </div> */}
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      placeholder={"Complaint #"}
                      value={complaintNumber}
                      onChange={(e) => setComplaintNumber(e.target.value)}
                    />
                    <label className="col-form-label">Complaint # </label>
                  </th>
                  <th>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={complaintType}
                      onChange={(e) => setComplaintType(e.target.value)}
                    >
                      <option value={""}></option>
                      <option value="Fence Alarm">Fence Alarm</option>
                      <option value="Wire Break">Wire Break</option>
                      <option value="Wire Loose">Wire Loose</option>
                      <option value="Efig Problem">Efig Problem</option>
                      <option value="Other">Other</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label"> Type</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      placeholder={"Customer "}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Customer</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={address}
                      placeholder={"Site Address"}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="col-form-label">Site Address</label>
                  </th>

                  <th>
                    {/* <div className="row"> */}
                    <div className="col-12">
                      <DatePicker
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={startTime}
                        onChange={(date) => setStartTime(date)}
                        showTimeSelect
                        dateFormat="yyyy-MM-dd hh:mm:ss"
                        isClearable
                        withPortal
                      />
                    </div>
                    <div className="col-12">
                      <DatePicker
                        placeholderText="End Date"
                        className="form-control form-control-sm"
                        selected={endTime}
                        onChange={(date) => setEndTime(date)}
                        showTimeSelect
                        dateFormat="yyyy-MM-dd hh:mm:ss"
                        isClearable
                        withPortal
                      />
                    </div>
                    {/* </div> */}
                    <label className="col-form-label">Generated At</label>
                  </th>
                  <th style={{ width: "120px" }}>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={operatorId}
                      onChange={(e) => setOperatorId(e.target.value)}
                    >
                      <option value=""></option>
                      {operatorListRow()}
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Operator</label>
                  </th>
                  <th style={{ width: "120px" }}>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={techId}
                      onChange={(e) => setTechId(e.target.value)}
                    >
                      <option value=""></option>
                      {techListRow()}
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Technician</label>
                  </th>
                  <th style={{ width: "120px" }}>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={groupId}
                      onChange={(e) => setGroupId(e.target.value)}
                    >
                      <option value=""></option>
                      {groupList()}
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Group</label>
                  </th>
                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                        style={{
                          width: "80px",
                        }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        style={{
                          width: "80px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>
              <tbody style={{ height: complaintList?.length ? "" : "58vh" }}>
                {getList()}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ComplaintHistory;
