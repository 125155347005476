import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteGroupAPI,
  GetAllGroups,
  SearchGroups,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyTextInput from "../../../../../components/MyTextInput";
import {
  setActionVal,
  setConfirmText,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { globalDeleteBtn } from "../../../../../components/DeleteConfirm";

const ListGroup = (props) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  const searchedGroups = useSelector((state) => state.user.searchedGroups);
  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  useEffect(() => {
    // search();
    GetAllGroups(dispatch);
  }, []);

  const search = async () => {
    dispatch(setIsLoading(true));
    await SearchGroups(dispatch, {
      name,
    });
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "group") {
      deleteGroupAction(actionVal.id);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  const deleteGroup = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this Group?",
        type: "group",
        id,
      })
    );
    globalDeleteBtn.click();
  };

  const deleteGroupAction = async (id) => {
    await DeleteGroupAPI(dispatch, { groupId: id });
    await GetAllGroups(dispatch);
    // await search();
  };

  const getList = () => {
    let count = 0;
    return searchedGroups?.map((val, index) => {
      count++;
      return (
        <tr key={index}>
          <th scope="row">{count}</th>
          <td>{`${val.name}`}</td>
          <td>
            {new Date(val.createdAt).toLocaleDateString("en-us", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </td>

          <td>
            <a className={`nav-link`} href="#">
              <i
                style={{
                  fontSize: "20px",
                  color: "blue",
                }}
                className="fa fa-light fa-pencil"
                onClick={() => {
                  dispatch(setEditGroupData(val));
                }}
                title="Edit"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target="#addGroup"
              ></i>

              {val.id != 1 ? (
                <i
                  style={{
                    fontSize: "20px",
                    color: "red",
                    marginLeft: "10px",
                  }}
                  className="fa fa-light fa-trash"
                  onClick={() => deleteGroup(val.id)}
                  title="Delete"
                ></i>
              ) : null}
            </a>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Group List"}
        name={"listGroups"}
        icon={"fa-solid fa-people-roof"}
      >
        <div className="row">
          <div className="col-12">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add Device"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addGroup"}
              ></i>
            </a>
          </div>
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={name}
                      placeholder={"Name"}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Name</label>
                  </th>
                  <th>
                    <input
                      type={"text"}
                      className="form-control form-control-sm"
                      //   value={name}
                      placeholder={"Created At"}
                      //   onChange={(e) => setName(e.target.value)}
                      disabled={true}
                    />
                    <label className="col-form-label">Created At</label>
                  </th>

                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                        style={{
                          width: "80px",
                        }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        style={{
                          width: "80px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>

              <tbody>{getList()}</tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListGroup;
