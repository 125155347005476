/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";

import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import { FetchComplaintChatSingle } from "../../../../../actions";
import { setComplaintChatSingle } from "../../../../../redux/slices/chatSlice";
import { setImageDetail } from "../../../../../redux/slices/userSlice";
import { URL } from "../../../../../config";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  setComplaintDetail,
  setIsComplaintLoading,
} from "../../../../../redux/slices/complaintSlice";

var arr = [];

const ComplaintDetails = (props) => {
  const dispatch = useDispatch();
  const [alarmStr, setAlarmStr] = useState(false);
  const isComplaintLoading = useSelector(
    (state) => state.complaint.isComplaintLoading
  );
  const complaintDetail = useSelector(
    (state) => state.complaint.complaintDetail
  );

  useEffect(() => {
    let alarm = "";
    if (complaintDetail?.fenceAlarm) {
      alarm += "Fence Alarm ";
    }
    if (complaintDetail?.acFail) {
      alarm += "AC Fail ";
    }
    if (complaintDetail?.tamperAlarm) {
      alarm += "Tamper Alarm";
    }

    setAlarmStr(alarm);
  }, [complaintDetail]);

  return (
    <>
      <Modal
        title={"Complaint Details"}
        name={"complaintDetails"}
        icon={"fa fa-clock-rotate-left"}
      >
        {complaintDetail ? (
          <>
            <div style={{ padding: "5px" }} className="">
              {/* <div className="row">
                  <div className="col-lg-12">
                    <a
                      href="#"
                      className="nav-link float-end"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setComplaintDetail(null));
                      }}
                    >
                      <i
                        style={{ fontSize: "26px", color: "red" }}
                        className="fa fa-light fa-close"
                      ></i>
                    </a>
                  </div>
                </div> */}
              <div className="row">
                <div
                  className="col-6"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  Customer Details:
                </div>
                <div
                  className="col-6"
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    textAlign: "center",
                  }}
                >
                  Complaint Details:
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <table className="table table-hover">
                    <tbody>
                      <tr>
                        <th scope="row">Name:</th>
                        <td>{`${
                          complaintDetail?.user?.customerFirstname ||
                          complaintDetail?.erpCustomer?.fullname ||
                          ""
                        } ${
                          complaintDetail?.user?.customerLastname || ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Address: </th>
                        <td>{`${
                          complaintDetail?.user?.address ||
                          complaintDetail?.erpCustomer?.address ||
                          ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Contact #: </th>
                        <td>{`${
                          complaintDetail?.user?.contact ||
                          complaintDetail?.erpCustomer?.contact ||
                          ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Site Address:</th>
                        <td>{`${
                          complaintDetail?.energizer?.address ||
                          complaintDetail?.erpProject?.address ||
                          ""
                        }`}</td>
                      </tr>
                    </tbody>
                  </table>
                  {!complaintDetail?.isErp ? (
                    <>
                      <div
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        Energizer Status{" "}
                      </div>
                      <table className="table table-hover">
                        <tbody>
                          <tr>
                            <th scope="row">Alarm:</th>
                            <td>{`${alarmStr || "None"}`}</td>
                          </tr>
                          <tr>
                            <th scope="row">Internet: </th>
                            <td>{`${
                              complaintDetail?.energizer?.internet
                                ? "Connected"
                                : "Disconnected"
                            }`}</td>
                          </tr>
                          <tr>
                            <th scope="row">Energizer State: </th>
                            <td>{`${
                              complaintDetail?.energizer?.energizerState
                                ? "Armed"
                                : "Disarmed"
                            }`}</td>
                          </tr>
                          <tr>
                            <th scope="row">Battery Volts:</th>
                            <td>{`${
                              complaintDetail?.energizer?.batteryVolts || ""
                            } V`}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  ) : null}
                </div>
                <div className="col-lg-6">
                  <table className="table table-hover">
                    <tbody>
                      <tr>
                        <th scope="row">Complaint #:</th>
                        <td>{`${complaintDetail?.complaintNumber || ""}`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Ticket Ref:</th>
                        <td>{`${complaintDetail?.complaintNumber || ""}`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Complaint Type: </th>
                        <td>{`${complaintDetail?.title || ""}`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Description:</th>
                        <td>{`${
                          complaintDetail?.des ||
                          complaintDetail.jsonData?.message ||
                          ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Status:</th>
                        <td>
                          {complaintDetail?.state == 1
                            ? "In Query"
                            : complaintDetail?.state == 2
                            ? "Attended"
                            : complaintDetail?.state == 3
                            ? "Assigned"
                            : complaintDetail?.state == 4
                            ? "On Hold"
                            : complaintDetail?.state == 5
                            ? "Close by customer"
                            : complaintDetail?.state == 6
                            ? "Cancelled"
                            : complaintDetail?.state == 7
                            ? "Discarded"
                            : complaintDetail?.state == 8
                            ? "Resolved"
                            : "Discarded"}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Created At:</th>
                        <td>
                          {new Date(
                            complaintDetail?.createdAt
                          ).toLocaleDateString("en-us", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Operator:</th>
                        <td>{`${complaintDetail?.operator?.firstname || ""} ${
                          complaintDetail?.operator?.lastname || ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Technician:</th>
                        <td>{`${
                          complaintDetail.tech?.firstname ||
                          complaintDetail?.erpUser?.firstname ||
                          ""
                        } ${
                          complaintDetail.tech?.lastname ||
                          complaintDetail.erpUser?.lastname ||
                          ""
                        }`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-6"></div>
              </div>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default ComplaintDetails;
