import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllGroups } from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyTextInput from "../../../../../components/MyTextInput";
import {
  setActionVal,
  setConfirmText,
  setEditGroupData,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { api } from "../../../../../actions/apiClient";
import { errorToast } from "../../../../../helpers/toasts";
import { URL } from "../../../../../config";

const EfficiencyReport = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [complaintType, setComplaintType] = useState("");

  const [cancelledCivil, setCancelledCivil] = useState([]);
  const [cancelledElect, setCancelledElect] = useState([]);
  const [cancelledPlum, setCancelledPlum] = useState([]);
  const [cancelledSani, setCancelledSani] = useState([]);
  const [cancelledMisc, setCancelledMisc] = useState([]);

  const [discardCivil, setDiscardCivil] = useState([]);
  const [discardElect, setDiscardElect] = useState([]);
  const [discardPlum, setDiscardPlum] = useState([]);
  const [discardSani, setDiscardSani] = useState([]);
  const [discardMisc, setDiscardMisc] = useState([]);

  const [resolvedCivil, setResolvedCivil] = useState([]);
  const [resolvedElect, setResolvedElect] = useState([]);
  const [resolvedPlum, setResolvedPlum] = useState([]);
  const [resolvedSani, setResolvedSani] = useState([]);
  const [resolvedMisc, setResolvedMisc] = useState([]);

  const [totalTimeCivil, setTotalTimeCivil] = useState([]);
  const [totalTimeElec, setTotalTimeElec] = useState([]);
  const [totalTimePlum, setTotalTimePlum] = useState([]);
  const [totalTimeSani, setTotalTimeSani] = useState([]);
  const [totalTimeMisc, setTotalTimeMisc] = useState([]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const search = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const url = URL + "complaint/efficiencyReport";
    await api
      .post(
        url,
        { type: complaintType, startTime, endTime },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setCancelledCivil(data.cancelledCivil);
        setCancelledElect(data.cancelledElect);
        setCancelledSani(data.cancelledSani);
        setCancelledPlum(data.cancelledPlum);
        setCancelledMisc(data.cancelledMisc);

        setDiscardCivil(data.discardedCivil);
        setDiscardElect(data.discardedElect);
        setDiscardSani(data.discardedSani);
        setDiscardPlum(data.discardedPlum);
        setDiscardMisc(data.discardedMisc);

        setResolvedCivil(data.resolvedCivil);
        setResolvedElect(data.resolvedElect);
        setResolvedSani(data.resolvedSani);
        setResolvedPlum(data.resolvedPlum);
        setResolvedMisc(data.resolvedMisc);

        setTotalTimeCivil(data.totalTimeCivil);
        setTotalTimeElec(data.totalTimeElec / data.resolvedElect.length);
        setTotalTimeSani(data.totalTimeSani);
        setTotalTimePlum(data.totalTimePlum);
        setTotalTimeMisc(data.totalTimeMisc);

        setIsLoading(false);
        console.log("asdsadsadasdasdas", data);
      })
      .catch((e) => {
        setIsLoading(false);
        errorToast(e.response.data.err);
      });
  };

  const getElectricRow = () => {
    return (
      <tr key={1}>
        {/* <th scope="row">{count + 1}</th> */}
        <td>{`Electrical`}</td>
        <td>{`${Math.floor(totalTimeElec / 3600)} hrs ${Math.floor(
          (totalTimeElec % 3600) / 60
        )} min`}</td>
        {/* <td>{`${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(11, 13)} hour${
          new Date(totalTimeElec * 1000).toISOString().substring(11, 13) > 1
            ? "s"
            : ""
        }  ${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(14, 16)} min`}</td> */}
        <td>
          {resolvedElect?.length +
            cancelledElect?.length +
            discardElect?.length}
        </td>
        <td>{resolvedElect?.length}</td>
        <td>{cancelledElect?.length}</td>
        <td>{discardElect?.length}</td>
      </tr>
    );
  };

  const getCivilRow = () => {
    return (
      <tr key={1}>
        {/* <th scope="row">{count + 1}</th> */}
        <td>{`Civil`}</td>
        <td>{`${Math.floor(totalTimeCivil / 3600)} hrs ${Math.floor(
          (totalTimeCivil % 3600) / 60
        )} min`}</td>
        {/* <td>{`${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(11, 13)} hour${
          new Date(totalTimeElec * 1000).toISOString().substring(11, 13) > 1
            ? "s"
            : ""
        }  ${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(14, 16)} min`}</td> */}
        <td>
          {resolvedCivil?.length +
            cancelledCivil?.length +
            discardCivil?.length}
        </td>
        <td>{resolvedCivil?.length}</td>
        <td>{cancelledCivil?.length}</td>
        <td>{discardCivil?.length}</td>
      </tr>
    );
  };

  const getSanitaryRow = () => {
    return (
      <tr key={1}>
        {/* <th scope="row">{count + 1}</th> */}
        <td>{`Sanitary`}</td>
        <td>{`${Math.floor(totalTimeSani / 3600)} hrs ${Math.floor(
          (totalTimeSani % 3600) / 60
        )} min`}</td>
        {/* <td>{`${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(11, 13)} hour${
          new Date(totalTimeElec * 1000).toISOString().substring(11, 13) > 1
            ? "s"
            : ""
        }  ${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(14, 16)} min`}</td> */}
        <td>
          {resolvedSani?.length + cancelledSani?.length + discardSani?.length}
        </td>
        <td>{resolvedSani?.length}</td>
        <td>{cancelledSani?.length}</td>
        <td>{discardSani?.length}</td>
      </tr>
    );
  };

  const getPlumbRow = () => {
    return (
      <tr key={1}>
        {/* <th scope="row">{count + 1}</th> */}
        <td>{`Plumbing`}</td>
        <td>{`${Math.floor(totalTimePlum / 3600)} hrs ${Math.floor(
          (totalTimePlum % 3600) / 60
        )} min`}</td>
        {/* <td>{`${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(11, 13)} hour${
          new Date(totalTimeElec * 1000).toISOString().substring(11, 13) > 1
            ? "s"
            : ""
        }  ${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(14, 16)} min`}</td> */}
        <td>
          {resolvedPlum?.length + cancelledPlum?.length + discardPlum?.length}
        </td>
        <td>{resolvedPlum?.length}</td>
        <td>{cancelledPlum?.length}</td>
        <td>{discardPlum?.length}</td>
      </tr>
    );
  };

  const getMiscRow = () => {
    return (
      <tr key={1}>
        {/* <th scope="row">{count + 1}</th> */}
        <td>{`Misc`}</td>
        <td>{`${Math.floor(totalTimeMisc / 3600)} hrs ${Math.floor(
          (totalTimeMisc % 3600) / 60
        )} min`}</td>
        {/* <td>{`${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(11, 13)} hour${
          new Date(totalTimeElec * 1000).toISOString().substring(11, 13) > 1
            ? "s"
            : ""
        }  ${new Date(totalTimeElec * 1000)
          .toISOString()
          .substring(14, 16)} min`}</td> */}
        <td>
          {resolvedMisc?.length + cancelledMisc?.length + discardMisc?.length}
        </td>
        <td>{resolvedMisc?.length}</td>
        <td>{cancelledMisc?.length}</td>
        <td>{discardMisc?.length}</td>
      </tr>
    );
    // ) : null;
  };

  return (
    <>
      <Modal
        title={"Efficiency Report"}
        name={"efficiencyReport"}
        icon={"fa fa-file"}
      >
        <div className="row">
          {/* <div className="col-3">
            <select
              className="form-select form-select-sm"
              aria-label="Default select example"
              value={complaintType}
              onChange={(e) => setComplaintType(e.target.value)}
            >
              <option value={""}>Select Department</option>
              <option value="1">Electrical Dept.</option>
              <option value="2">Civil Dept.</option>
              <option value="3">Sanitary Dept.</option>
              <option value="4">Plumbing Dept.</option>
              <option value="5">Misc </option>
            </select>
          </div> */}
          <div className="col-lg-2">
            <DatePicker
              placeholderText="Start Date"
              className="form-control form-control-sm"
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div className="col-lg-2  ">
            <DatePicker
              placeholderText="End Date"
              className="form-control form-control-sm"
              selected={endTime}
              onChange={(date) => setEndTime(date)}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div className="col-3">
            <div style={{ width: "80px" }}>
              {isLoading ? (
                <button
                  className="btn btn-sm btn-primary"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    search();
                  }}
                >
                  <i
                    style={{
                      fontSize: "14px",
                      width: "40px",
                      textAlign: "center",
                    }}
                    title="Search"
                    className="fa fa-light fa-magnifying-glass"
                  ></i>
                </button>
              )}
            </div>
          </div>
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  {/* <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th> */}
                  <th>
                    <label className="col-form-label">Department</label>
                  </th>
                  <th>
                    <label className="col-form-label">Avg. response time</label>
                  </th>

                  <th>
                    <label className="col-form-label">Total</label>
                  </th>
                  <th>
                    <label className="col-form-label">Addressed</label>
                  </th>
                  <th>
                    <label className="col-form-label">Cancelled</label>
                  </th>
                  <th>
                    <label className="col-form-label">
                      Closed By Supervisor
                    </label>
                  </th>
                </tr>
              </thead>

              <tbody style={{ height: "65vh" }}>
                <>{getElectricRow()}</>
                <>{getCivilRow()}</>
                <>{getSanitaryRow()}</>
                <>{getPlumbRow()}</>
                <>{getMiscRow()}</>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EfficiencyReport;
