/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteAnnouncement,
  GetAnnouncements,
  SearchAnnouncements,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import { URL } from "../../../../../config";
import {
  setActionVal,
  setConfirmText,
  setIsLoading,
} from "../../../../../redux/slices/userSlice";
import { globalDeleteBtn } from "../../../../../components/DeleteConfirm";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const ListAnn = (props) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const announcements = useSelector((state) => state.user.announcements);
  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);
  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);

  const dispatch = useDispatch();

  useEffect(() => {
    GetAnnouncements(dispatch);
  }, []);

  const search = async () => {
    dispatch(setIsLoading(true));
    await SearchAnnouncements(dispatch, {
      title,
      body,
    });
    dispatch(setIsLoading(false));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "ann") {
      deleteAction(actionVal.id);
      dispatch(setActionVal(null));
    }
    // alert(actionVal.action);
  }, [actionVal]);

  const deleteAnn = async (id) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to delete this announcement?",
        type: "ann",
        id,
      })
    );
    globalDeleteBtn.click();
  };

  const deleteAction = async (id) => {
    await DeleteAnnouncement(dispatch, { id });
    await search();
  };

  const getList = () => {
    let count = 0;
    return announcements?.map((val, index) => {
      // console.log(`${URL}upload/${val.attachment}`);
      count++;
      return (
        <Fragment key={index}>
          <tr>
            <th scope="row">{count}</th>
            <td>{`${val?.title}`}</td>
            {/* <td></td> */}
            <td>
              <div className={val?.body?.length > 100 ? `body_${val?.id}` : ""}>
                {" "}
                {`${val?.body.substring(0, 100)}`}{" "}
                {val?.body?.length > 100 ? "..." : ""}
              </div>
            </td>
            <td>
              <div className={`video_${val?.id}`}>
                <a
                  href={val?.videoLink}
                  target="_blank"
                >{`${val?.videoTitle}`}</a>
              </div>
            </td>
            <td>
              <div className={`web_${val?.id}`}>
                <a href={val?.webLink} target="_blank">{`${val?.webTitle}`}</a>
              </div>
            </td>
            <td>
              {new Date(val.createdAt).toLocaleDateString("en-us", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </td>
            <td>{`${val.creator?.firstname || ""} ${
              val.creator?.lastname || ""
            }`}</td>

            <td>
              <a
                className={`nav-link ${
                  user.roleId === 1 || user?.accessControl?.addAnn
                    ? ""
                    : "disabled"
                }`}
                href="#"
                style={{
                  marginTop: val.attachment ? "24px" : "0px",
                  opacity:
                    user.roleId === 1 || user?.accessControl?.addAnn ? 1 : 0.6,
                }}
              >
                {/* <i
                  style={{
                    fontSize: "20px",
                    color: "slateblue",
                    marginLeft: "10px",
                  }}
                  className="fa fa-light fa-pencil"
                  onClick={() => alert(val.id)}
                  title="Edit"
                ></i> */}
                <i
                  style={{ fontSize: "20px", color: "red", marginLeft: "10px" }}
                  className="fa fa-light fa-trash"
                  onClick={() => deleteAnn(val.id)}
                  title="Delete"
                ></i>
              </a>
            </td>
          </tr>
          <Tooltip anchorSelect={`.body_${val?.id}`} place="right">
            <div style={{ maxWidth: "400px" }}>{val?.body || ""}</div>
            {/* <div>Site Address: {val.energizer?.address || ""}</div> */}
          </Tooltip>
          <Tooltip anchorSelect={`.video_${val?.id}`} place="top">
            <div>{val?.videoLink || ""}</div>
            {/* <div>Site Address: {val.energizer?.address || ""}</div> */}
          </Tooltip>
          <Tooltip anchorSelect={`.web_${val?.id}`} place="top">
            <div>{val?.webLink || ""}</div>
            {/* <div>Site Address: {val.energizer?.address || ""}</div> */}
          </Tooltip>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Modal
        title={"Announcements List"}
        name={"listAnnouncements"}
        icon={"fa fa-list"}
      >
        <div style={{ height: "78vh" }}>
          <div className="row">
            <div className="col-12">
              <a href="#" className="nav-link">
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-light fa-arrow-left"
                  title="Go To Add Announcement"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target={"#createAnnouncements"}
                ></i>
              </a>
            </div>
            <div className="col-12">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ width: "30px" }}>
                      <label className="col-form-label">#</label>
                    </th>
                    <th>
                      <input
                        onKeyDown={handleKeyDown}
                        type={"text"}
                        className="form-control form-control-sm"
                        value={title}
                        placeholder={""}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <label className="col-form-label">Title</label>
                    </th>
                    <th>
                      <input
                        onKeyDown={handleKeyDown}
                        type={"text"}
                        className="form-control form-control-sm"
                        value={body}
                        placeholder={""}
                        onChange={(e) => setBody(e.target.value)}
                      />
                      <label className="col-form-label">Body</label>
                    </th>
                    <th>
                      <input
                        onKeyDown={handleKeyDown}
                        type={"text"}
                        className="form-control form-control-sm"
                        // value={body}
                        placeholder={""}
                        disabled={true}
                        // onChange={(e) => setBody(e.target.value)}
                      />
                      <label className="col-form-label">Video Info</label>
                    </th>
                    <th>
                      <input
                        onKeyDown={handleKeyDown}
                        type={"text"}
                        className="form-control form-control-sm"
                        // value={body}
                        placeholder={""}
                        disabled={true}
                        // onChange={(e) => setBody(e.target.value)}
                      />
                      <label className="col-form-label">Web Info</label>
                    </th>
                    <th>
                      <input
                        onKeyDown={handleKeyDown}
                        type={"text"}
                        className="form-control form-control-sm"
                        // value={body}
                        placeholder={""}
                        disabled={true}
                        // onChange={(e) => setBody(e.target.value)}
                      />
                      <label className="col-form-label">Created At</label>
                    </th>
                    <th>
                      <input
                        onKeyDown={handleKeyDown}
                        type={"text"}
                        className="form-control form-control-sm"
                        // value={body}
                        placeholder={""}
                        disabled={true}
                        // onChange={(e) => setBody(e.target.value)}
                      />
                      <label className="col-form-label">Created By</label>
                    </th>
                    {/* <th>
                    <input
                                          onKeyDown={handleKeyDown}

                      type={"text"}
                      className="form-control form-control-sm"
                      value={body}
                      placeholder={""}
                      // onChange={(e) => }
                      disabled={true}
                    />
                    <label className="col-form-label">Attachment</label>
                  </th> */}

                    <th style={{ width: "80px" }}>
                      {isLoading ? (
                        <button
                          className="btn btn-sm btn-primary"
                          type="button"
                          disabled
                          style={{
                            width: "80px",
                          }}
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          style={{
                            width: "80px",
                          }}
                          className="btn btn-sm btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            search();
                          }}
                        >
                          <i
                            style={{
                              fontSize: "14px",

                              textAlign: "center",
                            }}
                            title="Search"
                            className="fa fa-light fa-magnifying-glass"
                          ></i>
                        </button>
                      )}
                      <label className="col-form-label">Action</label>
                    </th>
                  </tr>
                </thead>

                <tbody>{getList()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListAnn;
