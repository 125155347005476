/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, createRef } from "react";

import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  ActiveEnergizer,
  BlockEnergizer,
  GetESPLocation,
  SearchEFIG,
  UnBlockEnergizer,
  UpdateFW,
} from "../../../../../actions";
import {
  setImageDetail,
  setPrevModal,
} from "../../../../../redux/slices/userSlice";
import { setMarkerLocation } from "../../../../../redux/slices/mapSlice";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  setComplaintDetail,
  setIsComplaintLoading,
} from "../../../../../redux/slices/complaintSlice";
import {
  setEfig,
  setEfigLoading,
  setUpdate,
} from "../../../../../redux/slices/efigSlice";
import MyTextInput from "../../../../../components/MyTextInput";

var arr = [];

const EfigDetails = (props) => {
  const dispatch = useDispatch();
  const [alarmStr, setAlarmStr] = useState(false);
  const [fw, setFw] = useState("");
  const [filePath, setFilePath] = useState("");
  const [hw, setHw] = useState("");

  const modalRef = useRef();

  const userDetails = useSelector((state) => state.user.userDetails);
  // const efig = useSelector((state) => state.efig.efig);
  const efig = useSelector((state) => state.efig.efig);
  const prevModal = useSelector((state) => state.user.prevModal);
  const efigLoading = useSelector((state) => state.efig.efigLoading);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    let alarm = "";
    if (efig?.fenceAlarm) {
      alarm += "Fence Alarm ";
    }
    if (efig?.acFail) {
      alarm += "AC Fail ";
    }
    if (efig?.tamperAlarm) {
      alarm += "Tamper Alarm";
    }
    if (efig?.lowPower) {
      alarm += "Low Power Mode";
    }

    setAlarmStr(alarm);
  }, [efig]);

  useEffect(() => {
    dispatch(setEfig(efig));
  }, [props]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.addEventListener("hidden.bs.modal", function (event) {
        dispatch(setEfig(null));
      });
      return modalRef.current.removeEventListener(
        "hidden.bs.modal",
        function (e) {
          // alert("removed");
        }
      );
    }
  }, [modalRef]);

  const updateEnergizerState = async (engz) => {
    if (!engz.isValid) {
      await UnBlockEnergizer({ energizerId: engz.id })
        .then((res) => {
          succesToast("Energizer Unblocked.");
          dispatch(setEfig({ ...efig, isValid: 1 }));
        })
        .catch((e) => {
          errorToast(e?.response?.data?.err || "Error");
        });
    } else {
      await BlockEnergizer({ energizerId: engz.id })
        .then((res) => {
          succesToast("Energizer blocked.");
          dispatch(setEfig({ ...efig, isValid: 0 }));
        })
        .catch((e) => {
          errorToast(e?.response?.data?.err || "Error");
        });
    }
  };

  const search = async () => {
    await SearchEFIG(dispatch, {});
  };

  const getLocation = async () => {
    await GetESPLocation(dispatch, { energizerId: efig?.id });
  };

  const activate = async () => {
    await ActiveEnergizer({ energizerId: efig.id })
      .then((res) => {
        succesToast("Energizer Activated.");
        search();
        dispatch(setEfig({ ...efig, active: 1 }));
      })
      .catch((e) => {
        errorToast(e?.response?.data?.err || "Error");
      });
  };

  const updateFW = async () => {
    if (IsEmpty(fw)) {
      errorToast("Please provide firmware");
      return;
    }
    if (IsEmpty(hw)) {
      errorToast("Please provide Hardware #");
      return;
    }
    if (IsEmpty(filePath)) {
      errorToast("File Path is required");
      return;
    }

    dispatch(setEfigLoading(true));
    await UpdateFW(dispatch, {
      firmware: fw,
      energizerId: efig.id,
      hardware: hw,
      url: filePath,
    });
    setFw("");
    setHw("");
    setFilePath("");
    dispatch(setEfigLoading(false));
  };

  return (
    <>
      <Modal
        _ref={modalRef}
        title={"EFIG Details"}
        name={"efigDetails"}
        icon={"fa fa-clock-rotate-left"}
      >
        {efig ? (
          <>
            <div className="col-12">
              <a href="#" className="nav-link">
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-light fa-arrow-left"
                  title="Go To List"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target={prevModal ? `#${prevModal}` : "#listEfigs"}
                  onClick={() => {
                    dispatch(setPrevModal(null));
                    dispatch(setEfig(null));
                  }}
                ></i>
              </a>
            </div>
            <div style={{ padding: "5px" }} className="">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    Customer Details:
                  </div>
                  <table className="table table-hover">
                    <tbody>
                      <tr>
                        <th scope="row">Customer Name:</th>
                        <td>{`${
                          efig?.user?.customerFirstname ||
                          userDetails?.customerFirstname ||
                          ""
                        } ${
                          efig?.user?.customerLastname ||
                          userDetails?.customerLastname ||
                          ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Device Admin:</th>
                        <td>{`${
                          efig?.user?.firstname || userDetails?.firstname || ""
                        } ${
                          efig?.user?.lastname || userDetails?.lastname || ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Address: </th>
                        <td>{`${
                          efig?.user?.address || userDetails?.address || ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Contact #: </th>
                        <td>{`${
                          efig?.user?.contact || userDetails?.contact || ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Site Address:</th>
                        <td>{`${efig?.address || ""}`}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="row">
                    <div></div>
                  </div>

                  <a
                    href="#"
                    className="btn btn-primary float-end"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target={"#efigAlarmDetails"}
                    onClick={() => {
                      dispatch(setUpdate(true));
                    }}
                  >
                    Alarm Details
                  </a>
                  {!efig.active ? (
                    <div className="float-end" style={{ marginRight: "12px" }}>
                      {efigLoading ? (
                        <button
                          className="btn btn-success"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          &nbsp;
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-success w-100"
                          onClick={(e) => {
                            e.preventDefault();
                            activate();
                          }}
                        >
                          Activate
                        </button>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    Energizer Details:
                  </div>
                  <table className="table table-hover">
                    <tbody>
                      <tr>
                        <th scope="row">EFIG Serial:</th>
                        <td>{`${efig.espSerial || ""} / ${efig.zone1Name}`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Alarm:</th>
                        <td>{`${alarmStr || "None"}`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Internet: </th>
                        <td
                          style={{
                            fontWeight: "bold",
                            color: efig?.internet ? "green" : "red",
                          }}
                        >{`${
                          efig?.internet ? "Connected" : "Disconnected"
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Last Updated: </th>
                        <td>
                          {new Date(efig?.lastUpdated).toLocaleDateString(
                            "en-us",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Status: </th>
                        <td
                          style={{
                            color: efig?.active ? "green" : "red",
                            fontWeight: "bold",
                          }}
                        >
                          {`${efig?.active ? "Active" : "InActive"}`}
                          <span
                            className="float-end"
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              style={{
                                fontSize: "20px",
                                color: efig.isValid ? "red" : "green",
                                marginLeft: "5px",
                                opacity:
                                  user.roleId === 1 ||
                                  user?.accessControl?.assignEfig
                                    ? 1
                                    : 0.5,
                              }}
                              className="fa fa-light fa-ban"
                              onClick={() => updateEnergizerState(efig)}
                              title={
                                efig.isValid ? "Block EFIG" : "Un-Block EFIG"
                              }
                            ></i>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Energizer State: </th>
                        <td>{`${
                          efig?.energizerState ? "Armed" : "Disarmed"
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Battery Volts:</th>
                        <td>{`${efig?.batteryVolts || ""} V`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Valid Till:</th>
                        <td
                          style={{
                            color:
                              new Date(efig?.validTill) < new Date()
                                ? "red"
                                : "green",
                            fontWeight: "700",
                          }}
                        >
                          {/* {new Date(efig?.validTill).toLocaleDateString(
                            "en-us",
                            {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              // hour: "2-digit",
                              // minute: "2-digit",
                            }
                          )} */}
                          {efig?.validTill
                            ? new Date(efig?.validTill) < new Date()
                              ? `${new Date(efig?.validTill).toLocaleDateString(
                                  "en-us",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    // hour: "2-digit",
                                    // minute: "2-digit",
                                  }
                                )} (Expired)`
                              : new Date(efig?.validTill).toLocaleDateString(
                                  "en-us",
                                  {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                    // hour: "2-digit",
                                    // minute: "2-digit",
                                  }
                                )
                            : null}
                        </td>
                      </tr>
                      {user?.roleId == 1 ||
                      (user?.roleId == 2 && user?.groupId == 1) ? (
                        <tr>
                          <th scope="row">Location:</th>
                          <td>
                            <>
                              {(efig?.lat && user.roleId === 1) ||
                              (efig?.lat &&
                                user?.roleId == 2 &&
                                user?.groupId == 1) ? (
                                <i
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  data-bs-toggle="modal"
                                  data-bs-target={"#viewMarkerOnMap"}
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                    marginRight: "12px",
                                    opacity:
                                      user.roleId === 1 ||
                                      (user?.roleId == 2 && user?.groupId == 1)
                                        ? 1
                                        : 0.6,
                                  }}
                                  className="fa fa-light fa-map-marker-alt"
                                  onClick={() => {
                                    dispatch(
                                      setMarkerLocation({
                                        longitude:
                                          parseFloat(efig?.lng) || 74.3209,
                                        latitude:
                                          parseFloat(efig?.lat) || 31.4676,
                                        zoom: 12,
                                      })
                                    );
                                  }}
                                  title="View Location "
                                ></i>
                              ) : null}
                            </>
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <th scope="row">Current FW / Hardware:</th>
                        <td>{`${efig?.firmware || ""} ${
                          efig?.hardware
                            ? "/ " + efig?.hardware
                            : efig?.hardware
                        }`}</td>
                      </tr>
                      {user?.roleId === 1 || user.roleId === 2 ? (
                        <tr>
                          <th scope="row">Update FW:</th>
                          <td>
                            <div className="row">
                              <div className="col-6">
                                <input
                                  type={"text"}
                                  className="form-control"
                                  placeholder="Firwm ware"
                                  value={fw}
                                  maxLength={3}
                                  onChange={(e) => setFw(e.target.value)}
                                />
                              </div>
                              <div className="col-6">
                                <input
                                  type={"text"}
                                  className="form-control"
                                  placeholder="Hardware"
                                  value={hw}
                                  maxLength={3}
                                  onChange={(e) => setHw(e.target.value)}
                                />
                              </div>
                              <div className="col-12">
                                <div className="input-group">
                                  <span className="input-group-text">
                                    https://
                                  </span>
                                  <input
                                    type={"text"}
                                    className="form-control"
                                    placeholder="File Path"
                                    value={filePath}
                                    onChange={(e) =>
                                      setFilePath(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="col-12"
                                style={{ textAlign: "center" }}
                              >
                                {efigLoading ? (
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    disabled
                                    style={{ width: "120px" }}
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>{" "}
                                    &nbsp;
                                  </button>
                                ) : (
                                  <button
                                    style={{ width: "120px" }}
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      updateFW();
                                    }}
                                  >
                                    Update
                                  </button>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-6"></div>
              </div>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default EfigDetails;
