import axios from "axios";
import { URL } from "../config/index";
import { errorToast } from "../helpers/toasts";

const addBearerAuthHeader = (headers) => {
  let token = localStorage.getItem("token");
  // if (!token) throw new Error("unable to get token from localStorage");
  headers.Authorization = "Bearer " + token;
  return headers;
};

export const api = axios.create({
  baseURL: URL,
  timeout: 480000,
  headers: addBearerAuthHeader({}),
  // headers: {'X-Custom-Header': 'foobar'}
});

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.status == 400 &&
      error?.response?.data.err == "TokenExpiredError: jwt expired"
    ) {
      const originalRequest = error.config;
      // console.warn("Refresh token orignal requret", originalRequest);
      const url = URL + "auth/refresh";
      return await axios
        .post(
          url,
          {
            refreshToken: localStorage.getItem("refreshToken"),
          },
          {
            headers: addBearerAuthHeader({}),
          }
        )
        .then((res) => {
          // console.warn("refresh token man", res.data);
          localStorage.removeItem("token");
          localStorage.setItem("token", res.data.token);
          localStorage.removeItem("refreshToken");
          localStorage.setItem("refreshToken", res.data.refreshToken);
          originalRequest.headers["Authorization"] = "Bearer " + res.data.token;
          return axios(originalRequest);
        })
        .catch((e) => {
          // if (!localStorage.getItem("expiredShown")) {
          errorToast("User Token expired. User not logged in.");
          localStorage.setItem("expiredShown", "1");
          // }
          throw new Error("Error");
        });
    }
    throw error;
  }
);
