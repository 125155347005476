/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";

import Modal from "../../../../../components/Modal";

import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../../../../helpers/toasts";
import { IsEmpty } from "../../../../../helpers/utils";
import {
  FetchComplaintChatSingle,
  GetMembers,
  UpdateFW,
} from "../../../../../actions";
import { setComplaintChatSingle } from "../../../../../redux/slices/chatSlice";
import {
  setImageDetail,
  setPrevModal,
} from "../../../../../redux/slices/userSlice";
import { setMarkerLocation } from "../../../../../redux/slices/mapSlice";
import { URL } from "../../../../../config";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  setComplaintDetail,
  setIsComplaintLoading,
} from "../../../../../redux/slices/complaintSlice";
import { setEfig, setEfigLoading } from "../../../../../redux/slices/efigSlice";
import MyTextInput from "../../../../../components/MyTextInput";

var arr = [];

const CustomerDetails = (props) => {
  const dispatch = useDispatch();
  const [alarmStr, setAlarmStr] = useState(false);
  const [fw, setFw] = useState("");

  const userDetails = useSelector((state) => state.user.userDetails);
  const customerMembers = useSelector((state) => state.user.customerMembers);
  const prevModal = useSelector((state) => state.user.prevModal);

  useEffect(() => {
    if (userDetails) {
      GetMembers(dispatch, { userId: userDetails?.id });
    }
  }, [userDetails]);

  const getMembersRow = () => {
    return customerMembers?.map((member) => (
      <tr style={{ backgroundColor: member?.restricted ? "#fac9c5" : "white" }}>
        <th scope="row">Name/Contact/Energizer</th>
        <td style={{ textAlign: "end" }}>
          {`${member?.firstname || ""} ${member?.lastname || ""} / ${
            member?.contact || ""
          } `}{" "}
          <span>{`/ ${member?.energizer?.espSerial || ""}`}</span>
        </td>
      </tr>
    ));
  };

  return (
    <>
      <Modal
        title={"Customer Details"}
        name={"customerDetails"}
        icon={"fa fa-clock-rotate-left"}
      >
        {userDetails ? (
          <>
            <div className="col-12">
              <a href="#" className="nav-link">
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-light fa-arrow-left"
                  title="Go To List"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target={
                    prevModal ? `#${prevModal}` : "#listResidents"
                  }
                  onClick={() => dispatch(setPrevModal(null))}
                ></i>
                <span
                  className="float-end"
                  style={{
                    padding: "1px 8px",
                    borderRadius: "5px",
                    backgroundColor: "#fac9c5",
                  }}
                >
                  Restricted User
                </span>
              </a>
            </div>
            <div style={{ padding: "5px" }} className="">
              <div className="row">
                <div className="col-lg-6">
                  <div
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    Customer Details:
                  </div>
                  <table className="table table-hover">
                    <tbody>
                      <tr>
                        <th scope="row">Customer Name:</th>
                        <td>{`${userDetails?.customerFirstname || ""} ${
                          userDetails?.customerLastname || ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Primary User :</th>
                        <td>{`${userDetails?.firstname || ""} ${
                          userDetails?.lastname || ""
                        }`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Address: </th>
                        <td>{`${userDetails?.address || ""}`}</td>
                      </tr>
                      <tr>
                        <th scope="row">Contact #: </th>
                        <td>{`${userDetails?.contact || ""}`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      textAlign: "center",
                    }}
                  >
                    Members
                  </div>
                  <table className="table table-hover">
                    <tbody>
                      {customerMembers.length ? (
                        getMembersRow()
                      ) : (
                        <center>
                          <h6>No members</h6>
                        </center>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-6"></div>
              </div>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default CustomerDetails;
