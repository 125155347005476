/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SyncComplaints,
  SyncCustomers,
  SyncProjects,
  SyncSaleOrders,
  SyncUsers,
  UserLogout,
} from "../../actions";
import { errorToast, succesToast } from "../../helpers/toasts";
import { disconnectMqtt } from "../../Mqtt/Mqtt";
import {
  Beep2,
  Beep3,
  Beep4,
  Beep5,
  CmpSound,
  CmpSoundStop,
  Noti1,
} from "../../Howler/Beeper";
import axios from "axios";
import { URL } from "../../config";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const TopBar = (props) => {
  const [balanceObj, setBalanceObj] = useState([]);
  const [balance, setBalance] = useState("0");
  const [consumption, setConsumption] = useState("0");
  const [days, setDays] = useState("0");
  const [expiresIn, setExpiresIn] = useState(0);
  const [expireStatus, setExpireStatus] = useState(false);

  const dispatch = useDispatch();
  const applicationBadge = useSelector((state) => state.user.applicationBadge);
  const internetStatus = useSelector((state) => state.user.internetStatus);
  const requestApplications = useSelector(
    (state) => state.user.requestApplications
  );
  const user = useSelector((state) => state.user.user);
  const sync = useSelector((state) => state.user.sync);
  const service = useSelector((state) => state.user.service);

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    getBalance();
  }, []);

  const getBalance = async (showToast = false) => {
    await axios
      .get(`${URL}balanceInquiryRaw`)
      .then((response) => {
        let data = response.data;
        setBalanceObj(data?.obj);
        setBalance(data?.balance);
        getConsumptionPerDay(data?.obj, data?.balance);
        if (showToast) {
          succesToast("Balance Refreshed");
        }
      })
      .catch((e) => {
        errorToast("Error Getting Balance Info");
      });
  };

  const getConsumptionPerDay = (obj, bal) => {
    const myDate = new Date(
      obj[obj?.length - 1]?.last_recharge?.substring(0, 11)
    );
    const currDate = new Date();

    const expDate = new Date(
      obj[obj?.length - 1]?.balance_expiry?.substring(0, 11)
    );
    // const expDate = new Date("09/25/2024");
    const diff = expDate - currDate;
    setExpireStatus(diff > 0 ? false : true);

    const diffTime2 = Math.abs(diff);
    const diffDays2 = Math.floor(diffTime2 / (1000 * 60 * 60 * 24));

    setExpiresIn(diffDays2);

    const diffTime = Math.abs(currDate - myDate);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    const totalBalance = parseInt(obj[obj?.length - 1]?.total_balance);

    const balanceConsumed = totalBalance - bal;

    const con = Math.floor(balanceConsumed / diffDays);

    setDays(diffDays);
    setConsumption(con);
  };

  return (
    <div className="App">
      {/* <header className="App-header"></header> */}
      {/* <div>sadasdas</div> */}

      <div
        style={{
          position: "absolute",
          left: "10px",
          bottom: "10px",
          border: "1px solid grey",
          boxShadow: "1px 1px 10px 1px grey",
          background:
            expiresIn > 0 && expiresIn < 7
              ? "#f59c98"
              : expiresIn <= 0
              ? "#f53f36"
              : "white",
          borderRadius: "5px",
          padding: "8px",
          textAlign: "left",
          display: "flex",
          flexDirection: "row",
        }}
        className="showDetails"
      >
        <div>
          <div>Balance: Rs.{balance}/- </div>

          {expireStatus ? (
            <div>Expired</div>
          ) : (
            <div>
              Expires In: {expiresIn + 1} {expiresIn + 1 > 1 ? "Days" : "Day"}
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <span>
            <i
              onClick={() => getBalance(true)}
              title="Refresh"
              style={{
                fontSize: "24px",
                cursor: "pointer",
                marginLeft: "8px",
              }}
              className="fa-solid fa-rotate-right"
            ></i>
          </span>
        </div>
        <Tooltip anchorSelect={`.showDetails`} place="top">
          <div>Balance Consumed Per Day: Rs.{consumption}/-</div>
          <div>Avg. Messages Per Day: {Math.floor(consumption / 3.4)}</div>
          <div>Days Elapsed: {days}</div>
          <div>
            Last recharge:{" "}
            {balanceObj[balanceObj?.length - 1]?.last_recharge?.substring(
              0,
              11
            )}
          </div>
          <div>
            Expiry:{" "}
            {balanceObj[balanceObj?.length - 1]?.balance_expiry?.substring(
              0,
              11
            )}
          </div>
          {expireStatus ? (
            <>
              <div
                style={{ fontWeight: "bold", fontSize: "16px", color: "red" }}
              >
                Balance Expired
              </div>
            </>
          ) : null}
        </Tooltip>
      </div>

      <nav
        className="container-fluid navbar navbar-expand-lg navbar-dark bg-dark"
        style={{ borderBottom: "1px solid grey" }}
      >
        <a
          className="navbar-brand"
          href="#"
          style={{
            color: "#438AA2",
            marginLeft: "10px",
            minWidth: "25%",
            overflow: "hidden",
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          {`EFIG Web Portal (${user.efigGroup?.name || ""})`}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            {user.groupId === 1 ? (
              user?.accessControl?.sync || user?.roleId === 1 ? (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ color: "#438AA2" }}
                  >
                    <i
                      style={{
                        fontSize: "20px",
                      }}
                      className={`fa fa-light fa-rotate ${
                        sync ? "fa-spin" : ""
                      }`}
                    ></i>
                    <span> &nbsp;</span>
                    SYNC
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        SyncProjects(dispatch);
                      }}
                    >
                      <i
                        style={{ fontSize: "16px", width: "22px" }}
                        className="fa-solid fa-rotate"
                      ></i>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      Sync Projects
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        SyncCustomers(dispatch);
                      }}
                    >
                      <i
                        style={{ fontSize: "16px", width: "22px" }}
                        className="fa-solid fa-rotate"
                      ></i>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      Sync Customers
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        SyncUsers(dispatch);
                      }}
                    >
                      <i
                        style={{ fontSize: "16px", width: "22px" }}
                        className="fa fa-light fa-rotate"
                      ></i>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      Sync Users
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        SyncComplaints(dispatch);
                      }}
                    >
                      <i
                        style={{ fontSize: "16px", width: "22px" }}
                        className="fa fa-light fa-rotate"
                      ></i>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      Sync Complaints
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => {
                        SyncSaleOrders(dispatch);
                      }}
                    >
                      <i
                        style={{ fontSize: "16px", width: "22px" }}
                        className="fa fa-light fa-rotate"
                      ></i>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      Sync Sale Orders
                    </a>
                    {/* <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#responderAssignments"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-clock-rotate-left"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Responder Assignments
                </a> */}
                  </div>
                </li>
              ) : null
            ) : null}

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-file"
                ></i>
                <span> &nbsp;</span>
                Complaints
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {user?.accessControl?.addComplaint && user.groupId == 1 ? (
                  <a
                    className="dropdown-item"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#addComplaint"
                  >
                    <i
                      style={{ fontSize: "16px", width: "22px" }}
                      className="fa-solid fa-file-circle-plus"
                    ></i>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    Add Complaint
                  </a>
                ) : null}

                {/* <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#activeComplaint"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa-solid fa-triangle-exclamation"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Active Complaints
                </a> */}
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#complaintHistory"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-clock-rotate-left"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Complaints History
                </a>
              </div>
            </li>
            {user.roleId === 1 ? (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ color: "#438AA2" }}
                >
                  <i
                    style={{
                      fontSize: "20px",
                      width: "22px",
                      marginLeft: "5px",
                    }}
                    className="fa-solid fa-people-roof"
                  ></i>
                  <span>&nbsp;</span>
                  Groups
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a
                    className={`dropdown-item ${
                      user.roleId === 1 ? "" : "disabled"
                    }`}
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#addGroup"
                  >
                    <i
                      style={{ fontSize: "16px", width: "22px" }}
                      className="fa-solid fa-people-roof"
                    ></i>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    Add
                  </a>
                  <a
                    className={`dropdown-item `}
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#listGroups"
                  >
                    <>
                      <i
                        style={{
                          fontSize: "16px",
                        }}
                        className="fa-solid fa-people-roof"
                        title="Tracker"
                        // onClick={() => alert("TODO")}
                      ></i>
                    </>
                    <span>&nbsp;</span>
                    List
                  </a>
                </div>
              </li>
            ) : null}

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{
                    fontSize: "20px",
                    width: "22px",
                    marginLeft: "5px",
                  }}
                  className="fa-regular fa-credit-card"
                ></i>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                Payments
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.addPayments
                      ? ""
                      : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addPayment"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa-regular fa-credit-card"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add
                </a>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.listPayments
                      ? ""
                      : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#listPayments"
                >
                  <>
                    <i
                      style={{
                        fontSize: "16px",
                      }}
                      className="fa-regular fa-credit-card"
                      title="Tracker"
                      // onClick={() => alert("TODO")}
                    ></i>
                  </>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  List
                </a>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className={`nav-link dropdown-toggle`}
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-bullhorn"
                ></i>
                <span>&nbsp;</span>
                Announcements
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.addAnn
                      ? ""
                      : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#createAnnouncements"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-solid fa-plus"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Create for Customer
                </a>

                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.listAnn
                      ? ""
                      : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#listAnnouncements"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-solid fa-list"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  List
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-tablet"
                ></i>
                <span>&nbsp;</span>
                EFIG
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {user.roleId === 1 ||
                (user?.accessControl?.addEfig && user.groupId == 1) ? (
                  <a
                    className={`dropdown-item ${
                      user.roleId === 1 ||
                      (user?.accessControl?.addEfig && user.groupId == 1)
                        ? ""
                        : "disabled"
                    }`}
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#addEfig"
                  >
                    <i
                      style={{ fontSize: "16px", width: "22px" }}
                      className="fa fa-solid fa-plus"
                    ></i>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    Add EFIG
                  </a>
                ) : null}

                <a
                  className={`dropdown-item ${
                    user.roleId === 1 ||
                    (user?.accessControl?.listEfig && user.groupId == 1)
                      ? ""
                      : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#listEfigs"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-solid fa-list"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  List EFIG
                </a>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.assignEfig
                      ? ""
                      : "disabled"
                  }`}
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#assignEfig"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-solid fa-link"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Assign EFIG
                </a>
                {user?.groupId == 1 ? (
                  <>
                    <a
                      className={`dropdown-item ${
                        user.roleId === 1 ||
                        (user?.accessControl?.transferEfig && user.groupId == 1)
                          ? ""
                          : "disabled"
                      }`}
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#interGroupTransfer"
                    >
                      <i
                        style={{ fontSize: "16px", width: "22px" }}
                        className="fa fa-solid fa-exchange-alt"
                      ></i>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      Inter Group Transfer
                    </a>
                    <a
                      className={`dropdown-item ${
                        user.roleId === 1 ||
                        (user?.accessControl?.updateEfig && user.groupId == 1)
                          ? ""
                          : "disabled"
                      }`}
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#updateFWAll"
                    >
                      <i
                        style={{ fontSize: "16px", width: "22px" }}
                        className="fa fa-solid fa-arrow-up"
                      ></i>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      Update Firmware For All Energizers
                    </a>
                  </>
                ) : null}
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-users"
                ></i>
                <span> &nbsp;</span>
                Users
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.addUser
                      ? ""
                      : "disabled"
                  }`}
                  href="#Add"
                  data-bs-toggle="modal"
                  data-bs-target="#addUser"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-user-plus"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add System Users
                </a>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.listUser
                      ? ""
                      : "disabled"
                  }`}
                  href="#list"
                  data-bs-toggle="modal"
                  data-bs-target="#listUser"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-users-viewfinder"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Search Staff List
                </a>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#438AA2" }}
              >
                <i
                  style={{ fontSize: "20px", width: "22px", marginLeft: "5px" }}
                  className="fa fa-light fa-users"
                ></i>
                <span> &nbsp;</span>
                Customers
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.addCustomer
                      ? ""
                      : "disabled"
                  }`}
                  href="#list"
                  data-bs-toggle="modal"
                  data-bs-target="#addCustomer"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-user-plus"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Add Customer
                </a>
                <a
                  className={`dropdown-item ${
                    user.roleId === 1 || user?.accessControl?.listCustomer
                      ? ""
                      : "disabled"
                  }`}
                  href="#list"
                  data-bs-toggle="modal"
                  data-bs-target="#listResidents"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-users-viewfinder"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  List Customers
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav justify-content-end">
            <li
              className="nav-item dropdown"
              // style={{ backgroundColor: "yellow" }}
            >
              <a
                className="nav-link mx-5 dropdown-toggle "
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{
                  color: "#438AA2",
                  fontSize: "16px",
                }}
              >
                <i
                  style={{
                    fontSize: "24px",
                    width: "22px",
                    marginLeft: "5px",
                  }}
                  className="fa fa-light fa-user-circle"
                ></i>
                {internetStatus ? (
                  <i
                    title="Online"
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      top: -13,
                      left: -2,
                      color: "lightgreen",
                      zIndex: 1,
                    }}
                    className="fa fa-light fa-circle"
                    // onClick={() => alert("TODO")}
                  ></i>
                ) : (
                  <i
                    title="Offline"
                    style={{
                      fontSize: "12px",
                      position: "relative",
                      top: -13,
                      left: -2,
                      color: "red",
                      zIndex: 1,
                    }}
                    className="fa fa-light fa-circle"
                    // onClick={() => alert("TODO")}
                  ></i>
                )}
                <span>&nbsp;</span>
                {`${user?.firstname || ""} ${user?.lastname || ""}`}
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown2"
              >
                {/* <a className="dropdown-item disabled" href="#">
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-user-circle"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  {`${user?.firstname} ${user?.lastname}`}
                </a>
                <div className="dropdown-divider"></div> */}
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#updateProfile"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-pen-to-square"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Update Profile
                </a>
                <a
                  className="dropdown-item"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#changePass"
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-lock"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Change Password
                </a>
                <div className="dropdown-divider"></div>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={async (e) => {
                    e.preventDefault();
                    await UserLogout(dispatch, user)
                      .then((res) => {
                        disconnectMqtt();
                      })
                      .catch((e) => {
                        errorToast(e.response?.data?.err);
                      });
                  }}
                >
                  <i
                    style={{ fontSize: "16px", width: "22px" }}
                    className="fa fa-light fa-right-from-bracket"
                  ></i>
                  <span>&nbsp;</span>
                  <span>&nbsp;</span>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
        {/* </div> */}
      </nav>
    </div>
  );
};

export default TopBar;
