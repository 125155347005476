import React, { useEffect, useState } from "react";

const MyCheckbox = (props) => {
  return (
    <>
      <div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={(e) => props.onChange(e.target.checked)}
            id={props.name}
            checked={props.val}
          />
          <label className="form-check-label" for={props.name}>
            {props.name}
          </label>
        </div>
        <hr />
      </div>
    </>
  );
};

export default MyCheckbox;
