/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserLogin } from "../../actions";
import { errorToast } from "../../helpers/toasts";
import { IsEmpty } from "../../helpers/utils";
import { setIsLoading } from "../../redux/slices/userSlice";
import MyTextInput from "../../components/MyTextInput";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [contact, setContact] = useState("");
  const [password, setPassword] = useState("");
  const isLoading = useSelector((state) => state.user.isLoading);

  const dispatch = useDispatch();

  const goto = async () => {
    dispatch(setIsLoading(true));

    if (IsEmpty(contact)) {
      errorToast("Contact is required");
      dispatch(setIsLoading(true));
      return;
    }

    if (IsEmpty(password)) {
      errorToast("Password is required");
      dispatch(setIsLoading(true));
      return;
    }

    await UserLogin(dispatch, { contact, password });
    dispatch(setIsLoading(false));
    Empty();
  };

  const Empty = () => {
    setContact("");
    setPassword("");
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (position.coords) {
        // console.log("posss", position.coords);
        // props.getCenter([
        //   {
        //     latitude: Number(position.coords.latitude),
        //     longitude: Number(position.coords.longitude),
        //   },
        // ]);
      }
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          {/* <div className="col-4"></div> */}
          <div
            className="card offset-md-4 col-md-4 offset-xl-4 col-xl-4 offset-xxl-4 col-xxl-4 offset-lg-4 col-lg-4 col-sm-12"
            style={{ marginTop: "30px" }}
          >
            <div className="card-body">
              <center>
                <img
                  src={require("../../assets/logo.png")}
                  width="150"
                  height="150"
                  alt=""
                  title={"Electric Fence Internet Gateway"}
                />
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  EFIG Web Portal
                </div>
                {/* <h3>E.A.R.S</h3> */}
              </center>
              <hr />
              <div className="input-form">
                <label className="col-form-label"> Cell Phone # </label>
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder=" Enter Username "
                  value={contact}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      goto();
                    }
                  }}
                /> */}
                <div className="input-group">
                  <span className="input-group-text">+92</span>
                  <input
                    type={"text"}
                    className="form-control"
                    placeholder={"3XXXXXXXXX"}
                    value={contact}
                    maxLength={10}
                    onChange={(e) => setContact(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        goto();
                      }
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="input-form">
                <label className="col-form-label"> Password </label>
                <input
                  type="password"
                  className="form-control"
                  placeholder=" Enter Password "
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      goto();
                    }
                  }}
                />
                <div className="float-end">
                  <a
                    href="/forgot-password"
                    onClick={(e) => {
                      // e.preventDefault();
                    }}
                    className="nav-link"
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      Forgot Password?
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <br />
                <br />
                <button
                  style={{ flex: 1, width: "100%" }}
                  title="Login"
                  className="btn btn-primary"
                  onClick={() => {
                    // setIsLoading(true);
                    goto();
                  }}
                >
                  {isLoading ? "Please Wait..." : "Login"}
                </button>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <pre>{JSON.stringify(props.user, null, 2)}</pre> */}
    </>
  );
};

export default Login;
