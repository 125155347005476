import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BlockEnergizer,
  GetEFIGList,
  SearchEFIG,
  UnBlockEnergizer,
  UnassignEFIG,
} from "../../../../../actions";
import Modal from "../../../../../components/Modal";
import MyTextInput from "../../../../../components/MyTextInput";
import {
  setActionVal,
  setConfirmText,
  setEditGroupData,
  setIsLoading,
  setPrevModal,
  setUserDetails,
} from "../../../../../redux/slices/userSlice";
import {
  setAssignEfigSerial,
  setEditEfig,
  setEfig,
  setTempEfig,
} from "../../../../../redux/slices/efigSlice";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { globalDeleteBtn } from "../../../../../components/DeleteConfirm";
import { setMarkerLocation } from "../../../../../redux/slices/mapSlice";
import { errorToast, succesToast } from "../../../../../helpers/toasts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";

const ListEfig = (props) => {
  const dispatch = useDispatch();

  const [currentList, setCurrentList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  const [fw, setFw] = useState("");
  const [serial, setSerial] = useState("");
  const [status, setStatus] = useState("");
  const [address, setAddress] = useState("");
  const [erpProjectRef, setErpProjectRef] = useState("");
  const [energizerSerial, setEnergizerSerial] = useState("");
  const [name, setName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [efigs, setEfigs] = useState(null);

  const list = useSelector((state) => state.efig.list);
  const isLoading = useSelector((state) => state.user.isLoading);
  const user = useSelector((state) => state.user.user);

  const delBtn = useSelector((state) => state.user.delBtn);
  const actionVal = useSelector((state) => state.user.actionVal);
  const groups = useSelector((state) => state.user.groups);

  useEffect(() => {
    GetEFIGList(dispatch);
  }, []);

  useEffect(() => {
    setEfigs([...list]);
  }, [list]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentList(efigs?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(efigs?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, efigs]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % efigs?.length;

    setItemOffset(newOffset);
  };

  const groupList = () => {
    return groups.map((val, index) => {
      return (
        <option key={val.id} value={val.id}>
          {val.name}
        </option>
      );
    });
  };

  const updateEnergizerState = async (engz) => {
    if (!engz.isValid) {
      await UnBlockEnergizer({ energizerId: engz.id })
        .then((res) => {
          succesToast("Energizer Unblocked.");
          search();
        })
        .catch((e) => {
          errorToast(e?.response?.data?.err || "Error");
        });
    } else {
      await BlockEnergizer({ energizerId: engz.id })
        .then((res) => {
          succesToast("Energizer blocked.");
          search();
        })
        .catch((e) => {
          errorToast(e?.response?.data?.err || "Error");
        });
    }
  };

  const search = async () => {
    dispatch(setIsLoading(true));
    await SearchEFIG(dispatch, {
      customerName: name,
      groupId,
      address,
      energizerSerial,
      espSerial: serial,
      state: status,
      startTime,
      endTime,
      firmware: fw,
      erpProjectRef,
    });
    setItemOffset(0);
    setPageCount(0);
    dispatch(setIsLoading(false));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  useEffect(() => {
    if (actionVal?.action === "1" && actionVal?.type === "removeEfig") {
      removeEfigAction(actionVal.id, actionVal.clean);
      dispatch(setActionVal(null));
    }
  }, [actionVal]);

  const removeEfig = async (id, tester) => {
    dispatch(
      setConfirmText({
        msg: "Are you sure you want to un-assign this EFIG?",
        type: "removeEfig",
        showClear: !tester,
        id,
      })
    );
    globalDeleteBtn.click();
  };

  const removeEfigAction = async (id, clean = false) => {
    // alert(clean);
    await UnassignEFIG(dispatch, { energizerId: id, clearConfig: clean });
    await GetEFIGList(dispatch);
    // await search();
  };

  const getList = () => {
    let count = 0;
    return currentList?.map((val, index) => {
      count++;
      return (
        <Fragment key={index}>
          <tr style={{ backgroundColor: !val?.active ? "#fac9c5" : "" }}>
            <th scope="row">{count}</th>
            <td>
              <a
                className={`energizer_${val?.id}`}
                onClick={() => {
                  dispatch(setEfig(val));
                  dispatch(setTempEfig(val));
                  dispatch(setUserDetails(""));
                  dispatch(setPrevModal("listEfigs"));
                }}
                href="#"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#efigDetails"}
              >
                {`${val?.espSerial} ${
                  val?.energizerSerial ? "/ " + val?.energizerSerial : ""
                } `}
              </a>{" "}
            </td>

            <td className={`clickable_${val?.id}`}>
              <a
                href="#"
                className={`nav-link`}
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#customerDetails"}
                style={{ color: "blue" }}
                onClick={() => {
                  dispatch(setUserDetails(val?.user));
                  dispatch(setPrevModal("listEfigs"));
                }}
              >
                {`${
                  val?.user?.customerFirstname || val?.user?.firstname || ""
                } ${
                  val?.user?.customerLastname || val?.user?.lastname || ""
                }`}{" "}
              </a>
            </td>
            <td>{val?.erpProject?.ref || ""}</td>
            <td>{`${val?.address || ""}`}</td>

            <td>{`${val?.efigGroup?.name || ""}`}</td>
            <td>{`${val?.userId ? "Installed" : "Available"}`}</td>
            <td
              style={{
                color: new Date(val?.validTill) < new Date() ? "red" : "green",
                fontWeight: "bold",
              }}
            >
              {val?.validTill
                ? new Date(val?.validTill) < new Date()
                  ? `${new Date(val?.validTill).toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      // hour: "2-digit",
                      // minute: "2-digit",
                    })}`
                  : new Date(val?.validTill).toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      // hour: "2-digit",
                      // minute: "2-digit",
                    })
                : null}
            </td>
            <td style={{ textAlign: "center" }}>{`${val?.firmware || "01"}/${
              val?.hardware || ""
            }`}</td>

            <td>
              <a
                className={`nav-link ${
                  user.roleId === 1 || user?.accessControl?.assignEfig
                    ? ""
                    : "disabled"
                }`}
                href="#"
              >
                {val.userId ? (
                  <>
                    <i
                      style={{
                        fontSize: "20px",
                        marginLeft: "10px",
                        color: "blue",
                        opacity:
                          user.roleId === 1 || user?.accessControl?.assignEfig
                            ? 1
                            : 0.4,
                      }}
                      className="fa fa-light fa-pencil"
                      onClick={() => {
                        dispatch(setEditEfig(val));
                      }}
                      title="Edit"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      data-bs-toggle="modal"
                      data-bs-target="#UpdateEfig"
                    ></i>

                    <i
                      style={{
                        fontSize: "20px",
                        color: "red",
                        marginLeft: "5px",
                        opacity:
                          user.roleId === 1 || user?.accessControl?.assignEfig
                            ? 1
                            : 0.6,
                      }}
                      className="fa fa-light fa-unlink"
                      onClick={() => removeEfig(val.id, val?.user?.tester)}
                      title="Un-Assign"
                    ></i>
                    {/* <i
                      style={{
                        fontSize: "20px",
                        color: val.isValid ? "red" : "green",
                        marginLeft: "5px",
                        opacity:
                          user.roleId === 1 || user?.accessControl?.assignEfig
                            ? 1
                            : 0.5,
                      }}
                      className="fa fa-light fa-ban"
                      onClick={() => updateEnergizerState(val)}
                      title={val.isValid ? "Block" : "Un-Block"}
                    ></i> */}
                  </>
                ) : (
                  <>
                    <i
                      style={{
                        fontSize: "20px",
                        color: "green",
                        marginLeft: "5px",
                        opacity:
                          user.roleId === 1 || user?.accessControl?.assignEfig
                            ? 1
                            : 0.6,
                      }}
                      className="fa fa-light fa-link"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      data-bs-toggle="modal"
                      data-bs-target="#assignEfig"
                      onClick={() => {
                        dispatch(
                          setAssignEfigSerial({
                            value: val.id,
                            label: val?.espSerial,
                          })
                        );
                      }}
                      title="Assign "
                    ></i>
                  </>
                )}
                {/* {val.lat && user.roleId === 1 ? (
                  <i
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    data-bs-toggle="modal"
                    data-bs-target={"#viewMarkerOnMap"}
                    style={{
                      fontSize: "20px",
                      color: "black",
                      marginLeft: "5px",
                      opacity: user.roleId === 1 || user.roleId === 6 ? 1 : 0.6,
                    }}
                    className="fa fa-light fa-map-marker-alt"
                    onClick={() => {
                      dispatch(
                        setMarkerLocation({
                          longitude: parseFloat(val.lng) || 74.3209,
                          latitude: parseFloat(val.lat) || 31.4676,
                          zoom: 12,
                        })
                      );
                    }}
                    title="View Location "
                  ></i>
                ) : null} */}
              </a>
            </td>
          </tr>
          {val?.user ? (
            <Tooltip anchorSelect={`.clickable_${val?.id}`} place="top">
              <div>
                Primary User:{" "}
                {`${val?.user?.firstname || ""} ${val?.user?.lastname || ""}`}
              </div>
              <div>Contact #: {val?.user?.contact || ""}</div>
              <div>Address: {val?.user?.address || ""}</div>
              {/* <div>Site Address: {val.energizer?.address || ""}</div> */}
            </Tooltip>
          ) : null}
          <Tooltip anchorSelect={`.energizer_${val?.id}`} place="top">
            <div>
              Internet:
              {` ${val.internet ? "Connected" : "Disconnected"}`}
            </div>
            <div>
              Zone Name:
              {` ${val.zone1Name || ""}`}
            </div>
            <div>
              Status:
              {` ${val.energizerState ? "Armed" : "Disarmed"}`}
            </div>
          </Tooltip>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Modal
        title={"EFIG List"}
        name={"listEfigs"}
        icon={"fa-solid fa-list"}
        height={"58vh"}
      >
        <div className="row">
          <div className="col-4">
            <a href="#" className="nav-link">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-light fa-arrow-left"
                title="Go To Add Device"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={"#addEfig"}
              ></i>
            </a>
          </div>
          <div className="col-4">
            <div className="text-center">
              <h6>{`Total Records: ${list?.length}`}</h6>
            </div>
          </div>
          <div className="col-4">
            <span
              className="float-end"
              style={{
                padding: "1px 8px",
                borderRadius: "5px",
                backgroundColor: "#fac9c5",
                fontWeight: "bold",
              }}
            >
              {" "}
              InActive{" "}
            </span>
          </div>
          <div className="col-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: "30px" }}>
                    <label className="col-form-label">#</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={serial}
                      placeholder={"Serial"}
                      onChange={(e) => setSerial(e.target.value)}
                    />
                    <label className="col-form-label">EFIG/Engz Sr #</label>
                  </th>
                  {/* <th>
                    <input
                                          onKeyDown={handleKeyDown}

                      type={"text"}
                      className="form-control form-control-sm"
                      value={energizerSerial}
                      placeholder={"Energizer #"}
                      onChange={(e) => setEnergizerSerial(e.target.value)}
                    />
                    <label className="col-form-label">Energizer #</label>
                  </th> */}
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={name}
                      placeholder={"Customer Name"}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label className="col-form-label">Customer Name</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={erpProjectRef}
                      placeholder={"Project Ref"}
                      onChange={(e) => setErpProjectRef(e.target.value)}
                    />
                    <label className="col-form-label">Project Ref</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={address}
                      placeholder={"Site Address"}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <label className="col-form-label">Site Address</label>
                  </th>

                  <th style={{ width: "120px" }}>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={groupId}
                      onChange={(e) => setGroupId(e.target.value)}
                    >
                      <option value=""></option>
                      {groupList()}
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Group</label>
                  </th>

                  <th style={{ width: "130px" }}>
                    <select
                      className="form-select form-select-sm"
                      aria-label="Default select example"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value={""}></option>
                      <option value="1">Installed</option>
                      <option value="0">Un-installed</option>
                      <option value="2">Disconnected</option>
                      {/* <option value="5">Device</option> */}
                    </select>
                    <label className="col-form-label">Status</label>
                  </th>
                  <th>
                    {/* <div className="row"> */}
                    <div className="col-12">
                      <DatePicker
                        placeholderText="Start Date"
                        className="form-control form-control-sm"
                        selected={startTime}
                        onChange={(date) => setStartTime(date)}
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        withPortal
                      />
                    </div>
                    <div className="col-12">
                      <DatePicker
                        placeholderText="End Date"
                        className="form-control form-control-sm"
                        selected={endTime}
                        onChange={(date) => setEndTime(date)}
                        dateFormat="yyyy-MM-dd"
                        isClearable
                        withPortal
                      />
                    </div>
                    {/* </div> */}
                    <label className="col-form-label">Valid Till</label>
                  </th>
                  <th>
                    <input
                      onKeyDown={handleKeyDown}
                      type={"text"}
                      className="form-control form-control-sm"
                      value={fw}
                      placeholder={"FW # /HW #"}
                      onChange={(e) => setFw(e.target.value)}
                    />
                    <label className="col-form-label">FW # /HW #</label>
                  </th>

                  <th style={{ width: "80px" }}>
                    {isLoading ? (
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        disabled
                        style={{
                          width: "80px",
                          textAlign: "center",
                        }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        style={{
                          width: "80px",
                        }}
                        className="btn btn-sm btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          search();
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                          title="Search"
                          className="fa fa-light fa-magnifying-glass"
                        ></i>
                      </button>
                    )}
                    <label className="col-form-label">Action</label>
                  </th>
                </tr>
              </thead>

              <tbody style={{ height: list?.length ? "" : "58vh" }}>
                {getList()}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div>
            <div className="float-end">
              <ReactPaginate
                nextLabel="&raquo;"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="&laquo;"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListEfig;
