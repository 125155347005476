/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import { PieChart } from "react-minimal-pie-chart";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ComplaintsListForChart } from "../../../actions";

function makeTooltipContent(entry = { tooltip: "title" }) {
  return `Sector ${entry.tooltip} has value ${entry.value}`;
}

const MyChart = (props) => {
  const dispatch = useDispatch();

  const [hovered, setHovered] = useState(null);
  const [time, setTime] = useState("30");

  const chartComplaintList = useSelector(
    (state) => state.complaint.chartComplaintList
  );
  const activeComplaintDataChart = useSelector(
    (state) => state.complaint.activeComplaintDataChart
  );
  const closedComplaintDataChart = useSelector(
    (state) => state.complaint.closedComplaintDataChart
  );
  const totalComplaintDataChart = useSelector(
    (state) => state.complaint.totalComplaintDataChart
  );

  useEffect(() => {
    ComplaintsListForChart(dispatch, {
      time,
    });
  }, [time]);

  const dataMock = [{ title: "No Data", value: 100, color: "lightgrey" }];

  // const activeComplaintData = [
  //   { value: 10, color: "#509cf2", name: "Electrical" },
  //   { value: 7, color: "yellow", name: "Civil" },
  //   { value: 10, color: "orange", name: "Sanitary" },
  //   { value: 1, color: "grey", name: "Plumbing" },
  //   { value: 4, color: "lightgreen", name: "Misc" },
  // ];
  // const closedComplaintData = [
  //   { value: 10, color: "#509cf2", name: "Electrical" },
  //   { value: 7, color: "yellow", name: "Civil" },
  //   { value: 10, color: "orange", name: "Sanitary" },
  //   { value: 1, color: "grey", name: "Plumbing" },
  //   { value: 4, color: "lightgreen", name: "Misc" },
  // ];
  // const totalComplaintData = [
  //   { value: 10, color: "#509cf2", name: "Electrical" },
  //   { value: 7, color: "yellow", name: "Civil" },
  //   { value: 10, color: "orange", name: "Sanitary" },
  //   { value: 1, color: "grey", name: "Plumbing" },
  //   { value: 4, color: "lightgreen", name: "Misc" },
  // ];

  const defaultLabelStyle = {
    fontSize: "25px",
    fontFamily: "sans-serif",
  };

  const options = {
    title: "Active ",
    is3D: true,
  };

  return (
    <>
      <div className="accordion" id="accordionPanelsStayOpenExample2">
        <div className="">
          <div className="row">
            <div className="col-12">
              <div
                style={{
                  color: "#438AA2",
                  fontWeight: "bold",
                  backgroundColor: "#121624",
                  height: "30px",
                }}
              >
                Complaint Statistics
              </div>
            </div>
          </div>
          {/* <h2 className="accordion-header" id="panelsStayOpen-headingOne">
            <button
              className="accordion-button"
              // type="button"
              //   data-bs-toggle="collapse"
              data-bs-target="#chart"
              aria-expanded="true"
              aria-controls="chart"
              style={{
                color: "#438AA2",
                fontWeight: "bold",
                backgroundColor: "#121624",
                height: "32px",
              }}
            >
              Chart
            </button>
          </h2> */}
          <div
            id="chart"
            // className="accordion-collapse show"
            // aria-labelledby="panelsStayOpen-headingOne"
          >
            <div
              style={{
                paddingTop: "10px",
                backgroundColor: "#F5FBFF",
                marginRight: "5px",
              }}
            >
              <div className="row">
                <div className="col-3">
                  <label className="col-form-label">Select Time:</label>
                  <select
                    className="form-select form-select-sm"
                    aria-label="Default select example"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  >
                    <option value={undefined}></option>
                    <option value="1">24 Hrs</option>
                    <option value="3">03 Days</option>
                    <option value="7">07 Days</option>
                    <option value="30">01 Month</option>
                  </select>
                </div>
                <div className="col-3">
                  {activeComplaintDataChart.length ? (
                    <div
                      data-tooltip-content={`${hovered}`}
                      data-tooltip-id="chart"
                      data-tooltip-variant="light"
                    >
                      <PieChart
                        data={activeComplaintDataChart}
                        label={({ x, y, dx, dy, dataEntry }) => (
                          <text
                            x={x}
                            y={y}
                            dx={dx}
                            dy={dy}
                            dominant-baseline="central"
                            text-anchor="middle"
                            style={{
                              fontSize: "8px",
                              textColor: "white",
                              color: "white",
                              fontFamily: "sans-serif",
                            }}
                          >
                            {Math.round(dataEntry.percentage) + "%"}
                          </text>
                        )}
                        onMouseOver={(_, index) =>
                          setHovered(
                            `${activeComplaintDataChart[index].value} ${
                              activeComplaintDataChart[index].name
                            } complaint${
                              activeComplaintDataChart[index].value > 1
                                ? "s"
                                : ""
                            }`
                          )
                        }
                        labelStyle={defaultLabelStyle}
                      />
                      Active
                      <ReactTooltip id="chart" />
                    </div>
                  ) : (
                    <div>
                      <PieChart data={dataMock} />
                      Active
                    </div>
                  )}
                </div>
                <div className="col-3">
                  {closedComplaintDataChart.length ? (
                    <div
                      data-tooltip-content={`${hovered}`}
                      data-tooltip-id="chart"
                      data-tooltip-variant="light"
                    >
                      <PieChart
                        data={closedComplaintDataChart}
                        label={({ x, y, dx, dy, dataEntry }) => (
                          <text
                            x={x}
                            y={y}
                            dx={dx}
                            dy={dy}
                            dominant-baseline="central"
                            text-anchor="middle"
                            style={{
                              fontSize: "8px",
                              textColor: "white",
                              color: "white",
                              fontFamily: "sans-serif",
                            }}
                          >
                            {Math.round(dataEntry.percentage) + "%"}
                          </text>
                        )}
                        onMouseOver={(_, index) =>
                          setHovered(
                            `${closedComplaintDataChart[index].value} ${
                              closedComplaintDataChart[index].name
                            } complaint${
                              closedComplaintDataChart[index].value > 1
                                ? "s"
                                : ""
                            }`
                          )
                        }
                        labelStyle={defaultLabelStyle}
                      />
                      Closed
                      <ReactTooltip id="chart" />
                    </div>
                  ) : (
                    <div>
                      <PieChart data={dataMock} />
                      Closed
                    </div>
                  )}
                </div>
                <div className="col-3">
                  {totalComplaintDataChart.length ? (
                    <div
                      data-tooltip-content={`${hovered}`}
                      data-tooltip-id="chart"
                      data-tooltip-variant="light"
                    >
                      <PieChart
                        data={totalComplaintDataChart}
                        label={({ x, y, dx, dy, dataEntry }) => (
                          <text
                            x={x}
                            y={y}
                            dx={dx}
                            dy={dy}
                            dominant-baseline="central"
                            text-anchor="middle"
                            style={{
                              fontSize: "8px",
                              textColor: "white",
                              color: "white",
                              fontFamily: "sans-serif",
                            }}
                          >
                            {Math.round(dataEntry.percentage) + "%"}
                          </text>
                        )}
                        onMouseOver={(_, index) =>
                          setHovered(
                            `${totalComplaintDataChart[index].value} ${
                              totalComplaintDataChart[index].name
                            } complaint${
                              totalComplaintDataChart[index].value > 1
                                ? "s"
                                : ""
                            }`
                          )
                        }
                        labelStyle={defaultLabelStyle}
                      />
                      Total
                      <ReactTooltip id="chart" />
                    </div>
                  ) : (
                    <div>
                      <PieChart data={dataMock} />
                      Total
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyChart;
