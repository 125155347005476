/* eslint-disable no-lone-blocks */
import { Offline, Online, Detector } from "react-detect-offline";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import {
  FetchAlertChat,
  FetchComplaintChat,
  FetchFreeChat,
  GetActiveComplaints,
  GetActivePanics,
  GetUsers,
  SetInternetState,
  SetRefresh,
} from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { setInternetStatus } from "../redux/slices/userSlice";
import { URL } from "../config";

const NetInfo = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const refresh = useSelector((state) => state.user.refresh);

  const [_online, set_Online] = useState(false);

  useEffect(() => {
    if (refresh && _online) {
      fetchData();
    }
  }, [refresh, _online]);

  const change = () => {
    SetRefresh(dispatch, true);
    set_Online(false);
    SetInternetState(dispatch, false);
  };

  const setStatus = () => {
    set_Online(true);
    SetInternetState(dispatch, true);
  };

  const fetchData = async () => {
    console.log("fetchingggggggggggggg");
    SetRefresh(dispatch, false);
    GetActiveComplaints(dispatch);
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 60,
          right: 20,
          width: "60px",
          zIndex: 1,
          textAlign: "center",
          boxShadow: "1px 2px 9px grey",
        }}
      >
        <Detector
          polling={{ url: URL + "online", interval: 15000 }}
          render={({ online }) => {
            {
              online ? setStatus() : change();
            }
            return (
              <>
                {/* <div>
                  {online ? (
                    <div style={{ color: "white", background: "green" }}>
                      Online
                    </div>
                  ) : (
                    <div style={{ color: "white", background: "red" }}>
                      Offline
                    </div>
                  )}
                </div> */}
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default NetInfo;
